import { useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";

import DebotClient from "../client/client";
import { ratesAtom } from "../store";

export const useRates = (): any => {
  const [rates, setRates] = useRecoilState(ratesAtom);

  useEffect(() => {
    if (rates && rates.price === null) {
      DebotClient.getExchangeRates('EVER')
        .then((value: any) => {
          setRates(value.price);
        });
    }
  });

  return useMemo(
    () => ({
      rates,
    }),
    [rates],
  );
};
