
/* Global Dependencies */
import { useState } from 'react';
import classnames from "classnames/bind";
import { Container } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { Button, Flex, FlexContainer, Icon } from '../../components';
import styles from "./Unstake.module.scss";

/* Component */
const cnb = classnames.bind(styles);

export const Unstake = () => {
  const navigate = useNavigate();
  const [stake, setStake] = useState<number>(1000);
  const [selected, setSelected] = useState<number>(1);

  const lockOptions = [{
    term: "Instant",
    value: "soon",
    disabled: true
  }, {
    term: "Delayed",
    value: "up to 32 hours",
    disabled: false
  }];

  return (<>
    <Container fluid className={cnb("content-container container-overflow-hidden")}>
      <FlexContainer
        direction="row"
        justify="space-between"
        align="stretch"
        className={cnb("stake-header")}
      >
        <Flex grow={1}>
          <Button className="back-btn" onClick={(e) => navigate('/pool')}>
            <Icon icon={"arrow-left"} />
          </Button>
        </Flex>
        <Flex grow={0}>
          <div className={cnb("account-block")}>
            <div className={cnb("account-info")}>
              <div className={cnb("account-sum")}>2,343.00 Ē</div>
              <div className={cnb("account-address")}>0:fa2 ···· 1839</div>
            </div>
            <div className={cnb("account-logout-btn")}><Icon icon="close-alt" /></div>
          </div>
        </Flex>
      </FlexContainer>
      <div className="inner-page-wrapper">
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1} className={cnb("unstake-title")}>
            Unstake&nbsp;&nbsp;·&nbsp;&nbsp;Ever Pool
          </Flex>
        </FlexContainer>
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
          className={cnb("unstake-block-header")}
        >
          <Flex grow={1}>
            Duration
          </Flex>
        </FlexContainer>
        <FlexContainer
          direction="column"
          justify="space-between"
          align="stretch"
          className={cnb("unstake-lock-block")}
        >
          <Flex grow={1}>
            {lockOptions && lockOptions.map((option, index) => (
              <FlexContainer
                key={index}
                direction="row"
                justify="space-between"
                align="center"
                onClick={() => {
                  if (!option.disabled) { setSelected(index); }
                }}
                className={cnb("unstake-lock-option", { "selected": selected === index }, { "disabled": option.disabled })}
              >
                <Flex grow={2} className={cnb("unstake-lock-option-term")}>
                  {option.term}
                </Flex>
                <Flex grow={1} className={cnb("unstake-lock-option-value")}>
                  {option.value}
                </Flex>
                <Flex grow={0} className={cnb("unstake-lock-option-radio")}>
                  {selected === index ? (
                    <Icon icon={"radio-checked"} />
                  ) : (<Icon icon={"radio-unchecked"} />)}
                </Flex>
              </FlexContainer>
            ))}
          </Flex>
        </FlexContainer>
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
          className={cnb("unstake-block-header")}
        >
          <Flex grow={1}>
            Unstake
          </Flex>
        </FlexContainer>
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1}>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="center"
              className={cnb("unstake-card")}
            >
              <Flex grow={1}>
                <FlexContainer
                  direction="column"
                  justify="space-between"
                  align="stretch"
                >
                  <Flex grow={1} className={cnb("unstake-input-label")}>
                    <label htmlFor="stake-val">Amount</label>
                  </Flex>
                  <Flex grow={1} className={cnb("unstake-input")}>
                    <input id="stake-val" type="number" value={stake} onChange={(ev) => setStake(+ev.target.value)} />
                  </Flex>
                </FlexContainer>

              </Flex>
              <Flex grow={0} className={cnb("currency")}>EVER</Flex>
            </FlexContainer>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="stretch"
            >
              <Flex grow={1} className={cnb("unstake-card-desc")}>min. 100 — 1,000,000 max.</Flex>
            </FlexContainer>
            <FlexContainer
              direction="column"
              justify="space-between"
              align="stretch"
            >
              <Flex grow={1}>
                <Button color="primary" size="large" onClick={() => console.log('Unstake')} disabled={(stake === 0)} className={cnb("confirm-btn")}>Confirm</Button>
              </Flex>
            </FlexContainer>
          </Flex>
        </FlexContainer>
      </div>
    </Container >
  </>
  );
};

export default Unstake;
