import { MetaDecorator } from '../components';
import { RealYields as RealYieldsPage } from "../views";

const RealYields = ({ path }: { path?: string }) => {
  return (
    <>
      <MetaDecorator
        title="Research and key metrics · Everscale Staking with Ever Surf"
        description="Staking crypto — comparison of platforms, best tools and highest rewards. Low risk, long-term."
        keywords="ever, surf, staking, stake, yields, everscale, ever, bnb, polygon, matic, ethereum, eth, avalanche, avax, cardano, ada, solana, sol, fantom, ftm"
      />
      <RealYieldsPage />
    </>
  );
};

export default RealYields;
