
/* Global Dependencies */
import classnames from "classnames/bind";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/* Local Dependencies */
import { Button, Flex, FlexContainer, Icon, Loader, Skeleton, Panel } from '../../components';
import styles from "./Confirm.module.scss";
import DebotClient from "../../client/client";
import { poolSelector } from '../../store';
import { numberFormat, toNano, toNanoAndFormat } from '../../utils';
import { useEffect, useState } from 'react';
import { TPoolPeriod } from '../../types';
import { Connect } from '../../patterns';

/* Component */
const cnb = classnames.bind(styles);

export const Confirm = ({ validator }: { validator?: boolean }) => {

  const { pool } = useParams<{ pool: string }>();
  const { amount } = useParams<{ amount: string }>();
  const { period } = useParams<{ period: string }>();

  const currentPool = useRecoilValue(poolSelector(pool));

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [fee, setFee] = useState<number>(0);
  const [providerFee, setProviderFee] = useState<number>(0);
  const [networkFee, setNetworkFee] = useState<number>(0);
  const [executionFee, setExecutionFee] = useState<string>("");
  const [expectedReward, setExpectedReward] = useState<string>("");
  const [selectedPeriod, setSelectedPeriod] = useState<TPoolPeriod | null>(null);

  useEffect(() => {
    if (currentPool?.config.providerFee && amount) {
      const providerFeeFromAmount = ((+currentPool?.config.providerFee / 10) * +amount) / 100;
      setProviderFee(providerFeeFromAmount);
      setNetworkFee(0.01);
      setExecutionFee(toNanoAndFormat(+currentPool?.config.gasFeeForStake, 2, 2));
      const totalFee = providerFeeFromAmount + toNano(+currentPool?.config.gasFeeForStake) + 0.01;
      setFee(totalFee);
    }
    if (currentPool && period) {
      const currentPeriod = currentPool?.config?.periods[+period];
      if (currentPeriod) {
        setSelectedPeriod(currentPeriod);
      }
    }
  }, [amount, period, currentPool]);

  useEffect(() => {
    if (selectedPeriod && amount) {
      const percentageAddition = currentPool?.uniqueName === 'boosted' ? 120 : 0;
      const percentage = (percentageAddition + +selectedPeriod?.APY) / 1000;
      const years = +selectedPeriod?.secs / (60 * 60 * 24 * 365);
      const delimiter = currentPool?.uniqueName === 'gosh' ? 13.64 : 1;
      const reward = +amount * percentage * years / delimiter;
      if (reward) {
        setExpectedReward(numberFormat(reward, 2, 2));
      }
    }

  }, [amount, selectedPeriod]);


  const navigate = useNavigate();

  const confirm = () => {
    if (amount && period && pool) {
      navigate(validator
        ? `/validator-done/${pool}/${amount}/${period}`
        : `/done/${pool}/${amount}/${period}`);
    }
  };
  const goBack = () => {
    if (currentPool) {
      navigate(validator
        ? `/validator-stake/${currentPool?.uniqueName}`
        : `/stake/${currentPool?.uniqueName}`);
    }
  }

  return (<>
    <Container fluid className={cnb("content-container container-overflow-hidden")}>
      <FlexContainer
        direction="row"
        justify="space-between"
        align="stretch"
        className={cnb("stake-header")}
      >
        <Flex grow={1}>
          <Button className={cnb("back-btn")}
            size="large"
            disabled={!currentPool}
            onClick={goBack}>
            <Icon icon={"close"} /> Cancel
          </Button>
        </Flex>
      </FlexContainer>
      <div className="inner-page-wrapper">
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1} className={cnb("unstake-title")}>
            {currentPool && currentPool.uniqueName === 'gosh' &&
              <>
                Stake&nbsp;&nbsp;·&nbsp;&nbsp;{validator ? "Validate Gosh" : "Gosh Pool"}
              </>
            }
            {currentPool && currentPool.uniqueName === 'boosted' &&
              <>
                Stake&nbsp;&nbsp;·&nbsp;&nbsp;{validator ? "Validate Boosted" : "Boosted Stake"}
              </>
            }
            {!currentPool &&
              <Skeleton />
            }
          </Flex>
        </FlexContainer>
        <FlexContainer
          direction="column"
          justify="space-between"
          align="stretch"
          className={cnb("unstake-card")}
        >
          <Flex grow={1} className={cnb("unstake-card-title")}>
            Estimated balance changes
          </Flex>
          <Flex grow={1}>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="stretch"
              className={cnb("unstake-card-row")}
            >
              <Flex grow={1} className={cnb("unstake-card-row-title")}>
                Deposit
              </Flex>
              <Flex grow={0} className={cnb("unstake-card-row-value")}>
                {currentPool &&
                  <>
                    {amount ? numberFormat((+amount), 2, 2)
                      : ''} {currentPool.stakeTokenName}
                  </>
                }
                {!currentPool &&
                  <Skeleton />
                }
              </Flex>
            </FlexContainer>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="stretch"
              className={cnb("unstake-card-row")}
            >
              <Flex grow={1} className={cnb("unstake-card-row-title")}>
                Fee
              </Flex>
              <Flex grow={0} className={cnb("unstake-card-row-value")}>
                {currentPool &&
                  <>
                    up to {numberFormat(fee, 2, 2)} {currentPool.stakeTokenName}
                  </>
                }
                {!currentPool &&
                  <Skeleton />
                }
              </Flex>
            </FlexContainer>
          </Flex>
        </FlexContainer>
        <FlexContainer
          direction="column"
          justify="space-between"
          align="stretch"
          className={cnb("unstake-card")}
        >
          <Flex grow={1} className={cnb("unstake-card-title")}>
            Deposit details
          </Flex>
          <Flex grow={1}>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="stretch"
              className={cnb("unstake-card-row")}
            >
              <Flex grow={1} className={cnb("unstake-card-row-title")}>
                {currentPool &&
                  <>Lock time</>
                }
                {!currentPool &&
                  <Skeleton />
                }
              </Flex>
              <Flex grow={0} className={cnb("unstake-card-row-value")}>
                {currentPool &&
                  <>
                    {selectedPeriod?.title}
                  </>
                }
                {!currentPool &&
                  <Skeleton />
                }
              </Flex>
            </FlexContainer>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="stretch"
              className={cnb("unstake-card-row")}
            >
              <Flex grow={1} className={cnb("unstake-card-row-title")}>
                {currentPool && currentPool.uniqueName === 'gosh' &&
                  <>APR</>
                }
                {currentPool && currentPool.uniqueName === 'boosted' &&
                  <>Estimated APR</>
                }
                {!currentPool &&
                  <Skeleton />
                }
              </Flex>
              <Flex grow={0} className={cnb("unstake-card-row-value")}>

                {currentPool && currentPool.uniqueName === 'gosh' && selectedPeriod &&
                  <>
                    {`${+selectedPeriod?.APY / 10}%`}
                  </>
                }
                {currentPool && currentPool.uniqueName === 'boosted' && selectedPeriod &&
                  <>
                    {`12.0 + ${+selectedPeriod?.APY / 10}%`}
                  </>
                }
                {!currentPool &&
                  <Skeleton />
                }
              </Flex>
            </FlexContainer>
            {currentPool && currentPool.uniqueName === 'gosh' &&
              <FlexContainer
                direction="row"
                justify="space-between"
                align="stretch"
                className={cnb("unstake-card-row")}
              >
                <Flex grow={1} className={cnb("unstake-card-row-title")}>
                  {currentPool &&
                    <>Follow-up vesting</>
                  }
                  {!currentPool &&
                    <Skeleton />
                  }
                </Flex>
                <Flex grow={0} className={cnb("unstake-card-row-value")}>
                  {currentPool &&
                    <>
                      120 days
                    </>
                  }
                  {!currentPool &&
                    <Skeleton />
                  }
                </Flex>
              </FlexContainer>
            }
            <FlexContainer
              direction="row"
              justify="space-between"
              align="stretch"
              className={cnb("unstake-card-row")}
            >
              <Flex grow={1} className={cnb("unstake-card-row-title")}>
                {currentPool &&
                  <>Expected reward</>
                }
                {!currentPool &&
                  <Skeleton />
                }
              </Flex>
              <Flex grow={0} className={cnb("unstake-card-row-value")}>
                {currentPool &&
                  <>
                    {expectedReward} {currentPool?.rewardTokenName}
                  </>
                }
                {!currentPool &&
                  <Skeleton />
                }
              </Flex>
            </FlexContainer>
          </Flex>
        </FlexContainer>
        <Panel
          collapse={true}
          className={cnb("unstake-card-fees")}
          header={<div className={"title title-normal"}>Fees details</div>}
        >
        <FlexContainer
          direction="column"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1}>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="stretch"
              className={cnb("unstake-card-row")}
            >
              <Flex grow={1} className={cnb("unstake-card-row-title")}>
                Provider
              </Flex>
              <Flex grow={0} className={cnb("unstake-card-row-value")}>
                {currentPool &&
                  <>
                    {numberFormat(providerFee, 2, 2)} {currentPool.stakeTokenName}
                  </>
                }
                {!currentPool &&
                  <Skeleton />
                }
              </Flex>
            </FlexContainer>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="stretch"
              className={cnb("unstake-card-row")}
            >
              <Flex grow={1} className={cnb("unstake-card-row-title")}>
                Network
              </Flex>
              <Flex grow={0} className={cnb("unstake-card-row-value")}>

                {currentPool &&
                  <>
                    ~ {networkFee} {currentPool.stakeTokenName}
                  </>
                }
                {!currentPool &&
                  <Skeleton />
                }
              </Flex>
            </FlexContainer>
            <FlexContainer
              direction="row"
              justify="space-between"
              align="stretch"
              className={cnb("unstake-card-row")}
            >
              <Flex grow={1} className={cnb("unstake-card-row-title")}>
                Execution
              </Flex>
              <Flex grow={0} className={cnb("unstake-card-row-value")}>
                {currentPool &&
                  <>
                    up to {executionFee} {currentPool.stakeTokenName}
                  </>
                }
                {!currentPool &&
                  <Skeleton />
                }
              </Flex>
            </FlexContainer>
          </Flex>
        </FlexContainer>
        </Panel>
        {currentPool &&
          <FlexContainer
            direction="column"
            justify="space-between"
            align="stretch"
          >
            <Flex grow={1}>
              <Button color="primary" size="large" onClick={() => confirm()} className={cnb("proceed-btn")} disabled={inProgress}>
                {inProgress && <Loader className={cnb("progress")} />} Proceed to deposit
              </Button>
            </Flex>
          </FlexContainer>
        }
      </div>
    </Container >
  </>
  );
};

export default Confirm;
