/* Global Dependencies */
import classnames from "classnames/bind";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

import {
  Button,
  Flex,
  FlexContainer,
  Icon,
  Skeleton,
  Input,
} from "../../components";
import { Connect } from "../../patterns";
import { poolSelector } from "../../store";
import { TPoolPeriod } from "../../types";
import { toNano, toNanoAndFormat } from "../../utils";
import styles from "./Stake.module.scss";

import { useFormik, FormikProvider, Field } from "formik";
import * as Yup from "yup";

interface FormValues {
  amount: number | undefined;
}

/* Component */
const cnb = classnames.bind(styles);

export const Stake = ({ validator }: { validator?: boolean }) => {
  const navigate = useNavigate();

  const { pool } = useParams<{ pool: string }>();
  const currentPool = useRecoilValue(poolSelector(pool));

  const [stake, setStake] = useState<number>();
  const [selected, setSelected] = useState<number>(0);
  const [selectedExclusive, setSelectedExclusive] = useState<number>(0);

  useEffect(() => {
    if (currentPool?.config) {
      setSelected(lockOptions.length - 1);
    }
  }, [currentPool]);

  const lockOptions = currentPool
    ? Object.values(currentPool?.config?.periods)
    : Array(5).fill({
        title: false,
        secs: false,
        APY: false,
      });

  const exclusiveOptions = [
    {
      term: "Boost Rewards",
      value: "+ 50%",
    },
  ];

  const confirm = () => {
    navigate(
      validator
        ? `/validator-confirm/${pool}/${stake}/${selected}`
        : `/confirm/${pool}/${stake}/${selected}`
    );
  };

  const goBack = () => {
    if (currentPool) {
      navigate(
        validator
          ? `/validate/${currentPool?.uniqueName}`
          : `/pool/${currentPool?.uniqueName}`
      );
    }
  };

  const maxStake = (capacity: string | undefined): number => {
    if (capacity && toNano(parseInt(capacity)) > 1000000) {
      return 1000000;
    } else if (capacity && toNano(parseInt(capacity)) <= 1000000) {
      return toNano(parseInt(capacity));
    }
    return 0;
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Nice try😄")
      .required("")
      .positive("Nice try😄")
      .min(
        validator
          ? 100000
          : toNano(parseInt(currentPool?.config?.minStake || "100")),
        "Minimum " +
          (validator
            ? 100000
            : toNano(parseInt(currentPool?.config?.minStake || "100")))
      )
      .max(
        maxStake(currentPool?.remainingCapacity),
        "Maximum " + maxStake(currentPool?.remainingCapacity)
      ),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      amount: undefined,
    },
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: ({}, { setSubmitting }) => {},
  });

  useEffect(() => {
    if (formik.values.amount) setStake(formik.values.amount);
  }, [formik.values.amount]);

  return (
    <>
      <Container
        fluid
        className={cnb("content-container container-overflow-hidden")}
      >
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
          className={cnb("stake-header")}
        >
          <Flex grow={1}>
            <Button
              size="large"
              className="back-btn"
              disabled={!currentPool}
              onClick={goBack}
            >
              <Icon icon={"arrow-left"} />
            </Button>
          </Flex>
        </FlexContainer>
        <div className="inner-page-wrapper">
          <FlexContainer
            direction="row"
            justify="space-between"
            align="stretch"
          >
            <Flex grow={1} className={cnb("stake-title")}>
              Stake ·{" "}
              {currentPool ? (
                validator ? (
                  "Validate " + currentPool.poolName.split(" ")[0]
                ) : (
                  currentPool.poolName
                )
              ) : (
                <Skeleton />
              )}
            </Flex>
          </FlexContainer>
          <FlexContainer
            direction="row"
            justify="space-between"
            align="stretch"
            className={cnb("stake-block-header")}
          >
            <Flex grow={1}>Lock time</Flex>
          </FlexContainer>
          <FlexContainer
            direction="column"
            justify="space-between"
            align="stretch"
            className={cnb("stake-lock-block")}
          >
            <Flex grow={1}>
              {currentPool &&
                currentPool.uniqueName === "gosh" &&
                lockOptions &&
                lockOptions.map((option: TPoolPeriod, index: number) => (
                  <FlexContainer
                    key={index}
                    direction="row"
                    justify="space-between"
                    align="center"
                    onClick={() => setSelected(index)}
                    className={cnb("stake-lock-option", {
                      selected: selected === index,
                    })}
                  >
                    <Flex grow={2} className={cnb("stake-lock-option-term")}>
                      {currentPool ? option.title : <Skeleton />}
                    </Flex>
                    <Flex grow={1} className={cnb("stake-lock-option-value")}>
                      {currentPool ? (
                        `${+option.APY / 10}% APR`
                      ) : (
                        <Skeleton wide="half" />
                      )}
                    </Flex>
                    <Flex grow={0} className={cnb("stake-lock-option-radio")}>
                      {selected === index ? (
                        <Icon icon={"radio-checked"} />
                      ) : (
                        <Icon icon={"radio-unchecked"} />
                      )}
                    </Flex>
                  </FlexContainer>
                ))}
              {currentPool &&
                currentPool.uniqueName === "boosted" &&
                lockOptions &&
                lockOptions.map((option: TPoolPeriod, index: number) => (
                  <FlexContainer
                    key={index}
                    direction="row"
                    justify="space-between"
                    align="center"
                    onClick={() => setSelected(index)}
                    className={cnb("stake-lock-option", {
                      selected: selected === index,
                    })}
                  >
                    <Flex grow={2} className={cnb("stake-lock-option-term")}>
                      {currentPool ? option.title : <Skeleton />}
                    </Flex>
                    <Flex grow={1} className={cnb("stake-lock-option-value")}>
                      {currentPool ? (
                        `12.0 + ${(+option.APY / 10).toFixed(1)}% APR`
                      ) : (
                        <Skeleton wide="half" />
                      )}
                    </Flex>
                    <Flex grow={0} className={cnb("stake-lock-option-radio")}>
                      {selected === index ? (
                        <Icon icon={"radio-checked"} />
                      ) : (
                        <Icon icon={"radio-unchecked"} />
                      )}
                    </Flex>
                  </FlexContainer>
                ))}
            </Flex>
          </FlexContainer>
          {currentPool && (
            <>
              {/* <FlexContainer
              direction="row"
              justify="space-between"
              align="stretch"
              className={cnb("stake-block-header")}
            >
              <Flex grow={1} >
                Ever Surf exclusive
              </Flex>
              <Flex grow={0} className={cnb("stake-block-limited")}>
                limited offer
              </Flex>
            </FlexContainer>
            <FlexContainer
              direction="column"
              justify="space-between"
              align="stretch"
              className={cnb("stake-lock-block")}
            >
              <Flex grow={1}>
                {exclusiveOptions && exclusiveOptions.map((option, index) => (
                  <FlexContainer
                    key={index}
                    direction="row"
                    justify="space-between"
                    align="center"
                    onClick={() => (setSelectedExclusive(index))}
                    className={cnb("stake-lock-option", { "selected": selectedExclusive === index })}
                  >
                    <Flex grow={2} className={cnb("stake-lock-option-term")}>
                      {option.term}
                    </Flex>
                    <Flex grow={1} className={cnb("stake-lock-option-value")}>
                      {option.value}
                    </Flex>
                    <Flex grow={0} className={cnb("stake-lock-option-radio")}>
                      {selectedExclusive === index ? (
                        <Icon icon={"radio-checked"} />
                      ) : (<Icon icon={"radio-unchecked"} />)}
                    </Flex>
                  </FlexContainer>
                ))}
              </Flex>
            </FlexContainer> */}
              <FlexContainer
                direction="row"
                justify="space-between"
                align="stretch"
                className={cnb("stake-block-header")}
              >
                <Flex grow={1}>Stake</Flex>
              </FlexContainer>
              <FlexContainer
                direction="row"
                justify="space-between"
                align="stretch"
              >
                <Flex grow={1}>
                  <FlexContainer
                    direction="row"
                    justify="stretch"
                    align="center"
                    className={cnb("stake-card")}
                  >
                    <Flex grow={1}>
                      <FormikProvider value={formik}>
                        <Field
                          name={"amount"}
                          as={Input}
                          type={"number"}
                          label={"Amount"}
                          // caption={"Type carefully"}
                          placeholder={""}
                          composition={"slide"}
                          units={"EVER"}
                          caption={
                            currentPool
                              ? `min. ${
                                  validator
                                    ? 100000
                                    : toNano(
                                        parseInt(
                                          currentPool?.config?.minStake || "100"
                                        )
                                      )
                                } — ${maxStake(
                                  currentPool.remainingCapacity
                                )} max.`
                              : "min — max"
                          }
                          validation={formik.errors["amount"]}
                          // mask="+x (xxx) xxx-xx-xx"
                          // value={value}
                          // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setValue(e.target.value)}}
                        />
                      </FormikProvider>
                    </Flex>
                    {/* <Flex grow={1}>

                      <FlexContainer
                        direction="column"
                        justify="space-between"
                        align="stretch"
                      >
                        <Flex grow={1} className={cnb("stake-input-label")}>
                          <label htmlFor="stake-val">Amount</label>
                        </Flex>
                        <Flex grow={1} className={cnb("stake-input")}>
                          <input id="stake-val" placeholder="0" type="number" value={stake} onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                            onChange={(ev) => setStake(ev.target.value)} />
                        </Flex>
                      </FlexContainer>
                  </Flex>
                  <Flex grow={0} className={cnb("currency")}>EVER</Flex> */}
                  </FlexContainer>

                  <FlexContainer
                    direction="column"
                    justify="space-between"
                    align="stretch"
                  >
                    <Flex grow={1}>
                      <Button
                        color="primary"
                        size="large"
                        onClick={() => confirm()}
                        disabled={
                          !stake ||
                          !formik.values.amount ||
                          Boolean(formik.errors.amount)
                        }
                        className={cnb("confirm-btn")}
                      >
                        Confirm
                      </Button>
                    </Flex>
                    <Flex grow={1} className={cnb("stake-confirm-desc")}>
                      Note that you will be unable to unstake until the lock
                      period&nbsp;finishes
                    </Flex>
                  </FlexContainer>
                </Flex>
              </FlexContainer>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default Stake;
