export const abi = `{
	"ABI version": 2,
	"version": "2.2",
	"header": ["time"],
	"functions": [
    {
      "name": "onGetPools",
      "inputs": [
          {"components":[{"name":"active","type":"bool"},{"name":"poolAddr","type":"optional(address)"},{"components":[{"name":"capacity","type":"uint128"},{"name":"minStake","type":"uint128"},{"components":[{"name":"secs","type":"uint32"},{"name":"title","type":"string"},{"name":"APY","type":"uint128"}],"name":"periods","type":"map(uint8,tuple)"},{"name":"gasFeeForStake","type":"uint128"},{"name":"gasFeeForWithdraw","type":"uint128"},{"name":"providerFee","type":"uint128"},{"name":"closed","type":"bool"},{"name":"accountImage","type":"cell"}],"name":"config","type":"optional(tuple)"},{"name":"remainingCapacity","type":"uint128"},{"name":"poolName","type":"string"},{"name":"stakeTokenName","type":"string"},{"name":"rewardTokenName","type":"string"},{"name":"rewardTokenType","type":"string"},{"name":"uniqueName","type":"string"},{"name":"maxAPY","type":"string"},{"name":"minAPY","type":"string"}],"name":"pools","type":"tuple[]"}
      ],
      "outputs": [
      ]
    },
    {
      "name": "onStake",
      "inputs": [
          {"name":"status","type":"uint8"}
      ],
      "outputs": [
      ]
    },
    {
      "name": "onGetStakes",
      "inputs": [
          {"components":[{"name":"addr","type":"address"},{"name":"createdAt","type":"uint64"},{"name":"amount","type":"uint128"},{"name":"lockSecs","type":"uint32"},{"name":"yield","type":"uint128"},{"name":"reward","type":"uint128"},{"name":"indexAddr","type":"address"},{"name":"indexData","type":"optional(cell)"}],"name":"stakes","type":"tuple[]"}
      ],
      "outputs": [
      ]
  },
    {
      "name": "onGetConfig",
      "inputs": [
          {"components":[{"name":"capacity","type":"uint128"},{"name":"minStake","type":"uint128"},{"components":[{"name":"secs","type":"uint32"},{"name":"title","type":"string"},{"name":"APY","type":"uint128"}],"name":"periods","type":"map(uint8,tuple)"},{"name":"gasFeeForStake","type":"uint128"},{"name":"gasFeeForWithdraw","type":"uint128"},{"name":"providerFee","type":"uint128"},{"name":"closed","type":"bool"},{"name":"accountImage","type":"cell"}],"name":"config","type":"tuple"},
          {"name":"remainingCapacity","type":"uint128"}
      ],
      "outputs": [
      ]
    },
    {
      "name": "onWithdraw",
      "inputs": [
          {"name":"status","type":"uint8"}
      ],
      "outputs": [
      ]
    }
	]
}`;

export const abiBalance = `{
	"ABI version": 2,
	"version": "2.2",
	"header": ["time"],
	"functions": [
		{
			"name": "onGetBalance",
			"inputs": [
				{"name":"balance","type":"uint64"}
			],
			"outputs": [
			]
		}
	]
}`;

export const abiRates = `{
	"ABI version": 2,
	"version": "2.2",
	"header": ["time"],
	"functions": [
		{
      "name": "onGetDexTokenPrice",
      "inputs": [
          {"components":[{"name":"price","type":"uint256"},{"name":"decimals","type":"uint32"},{"name":"priceStr","type":"string"}],"name":"price","type":"optional(tuple)"}
      ],
      "outputs": [
      ]
    }
	]
}`;

export default {
  abi,
  abiBalance,
  abiRates
}
