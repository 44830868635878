import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Widget as WidgetPage } from "../views";

const Widget = ({ path }: { path?: string }) => {
  const [toast, setToast] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <WidgetPage />
    </>
  );
};

export default Widget;
