/* Global Dependencies */
import classnames from "classnames/bind";
import { useEffect, useState } from 'react';
/* Local Dependencies */
import DebotClient from '../../client/client';
import { Flex, FlexContainer, Skeleton } from '../../components';
import { TGetSupportedPoolsResponse, TPool } from '../../types';
import { toNanoAndFormat } from '../../utils';
import styles from "./Widget.module.scss";

/* Component */

const cnb = classnames.bind(styles);

export const Widget = () => {
  const [remainingCapacity, setRemainingCapacity] = useState<string>("");
  const [fill, setFill] = useState<string>("0.0");
  useEffect(() => {
    updatePools();
  }, []);

  const updatePools = () => {
    DebotClient.getSupportedPools().then((value: TGetSupportedPoolsResponse) => {
      if (value && value.pools) {
        const goshPool = value.pools.find((el: TPool) => el.uniqueName === "gosh")
        if (goshPool) {
          setRemainingCapacity(toNanoAndFormat(+goshPool?.remainingCapacity));
          const filled = (((+goshPool.config.capacity - +goshPool?.remainingCapacity) / +goshPool.config.capacity * 100)).toFixed(1);
          setFill(filled);
        }
      }
      setTimeout(() => {
        updatePools();
      }, 30000);
    });
  }
  return (<>
    <div className={cnb("center")}>
      <div className={cnb("widget-wrap")}>
        <div className={cnb("widget")}>
          <FlexContainer
            direction="row"
            justify="space-between"
            align="stretch"
          >
            <Flex grow={0} className={cnb("title")}>
              Remaining <br />capacity
            </Flex>
            <Flex grow={1} className={cnb("desc")}>
              {remainingCapacity &&
                <><span className={cnb("coins")}>{remainingCapacity} EVER</span> <br />
                  filled <span className={cnb("percentage")}>{fill}%</span></>
              }
              {!remainingCapacity &&
                <>
                  <Skeleton /><br />
                  <Skeleton />
                </>}
            </Flex>
          </FlexContainer>
        </div>
      </div>
    </div>
  </>
  );
};

export default Widget;
