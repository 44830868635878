
/* Global Dependencies */
import classnames from "classnames/bind";
import QRCodeStyling from "qr-code-styling";
import { useEffect, useRef, useState } from 'react';
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
/* Local Dependencies */
import { Button, Flex, FlexContainer, Icon } from '../../components';
import { poolSelector } from '../../store';
import styles from "./Done.module.scss";


/* Component */
const cnb = classnames.bind(styles);

export const Done = ({ validator }: { validator?: boolean }) => {
  const navigate = useNavigate();

  const { pool } = useParams<{ pool: string }>();
  const { amount } = useParams<{ amount: string }>();
  const { period } = useParams<{ period: string }>();

  const currentPool = useRecoilValue(poolSelector(pool));

  const [url, setUrl] = useState<string>("");
  const refQr = useRef<HTMLDivElement>(null);

  const qrCode = new QRCodeStyling({
    width: validator ? 300 : 236,
    height: validator ? 300 : 236,
    type: "svg",
    margin: 0,
    qrOptions: {
      typeNumber: 0,
      mode: "Byte",
      errorCorrectionLevel: 'Q'
    },
    imageOptions: {
      hideBackgroundDots: false,
      margin: 0
    },
    dotsOptions: {
      type: "rounded",
      color: "#20262A"
    },
    backgroundOptions: {
      color: "transparent"
    },
    image: "",
    cornersSquareOptions: {
      type: undefined,
      color: "#20262A"
    },
    cornersDotOptions: {
      type: undefined,
      color: "#20262A"
    }
  });

  const DEBOT_ADDRESS = "0:bee7a7d2ba02444893652c8a77bfbef4f15631895cf18abe46a69a03a3f8dd58";

  const buildStakeManifest = (
    pool: string,
    amount: number,
    period: string,
  ): string => JSON.stringify({
    "version": 0,
    "debotAddress": "0:bee7a7d2ba02444893652c8a77bfbef4f15631895cf18abe46a69a03a3f8dd58",
    "init": {
      "method": "invokeStake",
      "params": {
        "pool": pool,
        "amount": amount,
        "period": period
      }
    },
    "abi": {
      "ABI version": 2,
      "version": "2.2",
      "header": ["time"],
      "functions": [
        {
          "name": "onStake",
          "inputs": [
            { "name": "status", "type": "uint8" }
          ],
          "outputs": [
          ]
        },
      ]
    },
    "quiet": false,
    "chain": []
  });

  const buildValidatorStakeManifest = (
    pool: string,
    amount: number,
    period: string,
  ): string => JSON.stringify({
    "version": 0,
    "debotAddress": "0:a06b2c6aa85bba895e356193a100e192c649edbcefe8eead511804c9fb8521cb",
    "init": {
      "method": "invokeValidatorStake",
      "params": {
        "pool": pool,
        "amount": amount,
        "period": period
      }
    },
    "quiet": false,
    "chain": []
  });

  useEffect(() => {
    if (pool && amount && period && currentPool) {
      const netAlias: string = process && process.env.REACT_APP_NET_ALIAS ? process.env.REACT_APP_NET_ALIAS : 'devnet';
      console.log(netAlias);
      setUrl(`https://uri.ever.surf/debot/${DEBOT_ADDRESS}?manifest=${Buffer.from(validator ? buildValidatorStakeManifest(currentPool?.poolAddr, +amount * (10 ** 9), period) : buildStakeManifest(currentPool?.poolAddr, +amount * (10 ** 9), period)).toString("base64").replaceAll('/+', '_-').replaceAll('=', '')}&net=${netAlias}`);
    }
  }, [pool, amount, period, currentPool]);

  useEffect(() => {
    if (refQr.current && url) {
      qrCode.update({
        data: url
      });
      refQr.current.innerHTML = '';
      qrCode.append(refQr.current);
      refQr.current.querySelector("svg")!.setAttribute("viewBox", validator ? "0 0 300 300" : "0 0 236 236");
    }
  }, [refQr, url]);

  const goBack = () => {
    if (currentPool) {
      navigate(validator
        ? `/validator-confirm/${pool}/${amount}/${period}`
        : `/confirm/${pool}/${amount}/${period}`);
    }
  }

  const goBackToPool = () => {
    if (currentPool) {
      navigate(validator
        ? `/validate/${pool}`
        : `/pool/${pool}`);
    }
  }

  return (<>
    <Container fluid className={cnb("content-container container-overflow-hidden")}>
      <FlexContainer
        direction="row"
        justify="space-between"
        align="stretch"
        className={cnb("stake-header")}
      >
        <Flex grow={1}>
          <Button size="large" className={cnb("back-btn")} disabled={!currentPool} onClick={goBackToPool}>
            Close <Icon icon={"close"} />
          </Button>
        </Flex>
      </FlexContainer>
      <div className="inner-page-wrapper">
        <FlexContainer
          direction="row"
          justify="space-between"
          align="stretch"
        >
          <Flex grow={1} className={cnb("done-title")}>
            Confirm action
          </Flex>
        </FlexContainer>
        <FlexContainer
          direction="column"
          justify="center"
          align="center"
        >

          <Flex grow={1}>
            <div ref={refQr} className={cnb("qrcode")}></div>
          </Flex>
          <Flex grow={1} className={cnb("qr-desc")}>
            Scan this QR code or follow the everlink to make a safe action
          </Flex>
          <Flex grow={1} className={cnb("confirm-desc")}>
            <a href={url} target="_blank" rel="noreferrer">
              <Button size="large" color='primary' className={cnb("done-btn")}>
                Confirm with Everlink
              </Button>
            </a>
          </Flex>
          <Flex grow={1}>
            <Button size="large" className={cnb("done-btn")} onClick={() => navigate("/")}>Done</Button>
          </Flex>
        </FlexContainer>
      </div>
    </Container >
  </>
  );
};

export default Done;
