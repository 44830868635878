import React, { useRef, DetailedHTMLProps, ButtonHTMLAttributes } from "react";
import { Button as BsButton, ButtonProps as BsButtonProps } from "react-bootstrap";
import styles from './Button.module.scss';
import classnames from "classnames/bind";

const cnb = classnames.bind(styles);

export declare type CommonButtonProps = 'href' | 'size' | 'variant' | 'disabled';

export interface IButtonIcon {
  icon: React.ReactNode,
  animation?: "none" | "left" | "right"
};

export interface IButton extends Omit<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, "onSelect"> {
  type?: "button" | "submit" | "reset" 
  active?: boolean,
  size?: "small" | "medium" | "large",
  variant?: "button" | "action",
  color?: "default" | "primary" | "secondary" | "success" | "failure" | "transparent" | "clear",
  disabled?: boolean,
  className?: string,
  children?: React.ReactNode,
  theme?: any,
  iconLeft?: IButtonIcon,
  iconRight?: IButtonIcon,
}

export const Button = ({
  disabled,
  type,
  size = "medium",
  variant = "button",
  color = "default",
  children,
  iconLeft,
  iconRight,
  onClick,
  className,
  ...props
}: IButton) => {
  // const newProps:BsButtonProps = {...(props as BsButtonProps), size: undefined} as BsButtonProps;
  const ref = useRef<HTMLButtonElement>(null)

  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current.addEventListener("mousemove", hoverHandler);
  //   }
  //   return () => {
  //     if (ref.current) {
  //       ref.current.removeEventListener("mousemove", hoverHandler);
  //     }
  //   }
  // }, [ref.current])

  // newProps.className = newProps.className + ' action action-normal';

  return <button
    ref={ref}
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={cnb(
      "button",
      "button-" + color,
      "button-" + size,
      children ? "button" : "button-icon-only",
      "button-variant-" + variant,
      { "button-icon-left": iconLeft },
      { "button-icon-right": iconRight },
      "action",
      ((size)=> {
        switch (size) {
          case "small":
            return "action-small";
          case "medium":
            return "action-medium";
          case "large":
            return "action-normal";
        }
      })(size),
      className
    )}
    {...props}
    >
      {iconLeft ? React.cloneElement(iconLeft.icon as React.ReactElement<any>, {
        className: cnb("icon-left")
      }) : <></>}
      {
      ((variant)=> {
        switch (variant) {
          case "button":
            return children;
          case "action":
            return <span>{children}</span>;
        }
      })(variant)}

      {iconRight ? React.cloneElement(iconRight.icon as React.ReactElement<any>, {
        className: cnb("icon-right", (iconRight.icon as React.ReactElement).props ? (iconRight.icon as React.ReactElement).props.className : "")
      }) : <></>}
  </button>;
};

export default Button;