import { ReactNode } from "react";
import { Modal as ModalBootstrap } from "react-bootstrap";

import styles from "./Modal.module.scss";
import classnames from "classnames/bind";
import { Flex, FlexContainer, Button, Icon } from "../../components";

const cn = classnames.bind(styles);

interface ModalProps {
  show: boolean,
  onHide?: () => void,
  size?: "sm" | "lg" | "xl" | "fullscreen" | undefined,
  className?: string,
  header?: ReactNode,
  fullscreen?: true | "sm-down" | "md-down" | "lg-down" | "xl-down" | "xxl-down" | undefined,
  body?: ReactNode,
  footer?: ReactNode,
  [key: string]: any
}

export const Modal = ({
  show,
  onHide,
  size,
  className,
  header,
  body,
  fullscreen,
  footer,
  ...props
}: ModalProps) => {
  return (
    <ModalBootstrap
      show={show}
      onHide={onHide}
      fullscreen={fullscreen}
      backdropClassName={cn("modal-backdrop", props.backdropClassName)}
      className={cn("modal", className)}
      {...props}
    >
      {header && <ModalBootstrap.Header>
        <FlexContainer
          justify="space-between"
          align="center"
          style={{width: "100%"}}
        >
          <Flex grow={1}><ModalBootstrap.Title>{header}</ModalBootstrap.Title></Flex>
          <Flex>
            <Button
              color="default"
              size="medium"
              disabled={false}
              onClick={onHide}
              iconLeft={{
                icon: <Icon icon="close"/>,
                animation: "none"
              }}
            ></Button>
          </Flex>
        </FlexContainer>
      </ModalBootstrap.Header>}
      <ModalBootstrap.Body>
        {body}
      </ModalBootstrap.Body>
      {footer && <ModalBootstrap.Footer>
        {footer}
      </ModalBootstrap.Footer>}
    </ModalBootstrap>
  );
};

export const Overlay = ({
  show,
  onHide,
  size,
  className,
  header,
  body,
  footer,
  ...props
}: ModalProps) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdropClassName={cn("modal-overlay-backdrop")}
      fullscreen={true}
      className={cn("modal-overlay", className)}
      header={header}
      body={body}
      footer={footer}
      {...props} 
    />
  );
};


export default Modal;
