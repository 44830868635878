import classnames from "classnames/bind";
import { useEffect, useRef, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import useOutsideClick from "../../utils/outsideClickCallback";
import styles from "./Header.module.scss";
import DebotClient from "../../client/client";

const cn = classnames.bind(styles);
const cnb = classnames.bind(styles);

export const Header = ({ location, ...props }: { location: string }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [animated, setAnimated] = useState<boolean>(false);

  const refPicker = useRef<HTMLDivElement>(null);
  const nodeRef = useRef(null);

  useOutsideClick(refPicker, () => { });

  const animateBack = (e: any) => {
    if (expanded) {
      setAnimated(true);
      setTimeout(() => setAnimated(false), 300);
    }
    e.stopPropagation();
    setExpanded(expanded ? false : true);
  }

  useEffect(() => {
    document.addEventListener('click', () => {
      if (expanded) {
        setAnimated(true);
        setTimeout(() => setAnimated(false), 300);
      }
      setExpanded(false);
    });
    return () => {
      document.removeEventListener('click', () => {
        if (expanded) {
          setAnimated(true);
          setTimeout(() => setAnimated(false), 300);
        }
        setExpanded(false);
      });
    }
  }, [expanded]);

  return (<>
    <header className={cn("header", { "header-centered": location === "get" })}>
      <Navbar
        expand="sm"
        expanded={expanded}
        className={cn("navbar")}
        onToggle={(e: any) => {
          if (!e.valueOf()) {
            setAnimated(true);
            setTimeout(() => setAnimated(false), 300);
          }
        }}
      >
        <Nav className={cn("navbar-nav", "me-auto")}>
          <Navbar.Brand className={cn("navbar-brand")}>
            <Link to="/" onClick={(e) => animateBack(e)} className="logo"><span>Staking <Logo /> Surf</span></Link><strong className="from">from <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.14286 1L1 4.14286H5.85714V9L9 5.85714L9 1H4.14286Z" fill="#676B6E" stroke="#676B6E" strokeLinejoin="round" />
            </svg> Everscale founders</strong></Navbar.Brand>
        </Nav>
      </Navbar>
    </header>
  </>
  );
};

export default Header;
