export const toNanoAndFormat = (nano: number, minFraction = 0, maxFraction = 0) => {
  return (nano / 10 ** 9).toLocaleString(
    "en",
    { minimumFractionDigits: minFraction, maximumFractionDigits: maxFraction }
  );
  ;
}

export const toNano = (nano: number) => {
  return (nano / 10 ** 9);
}

export const numberFormat = (digit: number, minFraction = 0, maxFraction = 0) => {
  return digit.toLocaleString(
    "en",
    { minimumFractionDigits: minFraction, maximumFractionDigits: maxFraction }
  );
  ;
}
