import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Stake as StakePage } from "../views";

const Stake = ({ path, validator }: { path?: string, validator?: boolean }) => {
  const [toast, setToast] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <StakePage validator={validator} />
    </>
  );
};

export default Stake;
