import cn from "classnames";
import React, { useLayoutEffect } from "react";
import {
  isMobile
} from "react-device-detect";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Footer, Header } from "./layouts";
import Dashboard from "./pages/dashboard";
import Pool from "./pages/pool";
import Validate from "./pages/validate";
import Stake from "./pages/stake";
import Unstake from "./pages/unstake";
import Confirm from "./pages/confirm";
import Done from "./pages/done";
import Widget from "./pages/widget";
import RealYields from "./pages/realyields";
import { usePools } from './hooks';
import { Connect } from './patterns';
import { useRates } from './hooks/useRates';
import { useRecoilState } from 'recoil';
import { walletState } from './store';
import DebotClient from './client/client';
import { Button, Icon } from "./components";

const Wrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}

const Router = () => {
  const location: string = useLocation().pathname.split('/').filter(Boolean)[0];
  const [wallet, setWallet] = useRecoilState(walletState);
  useRates();
  usePools();
  return (
    <div className={cn("ws-app", useLocation().pathname.split('/').filter(Boolean)[0], { "isMobile": isMobile, "main": !location })}>
      <Header
        location={location || "main"}
      />
      {(location !== "done" && location !== "validator-done" && location !== "kwpc" && location !== "yields") &&
        <Connect onConnect={(res) => {
          if (res && res.address) {
            console.log('CONNECT');
            setWallet({ connected: !!res.address });
            DebotClient.setUpWallet(res.address);
          }
        }} onDisconnect={() => {
          setWallet({ connected: false });
          DebotClient.setUpWallet(null);
        }} />
      }
      <main>
        <Wrapper>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/pool/:pool" element={<Pool />} />
            <Route path="/validate/:pool" element={<Validate />} />
            <Route path="/stake/:pool" element={<Stake />} />
            <Route path="/validator-stake/:pool" element={<Stake validator={true} />} />
            <Route path="/unstake" element={<Unstake />} />
            <Route path="/confirm/:pool/:amount/:period" element={<Confirm />} />
            <Route path="/validator-confirm/:pool/:amount/:period" element={<Confirm validator={true} />} />
            <Route path="/done/:pool/:amount/:period" element={<Done />} />
            <Route path="/validator-done/:pool/:amount/:period" element={<Done validator={true} />} />
            <Route path="/kwpc" element={<Widget />} />
            <Route path="/yields" element={<RealYields />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Wrapper>
      </main>
      <Footer />
    </div>
  );
};

export default Router;
