import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Dashboard as DashboardPage } from "../views";

const Dashboard = ({ path }: { path?: string }) => {
  const [toast, setToast] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <DashboardPage />
    </>
  );
};

export default Dashboard;
