import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Pool as PoolPage } from "../views";

const Pool = ({ path }: { path?: string }) => {
  const [toast, setToast] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <PoolPage />
    </>
  );
};

export default Pool;
