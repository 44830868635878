/* Global Dependencies */
import { useState, useEffect } from 'react';
import classnames from "classnames/bind";
import { Container } from "react-bootstrap";
import { Button, Flex, FlexContainer, Icon, Overlay } from '../../components';
import { isMobile } from "react-device-detect";

/* Local Dependencies */
import styles from "./RealYields.module.scss";
import { Link } from 'react-router-dom';
import { FooterContent } from '../../layouts';

/* Component */

const cnb = classnames.bind(styles);

export type Coin = {
  name: string,
  sub: string,
  id: string,
  staked: number,
  nominal: number,
  emission: number,
  realYield: number,
  lockup: string,
};

export const RealYields = () => {
  const [sorting, setSorting] = useState<string>("realYield");
  const [orderAsc, setOrderAsc] = useState<boolean>(false);
  const [coins, setCoins] = useState<Array<Coin>>([{
    name: 'Everscale',
    sub: 'EVER',
    id: 'EVER',
    staked: 13.7,
    nominal: 10.3,
    emission: 2.5,
    realYield: 7.8,
    lockup: '0',
  }, {
    name: 'BNB Chain',
    sub: 'BNB',
    id: 'BNB',
    staked: 82.1,
    nominal: 6.2,
    emission: 0.0,
    realYield: 6.2,
    lockup: '7d',
  }, {
    name: 'Polygon',
    sub: 'MATIC',
    id: 'MATIC',
    staked: 35.5,
    nominal: 7.0,
    emission: 3.1,
    realYield: 3.9,
    lockup: '21d',
  }, {
    name: 'Ethereum',
    sub: 'ETH 2.0',
    id: 'ETH',
    staked: 10.5,
    nominal: 4.1,
    emission: 0.5,
    realYield: 3.6,
    lockup: '0',
  }, {
    name: 'Avalanche',
    sub: 'AVAX',
    id: 'AVAX',
    staked: 65.0,
    nominal: 8.8,
    emission: 6.1,
    realYield: 2.7,
    lockup: '14d',
  }, {
    name: 'Cardano',
    sub: 'ADA',
    id: 'ADA',
    staked: 71.7,
    nominal: 5.0,
    emission: 3.9,
    realYield: 1.1,
    lockup: '0',
  }, {
    name: 'Solana',
    sub: 'SOL',
    id: 'SOL',
    staked: 74.0,
    nominal: 4.7,
    emission: 3.9,
    realYield: 0.8,
    lockup: '5d',
  }, {
    name: 'Fantom',
    sub: 'FTM',
    id: 'FTM',
    staked: 48.5,
    nominal: 4.6,
    emission: 7.0,
    realYield: -2.4,
    lockup: '5d',
  }]);

  useEffect(() => {
    const sortedFunc = (a: any, b: any, field: string): number => {
      if (parseInt(a[field]) < parseInt(b[field])) {
        return orderAsc ? -1 : 1;
      } else if (parseInt(a[field]) > parseInt(b[field])) {
        return orderAsc ? 1 : -1;
      } else {
        if (field === 'realYield') {
          return 0;
        } else {
          return sortedFunc(a, b, 'realYield');
        }
      }
    };

    const _sortedCoins = [...coins].sort((a: any, b: any) => sortedFunc(a, b, sorting));
    setCoins(_sortedCoins);
  }, [sorting, orderAsc]);

  const sort = (by: string) => {
    if (sorting === by) {
      setOrderAsc(!orderAsc);
    } else {
      setSorting(by);
      setOrderAsc(false);
    }
  };

  return (<>
    <Container fluid className={cnb("content-container", "yields", {"isMobile": isMobile})}>
      <FlexContainer
        justify="flex-start"
        align="center"
      >
        <Flex>
          <Link to="/"><Button
            type="button"
            size="large"
            color="default"
            iconLeft={{
              icon: <Icon icon="arrow-left" />,
              animation: "none"
            }}
          >Back</Button></Link>
        </Flex>
      </FlexContainer>
      <div className={cnb("promo-title")}>
        Native coin staking — <br />a safe haven for investors
      </div>
      <div className={cnb("promo-desc")}>
        <span>Competitive research and key metrics</span>
        {/* <a href="https://ever.surf/" target="_blank" rel="noreferrer">EVER <Icon icon="surf-outline-inline-icon" className={cnb("surf-icon")}/> SURF</a><strong>×</strong>
        <a href="https://stakingrewards.com" target="_blank" rel="noreferrer nofollow"><Icon icon="staking-rewards" className={cnb("stakingrewards-icon")}/> STAKING &nbsp;REWARDS</a> */}
      </div>
      <FlexContainer
        direction="column"
        justify="space-between"
        align="stretch"
        className={cnb("grid")}
      >
        <Flex grow={1} className={cnb("grid-header")}>
          <FlexContainer
            direction="row"
            justify="space-between"
            align="stretch"
          >
            <Flex grow={1} className={cnb("name-column", sorting === 'staked' ? (orderAsc ? "asc" : "desc") : "")} onClick={() => sort('staked')}>
              network staked
            </Flex>
            <Flex grow={1} className={cnb("nominal-column", sorting === 'nominal' ? (orderAsc ? "asc" : "desc") : "")} onClick={() => sort('nominal')}>
              nominal
            </Flex>
            <Flex grow={1} className={cnb("emission-column", sorting === 'emission' ? (orderAsc ? "asc" : "desc") : "")} onClick={() => sort('emission')}>
              emission
            </Flex>
            <Flex grow={1} className={cnb("yield-column", sorting === 'realYield' ? (orderAsc ? "asc" : "desc") : "")} onClick={() => sort('realYield')}>
              real yield
            </Flex>
            <Flex grow={1} className={cnb("lockup-column", sorting === 'lockup' ? (orderAsc ? "asc" : "desc") : "")} onClick={() => sort('lockup')}>
              lockup
            </Flex>
          </FlexContainer>
        </Flex>
        {coins.map((item, index) => <Flex grow={1} key={index}>
          <FlexContainer
            direction="row"
            justify="space-between"
            align="center"
            className={cnb("grid-row", (index === coins.length - 1) ? "grid-row-last" : "")}
          >
            <Flex grow={1} className={cnb("name-column")}>
              <FlexContainer
                key={index}
                direction="row"
                justify="space-between"
                align="center"
              >
                <Flex grow={0}>
                  <div className={cnb("coin-logo", item.id.toLowerCase())}></div>
                </Flex>
                <Flex grow={1}>
                  <div className={cnb("coin-title")}>{item.name}</div>
                  <strong>{item.sub}</strong><span>{item.staked.toFixed(1)}%</span>
                </Flex>
              </FlexContainer>
            </Flex>
            <Flex grow={1} className={cnb("nominal-column", "mono-narrow", "mono-narrow-large")}>
              {item.nominal.toFixed(1)}%
            </Flex>
            <Flex grow={1} className={cnb("emission-column", "mono-narrow", "mono-narrow-large")}>
              {item.emission.toFixed(1)}%
            </Flex>
            <Flex grow={1} className={cnb("yield-column", "mono-narrow", "mono-narrow-large", item.realYield > 0 ? "positive" : "negative")}>
              {item.realYield.toFixed(1)}%
            </Flex>
            <Flex grow={1} className={cnb("lockup-column")}>
              {item.lockup !== '0' ? item.lockup : '—'}
            </Flex>
          </FlexContainer>
        </Flex>
        )}
      </FlexContainer>
      <FlexContainer
        direction="column"
        justify="space-between"
        align="stretch"
        className={cnb("grid-desc")}
      >
        <Flex grow={1}>
          Real yield — the annualized reward rate (APR) adjusted by the inflation of the network supply. <br /><br />
          Data provided by <a href="https://stakingrewards.com" target="_blank" rel="noreferrer nofollow">stakingrewards.com</a>
        </Flex>
      </FlexContainer>
      <FlexContainer
        direction="column"
        justify="space-between"
        align="stretch"
        className={cnb("important-info")}
      >
        <Flex grow={1} className={cnb("important-info-title")}>
          Important to know
        </Flex>
        <Flex grow={1} className={cnb("important-info-list")}>
          — Staking is the core of any Proof-of-Stake blockchain <br /><br />
          — Staking is a low-risk instrument <br /><br />
          — Coin price fluctuations don’t affect your stake <br /><br />
        </Flex>
      </FlexContainer>
      <FlexContainer
        direction="row"
        justify="left"
        align="stretch"
        className={cnb("yields-buttons")}
      >
        <Flex grow={0}>
          <a href="https://staking.surf" target="_blank" rel="noreferrer">
            <Button
              size="large"
              color="primary"
              onClick={(e) => console.log(e)}
              iconRight={{
                animation: "none",
                icon: <Icon icon="surf-outline-icon" className={cnb("")} />
              }}
            >
              Stake EVER
            </Button>
          </a>
        </Flex>
        <Flex grow={0}>
          <a href="https://octusbridge.io/" target="_blank" rel="noreferrer nofollow">
            <Button
              size="large"
              onClick={(e) => console.log(e)}
              iconRight={{
                animation: "none",
                icon: <Icon icon="everscale-icon" className={cnb("")} />
              }}
            >
              Transfer Crypto
            </Button>
          </a>
        </Flex>
      </FlexContainer>
    </Container>
  </>
  );
};

export default RealYields;
