import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Confirm as ConfirmPage } from "../views";

const Confirm = ({ path, validator }: { path?: string, validator?: boolean }) => {
  const [toast, setToast] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <ConfirmPage validator={validator} />
    </>
  );
};

export default Confirm;
