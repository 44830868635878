import { atom } from "recoil";

type TPrice = {
  decimals: string;
  price: string;
  priceStr: string;
}
type TRates = {
  price: TPrice | null;
}
export const ratesAtom = atom<TRates>({
  key: 'RatesState',
  default: {
    price: null
  }
});
