import { useEffect, useMemo } from "react";
import { useRecoilState } from "recoil";

import DebotClient from "../client/client";
import { poolsAtom } from "../store";
import { TGetSupportedPoolsResponse, TPool } from "../types";

export interface IUseCards {
  pools: TPool[],
}

export const usePools = (): IUseCards => {
  const [pools, setPools] = useRecoilState(poolsAtom);

  useEffect(() => {
    if (pools.length === 0) {
      DebotClient.getSupportedPools()
        .then((value: TGetSupportedPoolsResponse) => {
          console.log(value)
          if (value && value.pools) {
            const sortedPools = value.pools.sort((x: TPool, y: TPool) => (x.active === y.active) ? x.uniqueName.localeCompare(y.uniqueName) : x.active ? -1 : 1)
            setPools(sortedPools);
          }
        });
    }
  });

  return useMemo(
    () => ({
      pools,
    }),
    [pools],
  );
};
