import callback from "./abi";

import {
  Debot,
  StakeResponse,
  GetUserStakesResponse,
  TGetSupportedPoolsResponse,
  TStaked,
  address,
} from "../types";

const browser = import("debot-browser");

const net: string = process && process.env.REACT_APP_NET ? process.env.REACT_APP_NET : 'https://devnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d';
const netAlias: string = process && process.env.REACT_APP_NET_ALIAS ? process.env.REACT_APP_NET_ALIAS : 'devnet';

export const Debots: Map<string, Debot> = new Map([
  ['pooler', {
    title: 'pooler',
    name: net,
    alias: netAlias,
    address: '0:bee7a7d2ba02444893652c8a77bfbef4f15631895cf18abe46a69a03a3f8dd58'
  }],
  ['balance', {
    title: 'balance',
    name: 'https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d',
    alias: 'mainnet',
    address: '0:d1a8a4e26c36cd8c3604cd8e5ed8207ac5a2b69926b3c2fec8a9a1ae18e65899'
  }],
  ['rates', {
    title: 'rates',
    name: 'https://mainnet.evercloud.dev/e0a940e339b34e6ab3bdd13f1f08a52d',
    alias: 'mainnet',
    address: '0:bd9263ad32ab53e158d59a929517128f121a356db0f6e76ab9511a6172b35a03'
  }]
]);

const logger = console;

export default class DebotClient {
  static convert = (from: any, to: any) => (data: any) => Buffer.from(data, from).toString(to);

  static hexToUtf8 = DebotClient.convert('hex', 'utf8');
  static utf8ToHex = DebotClient.convert('utf8', 'hex');

  private static browserPoolerHandle: Promise<BigInt>;
  private static browserBalanceHandle: Promise<BigInt>;
  private static browserRatesHandle: Promise<BigInt>;

  constructor() {
    DebotClient.init();
  }

  static async init() {
    DebotClient.browserPoolerHandle = (await browser).create_browser(Debots.get('pooler')!.name, Debots.get('pooler')!.address);
    DebotClient.browserBalanceHandle = (await browser).create_browser(Debots.get('balance')!.name, Debots.get('balance')!.address);
    DebotClient.browserRatesHandle = (await browser).create_browser(Debots.get('rates')!.name, Debots.get('rates')!.address);
  }

  static async setUpWallet(addr: address | null) {
    (await browser).update_user_settings(await DebotClient.browserPoolerHandle, {
      wallet: addr,
    });
  }

  /**
   * Get JSON manifest for browser request
   * TODO proper typization for func and args prop
   **/
  private static buildPoolerManifest(
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('pooler')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "chain": [],
      "abi": ${callback.abi}
    }`);
  };

  private static buildBalanceManifest(
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('balance')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "chain": [],
      "abi": ${callback.abiBalance}
    }`);
  };

  private static buildRatesManifest(
    func: any,
    args: any
  ): JSON {
    return JSON.parse(`{
      "version": 0,
      "debotAddress": "${Debots.get('rates')!.address}",
      "initMethod": "${func}",
      "initArgs": ${args},
      "quiet": true,
      "chain": [],
      "abi": ${callback.abiRates}
    }`);
  };

  static async stake(
    address: string,
    amount: number,
    period: string,
  ): Promise<{ res: StakeResponse }> {
    logger.log(`Calling invokeStake...\n`);
    const manifest = DebotClient.buildPoolerManifest("invokeStake", `{"pool": "${address}", "amount": "${amount}", "period": "${period}" }`);
    console.log(manifest);
    return await (await browser).run_browser((await DebotClient.browserPoolerHandle), manifest);
  }

  static async validatorStake(
    address: address,
    amount: number,
    period: string,
  ): Promise<{ res: StakeResponse }> {
    logger.log(`Calling invokeStake...\n`);
    const manifest = DebotClient.buildPoolerManifest("invokeValidatorStake", `{"pool": "${address}", "amount": "${amount}", "period": "${period}" }`);
    console.log(manifest);
    return await (await browser).run_browser((await DebotClient.browserPoolerHandle), manifest);
  }

  static async getUserStakes(
    poolAddr: address): Promise<{ stakes: Array<TStaked> }> {
    logger.log(`Calling invokeGetUserStakes...\n`);
    const manifest = DebotClient.buildPoolerManifest("invokeGetUserStakes", `{"pool": "${poolAddr}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await DebotClient.browserPoolerHandle), manifest);
  }

  static async getSupportedPools(
  ): Promise<TGetSupportedPoolsResponse> {
    logger.log(`Calling invokeGetSupportedPools...\n`);
    const manifest = DebotClient.buildPoolerManifest("invokeGetSupportedPools", `{}`);
    console.log(manifest);
    return await (await browser).run_browser((await DebotClient.browserPoolerHandle), manifest);
  }

  static async getAccountBalance(
    addr: string
  ): Promise<any> {
    logger.log(`Calling invokeGetAccountBalance...\n`);
    const manifest = DebotClient.buildBalanceManifest("invokeGetAccountBalance", `{"addr": "${addr}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await DebotClient.browserBalanceHandle), manifest);
  }

  static async getExchangeRates(
    name: string
  ): Promise<any> {
    logger.log(`Calling invokeGetDexTokenPrice...\n`);
    const manifest = DebotClient.buildRatesManifest("invokeGetDexTokenPrice", `{"name": "${name}"}`);
    console.log(manifest);
    return await (await browser).run_browser((await DebotClient.browserRatesHandle), manifest);
  }
}

(async () => {
  new DebotClient();
})()

