import { atom, selectorFamily } from "recoil";
import { TGetSupportedPoolsResponse, TPool } from "../types";

export const poolsAtom = atom<TPool[]>({
  key: 'PoolsState',
  default: []
});

export const poolSelector = selectorFamily({
  key: 'poolSelected',
  get: (name?: string | undefined) => ({ get }) => {
    const pools = get(poolsAtom);
    return pools.find((pool) => pool.uniqueName === name);
  }
});
