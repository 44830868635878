import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Done as DonePage } from "../views";

const Done = ({ path, validator }: { path?: string, validator?: boolean }) => {
  const [toast, setToast] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <DonePage validator={validator}/>
    </>
  );
};

export default Done;
