import { atom, selectorFamily } from "recoil";

type TWallet = {
  connected: boolean;
}
export const walletState = atom<TWallet>({
  key: 'WalletState',
  default: {
    connected: false,
  }
});
