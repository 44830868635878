import React, { useState, useEffect, useRef, ReactNode } from "react";
import styles from './Dropdown.module.scss';
import { Button, FormControl, IButton, Icon } from "..";
import classnames from "classnames/bind";

import type { IFormControl, IInput } from "../../components";
import { Input, InputComponent, InputNumberComponent, Flex, FlexContainer } from "../../components";

const cnb = classnames.bind(styles);

interface IOption {
  icon?: ReactNode,
  title: ReactNode,
  caption: ReactNode,
  disabled?: boolean,
  selected?: boolean,
  hidden?: boolean,
  className?: string,
  onClick?: () => void
}
export interface IDropdownOption extends IOption {
  to?: string,
  href?: string,
}
export interface ISelectOption extends IOption {
  value: string | number,
}

interface IDropdown extends IButton {
  options: IDropdownOption[],
  title: string,
  active?: boolean,
  optionClassName?: string,
  onSelect: (option: IDropdownOption) => void
}

interface ISelect<FormValues> extends Omit<IButton, "name"> {
  options: ISelectOption[],
  value: string | number,
  defaultValue?: string,
  title: string,
  active?: boolean,
  optionClassName?: string,
  onSelect: (option: ISelectOption) => void,
  onChange?: React.FormEventHandler<HTMLElement>,
  // selected?: ISelectOption,
  name: keyof FormValues
}

type IInputSelect<FormValues> = Omit<ISelect<FormValues>, "type"> & Omit<IInput<FormValues>, "name" | "defaultValue" | "value" | "onChange" | "type"> & {
  nameSelect: keyof FormValues,
  defaultValueSelect?: string,
  valueSelect: string,
  onChangeSelect: React.FormEventHandler<HTMLElement>,
  type?: "text" | "number" | "password"
}

interface IDropdownMenu<IOption> extends IButton {
  options: IOption[],
  selected?: IOption,
  optionClassName?: string,
  show?: boolean,
  className?: string,
  selectAction: (option: IOption) => void
}

function useOutsideClick(ref: React.RefObject<HTMLDivElement>, callback: () => void) {
  useEffect(() => {
    function handleClickOutside(event: CustomEvent) {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", ((event: CustomEvent) => {
        handleClickOutside(event);
    }) as (event: Event) => void);
    return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", ((event: CustomEvent) => {
            handleClickOutside(event);
        }) as (event: Event) => void);
    };
  }, [ref]);
}

export const DropdownMenu = <IOption extends ISelectOption | IDropdownOption, >(props: IDropdownMenu<IOption>) => {
  const {
    options,
    show,
    children,
    optionClassName,
    className,
    selectAction
  } = props;

  return <div className={cnb("dropdown-menu", {"dropdown-menu-open": show})}>
    <div className={cnb("dropdown-menu-body")}>
      {options.map((option, index) => <DropdownOption<IOption> key={index} {...option} onClick={(() => selectAction(option))} className={optionClassName} />)}
    </div>
  </div>
}

export const DropdownOption = <IOption extends ISelectOption | IDropdownOption, >(props: IOption) => {
  if ("to" in props) {
    return <FlexContainer align="center" justify="flex-start" onClick={props.disabled ? () => {} : props.onClick} className={cnb("dropdown-option", {"dropdown-option-disabled": props.disabled}, props.className)}>{props.icon ? <Flex className={cnb("dropdown-option-icon")}>{props.icon}</Flex> : <></>}<Flex>{props.title}</Flex></FlexContainer>
  }
  if ("value" in props) {
    return <FlexContainer align="center" justify="flex-start" onClick={props.disabled ? () => {} : props.onClick} className={cnb("dropdown-option", {"dropdown-option-disabled": props.disabled}, props.className)}>{props.icon ? <Flex className={cnb("dropdown-option-icon")}>{props.icon}</Flex> : <></>}<Flex>{props.title}</Flex></FlexContainer>
    
  }
  return <div>Default option. Something went wrong in this component.</div>
}

// export const SelectComponent = React.forwardRef<HTMLElement, ISelect>(({
export const SelectComponent = <FormValues, >({
  disabled,
  name,
  title,
  placeholder,
  value,
  defaultValue,
  type,
  size = "medium",
  variant = "button",
  color = "default",
  children,
  iconLeft,
  iconRight,
  className,
  optionClassName,
  options = [],
  active: isActive,
  onSelect,
  onChange
}: ISelect<FormValues>, ref: React.ForwardedRef<HTMLElement>) => {
  
  const [active, setActive] = useState<boolean>(Boolean(isActive));
  const [selected, setSelected] = useState<ISelectOption | undefined>(defaultValue ? options.find(option => String(option.value) === String(value ? value : defaultValue))! : undefined);
  const refInput = useRef<HTMLInputElement>(null);
  const refPicker = useRef<HTMLDivElement>(null);

  const showDropdown = () => {
    setActive(!active);
  }
  const hideDropdown = () => {
    setActive(false);
  }

  const selectOption = (option: ISelectOption) => {
    setActive(false);
    if (onSelect) onSelect(option);
    // if (onChange) onChange(String(option.value));
  }

  useOutsideClick(refPicker, () => hideDropdown());

  useEffect(() => {
    if (defaultValue)
      setSelected(options.find(option => String(option.value) === String(defaultValue)) || options[0]);
  }, [defaultValue])


  useEffect(() => {
    if (value)
      setSelected(options.find(option => String(option.value) === String(value)) || options[0]);
  }, [value])

  useEffect(() => {
    if (selected && refInput.current)
      refInput.current.value = String(selected.value);
  }, [selected])
  

  // useEffect(() => {
  //   if (refButton.current) {
  //     refButton.current.addEventListener("click", showDropdown);
  //   }
  //   return () => {
  //     if (refButton.current) {
  //       refButton.current.addEventListener("click", showDropdown);
  //     }
  //   }
  // }, [refButton.current])

  return <div ref={refPicker} className={cnb("dropdown", {"dropdown-open": active}, {"dropdown-disabled": disabled}, "dropdown-size-" + size, className)}>
    <Button
      disabled={disabled}
      type={type}
      size={size}
      variant={variant}
      color={color}
      iconLeft={selected && selected.icon ? {icon: <span>{selected.icon}</span>, animation: "none"} : iconLeft}
      iconRight={iconRight}
      onClick={showDropdown}
    >
      {selected ? selected.title : <>{placeholder}</>}
    </Button>
    <input
      readOnly={true}
      ref={refInput}
      hidden={true}
      value={selected ? selected.value : ""}
      name={String(name)}
      onChange={onChange}
    />
    <DropdownMenu<ISelectOption>
      options={options}
      show={active}
      selectAction={selectOption}
      optionClassName={optionClassName}
    />
  </div>
};
// export const SelectComponent = React.forwardRef<HTMLElement, ISelect>(({
  export const InputSelectComponent = <FormValues, >({
    label,
    placeholder,
    clear,
    composition,
    validation,
    icon,
    units,
    action,
    disabled,
    autoComplete,
  
    type = "text",
    name,
    value,
    defaultValue,
    className,
  
    onChange,
    onFocus,
    onBlur,
  
    mask,

    optionClassName,
    options = [],
    active: isActive,
    onSelect,

    nameSelect,
    defaultValueSelect,
    valueSelect,
    onChangeSelect,

    ...props
  }: IInputSelect<FormValues>, ref: React.ForwardedRef<HTMLElement>) => {
    
    const [active, setActive] = useState<boolean>(Boolean(isActive));
    const [selected, setSelected] = useState<ISelectOption>(defaultValueSelect ? options.find(option => String(option.value) === String(value ? value : defaultValue))! : options[0]);
    const refInput = useRef<HTMLInputElement>(null);
    const refPicker = useRef<HTMLDivElement>(null);
  
    const showDropdown = () => {
      setActive(!active);
    }
    const hideDropdown = () => {
      setActive(false);
    }
  
    const selectOption = (option: ISelectOption) => {
      setActive(false);
      setSelected(option);
      // if (onChange) onChange(String(option.value));
    }
  
    useOutsideClick(refPicker, () => hideDropdown());
  
    useEffect(() => {
      if (defaultValueSelect)
        setSelected(options.find(option => String(option.value) === String(defaultValueSelect)) || options[0]);
    }, [defaultValueSelect])
  
  
    useEffect(() => {
      if (valueSelect)
        setSelected(options.find(option => String(option.value) === String(valueSelect)) || options[0]);
    }, [valueSelect])
  
    useEffect(() => {
      if (selected && refInput.current) {
        if (onSelect) onSelect(selected);
        refInput.current.value = String(selected.value);
        refInput.current.dispatchEvent(new Event("change", {
          bubbles: true
        }));
      }
    }, [selected])
    
    
  
    // useEffect(() => {
    //   if (refButton.current) {
    //     refButton.current.addEventListener("click", showDropdown);
    //   }
    //   return () => {
    //     if (refButton.current) {
    //       refButton.current.addEventListener("click", showDropdown);
    //     }
    //   }
    // }, [refButton.current])

  
    return <div ref={refPicker} className={cnb("dropdown", {"dropdown-open": active}, className)}>
      {type === "text" && <InputComponent
        name={name}
        disabled={disabled}
        composition={composition}
        action={<div onClick={disabled ? () => {} : showDropdown}>{selected ? (selected.caption ? selected.caption : selected.title) : ""}&nbsp;<Icon icon="chevron-down" /></div>}
        label={label}
        placeholder={placeholder}
        clear={clear}
        validation={validation}
        icon={icon}
        units={units}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        mask={mask}
        {...props}
      />}
      {type === "number" && <InputNumberComponent
        name={name}
        disabled={disabled}
        composition={composition}
        action={<div onClick={disabled ? () => {} : showDropdown}>{selected ? (selected.caption ? selected.caption : selected.title) : ""}&nbsp;<Icon icon="chevron-down" /></div>}
        label={label}
        placeholder={placeholder}
        clear={clear}
        validation={validation}
        icon={icon}
        units={units}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        mask={mask}
        {...props}
      />}
      <input
        readOnly={true}
        ref={refInput}
        hidden={true}
        value={selected ? selected.value : ""}
        name={String(nameSelect)}
        onInput={e => console.log("YESSS!")}
        onChange={onChangeSelect}
      />
      <DropdownMenu<ISelectOption>
        options={options}
        show={active}
        selectAction={selectOption}
        optionClassName={optionClassName}
      />
    </div>
  };

export const Select = <FormValues, >({
  title,
  validation,
  status,
  caption,
  ...props
} : IFormControl & ISelect<FormValues> & {
}) => {

  return <FormControl
    title={title}
    status={validation ? "validate" : undefined}
    caption={Boolean(validation) ? validation : caption}
  >
    <SelectComponent<FormValues>
      title={title}
      {...props}
    />
  </FormControl>;
};


export const InputSelect = <FormValues, >({
  title,
  validation,
  status,
  caption,
  ...props
} : IFormControl & IInputSelect<FormValues> & {
}) => {

  return <FormControl
    title={title}
    status={validation ? "validate" : undefined}
    caption={Boolean(validation) ? validation : caption}
  >
    <InputSelectComponent<FormValues>
      title={title}
      {...props}
    />
  </FormControl>;
};

