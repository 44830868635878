const GoshDebotQrCode = () => <svg width="72" height="72" viewBox="0 0 72 72" fill="#FDFDFD" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.7561 0H0V1.7561H1.7561V0Z" />
  <path d="M3.5122 0H1.7561V1.7561H3.5122V0Z" />
  <path d="M5.26829 0H3.5122V1.7561H5.26829V0Z" />
  <path d="M7.02439 0H5.26829V1.7561H7.02439V0Z" />
  <path d="M8.78049 0H7.02439V1.7561H8.78049V0Z" />
  <path d="M10.5366 0H8.78049V1.7561H10.5366V0Z" />
  <path d="M12.2927 0H10.5366V1.7561H12.2927V0Z" />
  <path d="M15.8049 0H14.0488V1.7561H15.8049V0Z" />
  <path d="M26.3415 0H24.5854V1.7561H26.3415V0Z" />
  <path d="M29.8537 0H28.0976V1.7561H29.8537V0Z" />
  <path d="M31.6098 0H29.8537V1.7561H31.6098V0Z" />
  <path d="M33.3659 0H31.6098V1.7561H33.3659V0Z" />
  <path d="M35.122 0H33.3659V1.7561H35.122V0Z" />
  <path d="M36.8781 0H35.122V1.7561H36.8781V0Z" />
  <path d="M38.6341 0H36.8781V1.7561H38.6341V0Z" />
  <path d="M40.3902 0H38.6341V1.7561H40.3902V0Z" />
  <path d="M42.1463 0H40.3902V1.7561H42.1463V0Z" />
  <path d="M47.4146 0H45.6585V1.7561H47.4146V0Z" />
  <path d="M49.1707 0H47.4146V1.7561H49.1707V0Z" />
  <path d="M50.9268 0H49.1707V1.7561H50.9268V0Z" />
  <path d="M52.6829 0H50.9268V1.7561H52.6829V0Z" />
  <path d="M54.439 0H52.6829V1.7561H54.439V0Z" />
  <path d="M56.1951 0H54.439V1.7561H56.1951V0Z" />
  <path d="M57.9512 0H56.1951V1.7561H57.9512V0Z" />
  <path d="M61.4634 0H59.7073V1.7561H61.4634V0Z" />
  <path d="M63.2195 0H61.4634V1.7561H63.2195V0Z" />
  <path d="M64.9756 0H63.2195V1.7561H64.9756V0Z" />
  <path d="M66.7317 0H64.9756V1.7561H66.7317V0Z" />
  <path d="M68.4878 0H66.7317V1.7561H68.4878V0Z" />
  <path d="M70.2439 0H68.4878V1.7561H70.2439V0Z" />
  <path d="M72 0H70.2439V1.7561H72V0Z" />
  <path d="M1.7561 1.7561H0V3.5122H1.7561V1.7561Z" />
  <path d="M12.2927 1.7561H10.5366V3.5122H12.2927V1.7561Z" />
  <path d="M19.3171 1.7561H17.561V3.5122H19.3171V1.7561Z" />
  <path d="M22.8293 1.7561H21.0732V3.5122H22.8293V1.7561Z" />
  <path d="M24.5854 1.7561H22.8293V3.5122H24.5854V1.7561Z" />
  <path d="M26.3415 1.7561H24.5854V3.5122H26.3415V1.7561Z" />
  <path d="M28.0976 1.7561H26.3415V3.5122H28.0976V1.7561Z" />
  <path d="M29.8537 1.7561H28.0976V3.5122H29.8537V1.7561Z" />
  <path d="M31.6098 1.7561H29.8537V3.5122H31.6098V1.7561Z" />
  <path d="M33.3659 1.7561H31.6098V3.5122H33.3659V1.7561Z" />
  <path d="M36.8781 1.7561H35.122V3.5122H36.8781V1.7561Z" />
  <path d="M38.6341 1.7561H36.8781V3.5122H38.6341V1.7561Z" />
  <path d="M40.3902 1.7561H38.6341V3.5122H40.3902V1.7561Z" />
  <path d="M42.1463 1.7561H40.3902V3.5122H42.1463V1.7561Z" />
  <path d="M43.9024 1.7561H42.1463V3.5122H43.9024V1.7561Z" />
  <path d="M45.6585 1.7561H43.9024V3.5122H45.6585V1.7561Z" />
  <path d="M47.4146 1.7561H45.6585V3.5122H47.4146V1.7561Z" />
  <path d="M49.1707 1.7561H47.4146V3.5122H49.1707V1.7561Z" />
  <path d="M61.4634 1.7561H59.7073V3.5122H61.4634V1.7561Z" />
  <path d="M72 1.7561H70.2439V3.5122H72V1.7561Z" />
  <path d="M1.7561 3.5122H0V5.26829H1.7561V3.5122Z" />
  <path d="M5.26829 3.5122H3.5122V5.26829H5.26829V3.5122Z" />
  <path d="M7.02439 3.5122H5.26829V5.26829H7.02439V3.5122Z" />
  <path d="M8.78049 3.5122H7.02439V5.26829H8.78049V3.5122Z" />
  <path d="M12.2927 3.5122H10.5366V5.26829H12.2927V3.5122Z" />
  <path d="M15.8049 3.5122H14.0488V5.26829H15.8049V3.5122Z" />
  <path d="M17.561 3.5122H15.8049V5.26829H17.561V3.5122Z" />
  <path d="M26.3415 3.5122H24.5854V5.26829H26.3415V3.5122Z" />
  <path d="M31.6098 3.5122H29.8537V5.26829H31.6098V3.5122Z" />
  <path d="M36.8781 3.5122H35.122V5.26829H36.8781V3.5122Z" />
  <path d="M42.1463 3.5122H40.3902V5.26829H42.1463V3.5122Z" />
  <path d="M43.9024 3.5122H42.1463V5.26829H43.9024V3.5122Z" />
  <path d="M45.6585 3.5122H43.9024V5.26829H45.6585V3.5122Z" />
  <path d="M57.9512 3.5122H56.1951V5.26829H57.9512V3.5122Z" />
  <path d="M61.4634 3.5122H59.7073V5.26829H61.4634V3.5122Z" />
  <path d="M64.9756 3.5122H63.2195V5.26829H64.9756V3.5122Z" />
  <path d="M66.7317 3.5122H64.9756V5.26829H66.7317V3.5122Z" />
  <path d="M68.4878 3.5122H66.7317V5.26829H68.4878V3.5122Z" />
  <path d="M72 3.5122H70.2439V5.26829H72V3.5122Z" />
  <path d="M1.7561 5.26829H0V7.02439H1.7561V5.26829Z" />
  <path d="M5.26829 5.26829H3.5122V7.02439H5.26829V5.26829Z" />
  <path d="M7.02439 5.26829H5.26829V7.02439H7.02439V5.26829Z" />
  <path d="M8.78049 5.26829H7.02439V7.02439H8.78049V5.26829Z" />
  <path d="M12.2927 5.26829H10.5366V7.02439H12.2927V5.26829Z" />
  <path d="M15.8049 5.26829H14.0488V7.02439H15.8049V5.26829Z" />
  <path d="M17.561 5.26829H15.8049V7.02439H17.561V5.26829Z" />
  <path d="M19.3171 5.26829H17.561V7.02439H19.3171V5.26829Z" />
  <path d="M21.0732 5.26829H19.3171V7.02439H21.0732V5.26829Z" />
  <path d="M22.8293 5.26829H21.0732V7.02439H22.8293V5.26829Z" />
  <path d="M24.5854 5.26829H22.8293V7.02439H24.5854V5.26829Z" />
  <path d="M26.3415 5.26829H24.5854V7.02439H26.3415V5.26829Z" />
  <path d="M31.6098 5.26829H29.8537V7.02439H31.6098V5.26829Z" />
  <path d="M33.3659 5.26829H31.6098V7.02439H33.3659V5.26829Z" />
  <path d="M43.9024 5.26829H42.1463V7.02439H43.9024V5.26829Z" />
  <path d="M45.6585 5.26829H43.9024V7.02439H45.6585V5.26829Z" />
  <path d="M47.4146 5.26829H45.6585V7.02439H47.4146V5.26829Z" />
  <path d="M49.1707 5.26829H47.4146V7.02439H49.1707V5.26829Z" />
  <path d="M54.439 5.26829H52.6829V7.02439H54.439V5.26829Z" />
  <path d="M57.9512 5.26829H56.1951V7.02439H57.9512V5.26829Z" />
  <path d="M61.4634 5.26829H59.7073V7.02439H61.4634V5.26829Z" />
  <path d="M64.9756 5.26829H63.2195V7.02439H64.9756V5.26829Z" />
  <path d="M66.7317 5.26829H64.9756V7.02439H66.7317V5.26829Z" />
  <path d="M68.4878 5.26829H66.7317V7.02439H68.4878V5.26829Z" />
  <path d="M72 5.26829H70.2439V7.02439H72V5.26829Z" />
  <path d="M1.7561 7.02439H0V8.78049H1.7561V7.02439Z" />
  <path d="M5.26829 7.02439H3.5122V8.78049H5.26829V7.02439Z" />
  <path d="M7.02439 7.02439H5.26829V8.78049H7.02439V7.02439Z" />
  <path d="M8.78049 7.02439H7.02439V8.78049H8.78049V7.02439Z" />
  <path d="M12.2927 7.02439H10.5366V8.78049H12.2927V7.02439Z" />
  <path d="M15.8049 7.02439H14.0488V8.78049H15.8049V7.02439Z" />
  <path d="M24.5854 7.02439H22.8293V8.78049H24.5854V7.02439Z" />
  <path d="M26.3415 7.02439H24.5854V8.78049H26.3415V7.02439Z" />
  <path d="M31.6098 7.02439H29.8537V8.78049H31.6098V7.02439Z" />
  <path d="M35.122 7.02439L33.3659 7.02439V8.78049H35.122V7.02439Z" />
  <path d="M36.8781 7.02439H35.122V8.78049H36.8781V7.02439Z" />
  <path d="M40.3902 7.02439H38.6341V8.78049H40.3902V7.02439Z" />
  <path d="M45.6585 7.02439H43.9024V8.78049H45.6585V7.02439Z" />
  <path d="M52.6829 7.02439L50.9268 7.02439V8.78049H52.6829V7.02439Z" />
  <path d="M54.439 7.02439H52.6829V8.78049H54.439V7.02439Z" />
  <path d="M56.1951 7.02439H54.439V8.78049H56.1951V7.02439Z" />
  <path d="M57.9512 7.02439H56.1951V8.78049H57.9512V7.02439Z" />
  <path d="M61.4634 7.02439H59.7073V8.78049H61.4634V7.02439Z" />
  <path d="M64.9756 7.02439H63.2195V8.78049H64.9756V7.02439Z" />
  <path d="M66.7317 7.02439H64.9756V8.78049H66.7317V7.02439Z" />
  <path d="M68.4878 7.02439H66.7317V8.78049H68.4878V7.02439Z" />
  <path d="M72 7.02439H70.2439V8.78049H72V7.02439Z" />
  <path d="M1.7561 8.78049H0V10.5366H1.7561V8.78049Z" />
  <path d="M12.2927 8.78049H10.5366V10.5366H12.2927V8.78049Z" />
  <path d="M15.8049 8.78049H14.0488V10.5366H15.8049V8.78049Z" />
  <path d="M21.0732 8.78049H19.3171V10.5366H21.0732V8.78049Z" />
  <path d="M24.5854 8.78049H22.8293V10.5366H24.5854V8.78049Z" />
  <path d="M26.3415 8.78049H24.5854V10.5366H26.3415V8.78049Z" />
  <path d="M29.8537 8.78049H28.0976V10.5366H29.8537V8.78049Z" />
  <path d="M31.6098 8.78049H29.8537V10.5366H31.6098V8.78049Z" />
  <path d="M33.3659 8.78049H31.6098V10.5366H33.3659V8.78049Z" />
  <path d="M35.122 8.78049H33.3659V10.5366H35.122V8.78049Z" />
  <path d="M43.9024 8.78049H42.1463V10.5366H43.9024V8.78049Z" />
  <path d="M45.6585 8.78049H43.9024V10.5366H45.6585V8.78049Z" />
  <path d="M47.4146 8.78049H45.6585V10.5366H47.4146V8.78049Z" />
  <path d="M52.6829 8.78049H50.9268V10.5366H52.6829V8.78049Z" />
  <path d="M54.439 8.78049H52.6829V10.5366H54.439V8.78049Z" />
  <path d="M57.9512 8.78049H56.1951V10.5366H57.9512V8.78049Z" />
  <path d="M61.4634 8.78049H59.7073V10.5366H61.4634V8.78049Z" />
  <path d="M72 8.78049H70.2439V10.5366H72V8.78049Z" />
  <path d="M1.7561 10.5366H0V12.2927H1.7561V10.5366Z" />
  <path d="M3.5122 10.5366H1.7561V12.2927H3.5122V10.5366Z" />
  <path d="M5.26829 10.5366H3.5122V12.2927H5.26829V10.5366Z" />
  <path d="M7.02439 10.5366H5.26829V12.2927H7.02439V10.5366Z" />
  <path d="M8.78049 10.5366H7.02439V12.2927H8.78049V10.5366Z" />
  <path d="M10.5366 10.5366H8.78049V12.2927H10.5366V10.5366Z" />
  <path d="M12.2927 10.5366H10.5366V12.2927H12.2927V10.5366Z" />
  <path d="M15.8049 10.5366H14.0488V12.2927H15.8049V10.5366Z" />
  <path d="M19.3171 10.5366H17.561V12.2927H19.3171V10.5366Z" />
  <path d="M22.8293 10.5366H21.0732V12.2927H22.8293V10.5366Z" />
  <path d="M26.3415 10.5366H24.5854V12.2927H26.3415V10.5366Z" />
  <path d="M29.8537 10.5366H28.0976V12.2927H29.8537V10.5366Z" />
  <path d="M33.3659 10.5366H31.6098V12.2927H33.3659V10.5366Z" />
  <path d="M36.8781 10.5366H35.122V12.2927H36.8781V10.5366Z" />
  <path d="M40.3902 10.5366H38.6341V12.2927H40.3902V10.5366Z" />
  <path d="M43.9024 10.5366H42.1463V12.2927H43.9024V10.5366Z" />
  <path d="M47.4146 10.5366H45.6585V12.2927H47.4146V10.5366Z" />
  <path d="M50.9268 10.5366H49.1707V12.2927H50.9268V10.5366Z" />
  <path d="M54.439 10.5366H52.6829V12.2927H54.439V10.5366Z" />
  <path d="M57.9512 10.5366H56.1951V12.2927H57.9512V10.5366Z" />
  <path d="M61.4634 10.5366H59.7073V12.2927H61.4634V10.5366Z" />
  <path d="M63.2195 10.5366H61.4634V12.2927H63.2195V10.5366Z" />
  <path d="M64.9756 10.5366H63.2195V12.2927H64.9756V10.5366Z" />
  <path d="M66.7317 10.5366H64.9756V12.2927H66.7317V10.5366Z" />
  <path d="M68.4878 10.5366H66.7317V12.2927H68.4878V10.5366Z" />
  <path d="M70.2439 10.5366H68.4878V12.2927H70.2439V10.5366Z" />
  <path d="M72 10.5366H70.2439V12.2927H72V10.5366Z" />
  <path d="M21.0732 12.2927H19.3171V14.0488H21.0732V12.2927Z" />
  <path d="M26.3415 12.2927H24.5854V14.0488H26.3415V12.2927Z" />
  <path d="M40.3902 12.2927H38.6341V14.0488H40.3902V12.2927Z" />
  <path d="M42.1463 12.2927H40.3902V14.0488H42.1463V12.2927Z" />
  <path d="M52.6829 12.2927H50.9268V14.0488H52.6829V12.2927Z" />
  <path d="M54.439 12.2927H52.6829V14.0488H54.439V12.2927Z" />
  <path d="M56.1951 12.2927H54.439V14.0488H56.1951V12.2927Z" />
  <path d="M5.26829 14.0488H3.5122V15.8049H5.26829V14.0488Z" />
  <path d="M7.02439 14.0488H5.26829V15.8049H7.02439V14.0488Z" />
  <path d="M8.78049 14.0488H7.02439V15.8049H8.78049V14.0488Z" />
  <path d="M10.5366 14.0488H8.78049V15.8049H10.5366V14.0488Z" />
  <path d="M12.2927 14.0488H10.5366V15.8049H12.2927V14.0488Z" />
  <path d="M14.0488 14.0488H12.2927V15.8049H14.0488V14.0488Z" />
  <path d="M17.561 14.0488H15.8049V15.8049H17.561V14.0488Z" />
  <path d="M21.0732 14.0488H19.3171V15.8049H21.0732V14.0488Z" />
  <path d="M29.8537 14.0488H28.0976V15.8049H29.8537V14.0488Z" />
  <path d="M31.6098 14.0488H29.8537V15.8049H31.6098V14.0488Z" />
  <path d="M36.8781 14.0488H35.122V15.8049H36.8781V14.0488Z" />
  <path d="M38.6341 14.0488H36.8781V15.8049H38.6341V14.0488Z" />
  <path d="M42.1463 14.0488H40.3902V15.8049H42.1463V14.0488Z" />
  <path d="M49.1707 14.0488H47.4146V15.8049H49.1707V14.0488Z" />
  <path d="M54.439 14.0488H52.6829V15.8049H54.439V14.0488Z" />
  <path d="M56.1951 14.0488H54.439V15.8049H56.1951V14.0488Z" />
  <path d="M57.9512 14.0488H56.1951V15.8049H57.9512V14.0488Z" />
  <path d="M59.7073 14.0488H57.9512V15.8049H59.7073V14.0488Z" />
  <path d="M63.2195 14.0488H61.4634V15.8049H63.2195V14.0488Z" />
  <path d="M64.9756 14.0488H63.2195V15.8049H64.9756V14.0488Z" />
  <path d="M66.7317 14.0488H64.9756V15.8049H66.7317V14.0488Z" />
  <path d="M68.4878 14.0488H66.7317V15.8049H68.4878V14.0488Z" />
  <path d="M72 14.0488H70.2439V15.8049H72V14.0488Z" />
  <path d="M3.5122 15.8049H1.7561V17.561H3.5122V15.8049Z" />
  <path d="M5.26829 15.8049H3.5122V17.561H5.26829V15.8049Z" />
  <path d="M7.02439 15.8049H5.26829V17.561H7.02439V15.8049Z" />
  <path d="M8.78049 15.8049H7.02439V17.561H8.78049V15.8049Z" />
  <path d="M10.5366 15.8049H8.78049V17.561H10.5366V15.8049Z" />
  <path d="M15.8049 15.8049H14.0488V17.561H15.8049V15.8049Z" />
  <path d="M17.561 15.8049H15.8049V17.561H17.561V15.8049Z" />
  <path d="M24.5854 15.8049H22.8293V17.561H24.5854V15.8049Z" />
  <path d="M29.8537 15.8049H28.0976V17.561H29.8537V15.8049Z" />
  <path d="M31.6098 15.8049H29.8537V17.561H31.6098V15.8049Z" />
  <path d="M33.3659 15.8049H31.6098V17.561H33.3659V15.8049Z" />
  <path d="M36.8781 15.8049H35.122V17.561H36.8781V15.8049Z" />
  <path d="M38.6341 15.8049H36.8781V17.561H38.6341V15.8049Z" />
  <path d="M40.3902 15.8049H38.6341V17.561H40.3902V15.8049Z" />
  <path d="M45.6585 15.8049H43.9024V17.561H45.6585V15.8049Z" />
  <path d="M47.4146 15.8049H45.6585V17.561H47.4146V15.8049Z" />
  <path d="M52.6829 15.8049H50.9268V17.561H52.6829V15.8049Z" />
  <path d="M54.439 15.8049H52.6829V17.561H54.439V15.8049Z" />
  <path d="M61.4634 15.8049H59.7073V17.561H61.4634V15.8049Z" />
  <path d="M70.2439 15.8049H68.4878V17.561H70.2439V15.8049Z" />
  <path d="M5.26829 17.561H3.5122V19.3171H5.26829V17.561Z" />
  <path d="M7.02439 17.561H5.26829V19.3171H7.02439V17.561Z" />
  <path d="M10.5366 17.561H8.78049V19.3171H10.5366V17.561Z" />
  <path d="M12.2927 17.561H10.5366V19.3171H12.2927V17.561Z" />
  <path d="M17.561 17.561H15.8049V19.3171H17.561V17.561Z" />
  <path d="M22.8293 17.561H21.0732V19.3171H22.8293V17.561Z" />
  <path d="M24.5854 17.561H22.8293V19.3171H24.5854V17.561Z" />
  <path d="M26.3415 17.561H24.5854V19.3171H26.3415V17.561Z" />
  <path d="M28.0976 17.561H26.3415V19.3171H28.0976V17.561Z" />
  <path d="M29.8537 17.561H28.0976V19.3171H29.8537V17.561Z" />
  <path d="M35.122 17.561H33.3659V19.3171H35.122V17.561Z" />
  <path d="M36.8781 17.561H35.122V19.3171H36.8781V17.561Z" />
  <path d="M38.6341 17.561H36.8781V19.3171H38.6341V17.561Z" />
  <path d="M42.1463 17.561H40.3902V19.3171H42.1463V17.561Z" />
  <path d="M43.9024 17.561H42.1463V19.3171H43.9024V17.561Z" />
  <path d="M45.6585 17.561H43.9024V19.3171H45.6585V17.561Z" />
  <path d="M57.9512 17.561H56.1951V19.3171H57.9512V17.561Z" />
  <path d="M61.4634 17.561H59.7073V19.3171H61.4634V17.561Z" />
  <path d="M64.9756 17.561H63.2195V19.3171H64.9756V17.561Z" />
  <path d="M70.2439 17.561H68.4878V19.3171H70.2439V17.561Z" />
  <path d="M72 17.561H70.2439V19.3171H72V17.561Z" />
  <path d="M3.5122 19.3171H1.7561V21.0732H3.5122V19.3171Z" />
  <path d="M5.26829 19.3171H3.5122V21.0732H5.26829V19.3171Z" />
  <path d="M7.02439 19.3171H5.26829V21.0732H7.02439V19.3171Z" />
  <path d="M8.78049 19.3171H7.02439V21.0732H8.78049V19.3171Z" />
  <path d="M10.5366 19.3171H8.78049V21.0732H10.5366V19.3171Z" />
  <path d="M17.561 19.3171H15.8049V21.0732H17.561V19.3171Z" />
  <path d="M29.8537 19.3171H28.0976V21.0732H29.8537V19.3171Z" />
  <path d="M31.6098 19.3171H29.8537V21.0732H31.6098V19.3171Z" />
  <path d="M33.3659 19.3171H31.6098V21.0732H33.3659V19.3171Z" />
  <path d="M35.122 19.3171H33.3659V21.0732H35.122V19.3171Z" />
  <path d="M36.8781 19.3171H35.122V21.0732H36.8781V19.3171Z" />
  <path d="M38.6341 19.3171H36.8781V21.0732H38.6341V19.3171Z" />
  <path d="M43.9024 19.3171H42.1463V21.0732H43.9024V19.3171Z" />
  <path d="M45.6585 19.3171H43.9024V21.0732H45.6585V19.3171Z" />
  <path d="M47.4146 19.3171H45.6585V21.0732H47.4146V19.3171Z" />
  <path d="M49.1707 19.3171H47.4146V21.0732H49.1707V19.3171Z" />
  <path d="M50.9268 19.3171H49.1707V21.0732H50.9268V19.3171Z" />
  <path d="M52.6829 19.3171H50.9268V21.0732H52.6829V19.3171Z" />
  <path d="M59.7073 19.3171H57.9512V21.0732H59.7073V19.3171Z" />
  <path d="M61.4634 19.3171H59.7073V21.0732H61.4634V19.3171Z" />
  <path d="M64.9756 19.3171H63.2195V21.0732H64.9756V19.3171Z" />
  <path d="M66.7317 19.3171H64.9756V21.0732H66.7317V19.3171Z" />
  <path d="M68.4878 19.3171H66.7317V21.0732H68.4878V19.3171Z" />
  <path d="M70.2439 19.3171H68.4878V21.0732H70.2439V19.3171Z" />
  <path d="M1.7561 21.0732H0V22.8293H1.7561V21.0732Z" />
  <path d="M3.5122 21.0732H1.7561V22.8293H3.5122V21.0732Z" />
  <path d="M7.02439 21.0732H5.26829V22.8293H7.02439V21.0732Z" />
  <path d="M8.78049 21.0732H7.02439V22.8293H8.78049V21.0732Z" />
  <path d="M12.2927 21.0732H10.5366V22.8293H12.2927V21.0732Z" />
  <path d="M14.0488 21.0732H12.2927V22.8293H14.0488V21.0732Z" />
  <path d="M15.8049 21.0732H14.0488V22.8293H15.8049V21.0732Z" />
  <path d="M17.561 21.0732H15.8049V22.8293H17.561V21.0732Z" />
  <path d="M19.3171 21.0732H17.561V22.8293H19.3171V21.0732Z" />
  <path d="M21.0732 21.0732H19.3171V22.8293H21.0732V21.0732Z" />
  <path d="M29.8537 21.0732H28.0976V22.8293H29.8537V21.0732Z" />
  <path d="M35.122 21.0732H33.3659V22.8293H35.122V21.0732Z" />
  <path d="M45.6585 21.0732H43.9024V22.8293H45.6585V21.0732Z" />
  <path d="M52.6829 21.0732H50.9268V22.8293H52.6829V21.0732Z" />
  <path d="M56.1951 21.0732H54.439V22.8293H56.1951V21.0732Z" />
  <path d="M57.9512 21.0732H56.1951V22.8293H57.9512V21.0732Z" />
  <path d="M61.4634 21.0732H59.7073V22.8293H61.4634V21.0732Z" />
  <path d="M63.2195 21.0732H61.4634V22.8293H63.2195V21.0732Z" />
  <path d="M66.7317 21.0732H64.9756V22.8293H66.7317V21.0732Z" />
  <path d="M5.26829 22.8293H3.5122V24.5854H5.26829V22.8293Z" />
  <path d="M15.8049 22.8293H14.0488V24.5854H15.8049V22.8293Z" />
  <path d="M21.0732 22.8293H19.3171V24.5854H21.0732V22.8293Z" />
  <path d="M26.3415 22.8293H24.5854V24.5854H26.3415V22.8293Z" />
  <path d="M29.8537 22.8293H28.0976V24.5854H29.8537V22.8293Z" />
  <path d="M36.8781 22.8293H35.122V24.5854H36.8781V22.8293Z" />
  <path d="M40.3902 22.8293H38.6341V24.5854H40.3902V22.8293Z" />
  <path d="M42.1463 22.8293H40.3902V24.5854H42.1463V22.8293Z" />
  <path d="M43.9024 22.8293H42.1463V24.5854H43.9024V22.8293Z" />
  <path d="M57.9512 22.8293H56.1951V24.5854H57.9512V22.8293Z" />
  <path d="M61.4634 22.8293H59.7073V24.5854H61.4634V22.8293Z" />
  <path d="M64.9756 22.8293H63.2195V24.5854H64.9756V22.8293Z" />
  <path d="M66.7317 22.8293H64.9756V24.5854H66.7317V22.8293Z" />
  <path d="M68.4878 22.8293H66.7317V24.5854H68.4878V22.8293Z" />
  <path d="M70.2439 22.8293H68.4878V24.5854H70.2439V22.8293Z" />
  <path d="M1.7561 24.5854H0V26.3415H1.7561V24.5854Z" />
  <path d="M3.5122 24.5854H1.7561V26.3415H3.5122V24.5854Z" />
  <path d="M7.02439 24.5854H5.26829V26.3415H7.02439V24.5854Z" />
  <path d="M10.5366 24.5854H8.78049V26.3415H10.5366V24.5854Z" />
  <path d="M12.2927 24.5854H10.5366V26.3415H12.2927V24.5854Z" />
  <path d="M15.8049 24.5854H14.0488V26.3415H15.8049V24.5854Z" />
  <path d="M17.561 24.5854H15.8049V26.3415H17.561V24.5854Z" />
  <path d="M19.3171 24.5854H17.561V26.3415H19.3171V24.5854Z" />
  <path d="M21.0732 24.5854H19.3171V26.3415H21.0732V24.5854Z" />
  <path d="M26.3415 24.5854H24.5854V26.3415H26.3415V24.5854Z" />
  <path d="M28.0976 24.5854H26.3415V26.3415H28.0976V24.5854Z" />
  <path d="M33.3659 24.5854H31.6098V26.3415H33.3659V24.5854Z" />
  <path d="M42.1463 24.5854H40.3902V26.3415H42.1463V24.5854Z" />
  <path d="M45.6585 24.5854H43.9024V26.3415H45.6585V24.5854Z" />
  <path d="M47.4146 24.5854H45.6585V26.3415H47.4146V24.5854Z" />
  <path d="M50.9268 24.5854H49.1707V26.3415H50.9268V24.5854Z" />
  <path d="M52.6829 24.5854H50.9268V26.3415H52.6829V24.5854Z" />
  <path d="M56.1951 24.5854H54.439V26.3415H56.1951V24.5854Z" />
  <path d="M68.4878 24.5854H66.7317V26.3415H68.4878V24.5854Z" />
  <path d="M70.2439 24.5854H68.4878V26.3415H70.2439V24.5854Z" />
  <path d="M14.0488 26.3415H12.2927V28.0976H14.0488V26.3415Z" />
  <path d="M17.561 26.3415H15.8049V28.0976H17.561V26.3415Z" />
  <path d="M24.5854 26.3415H22.8293V28.0976H24.5854V26.3415Z" />
  <path d="M29.8537 26.3415H28.0976V28.0976H29.8537V26.3415Z" />
  <path d="M33.3659 26.3415H31.6098V28.0976H33.3659V26.3415Z" />
  <path d="M35.122 26.3415H33.3659V28.0976H35.122V26.3415Z" />
  <path d="M36.8781 26.3415H35.122V28.0976H36.8781V26.3415Z" />
  <path d="M38.6341 26.3415H36.8781V28.0976H38.6341V26.3415Z" />
  <path d="M40.3902 26.3415H38.6341V28.0976H40.3902V26.3415Z" />
  <path d="M45.6585 26.3415H43.9024V28.0976H45.6585V26.3415Z" />
  <path d="M49.1707 26.3415H47.4146V28.0976H49.1707V26.3415Z" />
  <path d="M54.439 26.3415H52.6829V28.0976H54.439V26.3415Z" />
  <path d="M57.9512 26.3415H56.1951V28.0976H57.9512V26.3415Z" />
  <path d="M61.4634 26.3415H59.7073V28.0976H61.4634V26.3415Z" />
  <path d="M63.2195 26.3415H61.4634V28.0976H63.2195V26.3415Z" />
  <path d="M64.9756 26.3415H63.2195V28.0976H64.9756V26.3415Z" />
  <path d="M68.4878 26.3415H66.7317V28.0976H68.4878V26.3415Z" />
  <path d="M70.2439 26.3415H68.4878V28.0976H70.2439V26.3415Z" />
  <path d="M3.5122 28.0976H1.7561V29.8537H3.5122V28.0976Z" />
  <path d="M7.02439 28.0976H5.26829V29.8537H7.02439V28.0976Z" />
  <path d="M8.78049 28.0976H7.02439V29.8537H8.78049V28.0976Z" />
  <path d="M12.2927 28.0976H10.5366V29.8537H12.2927V28.0976Z" />
  <path d="M14.0488 28.0976H12.2927V29.8537H14.0488V28.0976Z" />
  <path d="M21.0732 28.0976H19.3171V29.8537H21.0732V28.0976Z" />
  <path d="M24.5854 28.0976H22.8293V29.8537H24.5854V28.0976Z" />
  <path d="M26.3415 28.0976H24.5854V29.8537H26.3415V28.0976Z" />
  <path d="M28.0976 28.0976H26.3415V29.8537H28.0976V28.0976Z" />
  <path d="M29.8537 28.0976H28.0976V29.8537H29.8537V28.0976Z" />
  <path d="M31.6098 28.0976H29.8537V29.8537H31.6098V28.0976Z" />
  <path d="M38.6341 28.0976H36.8781V29.8537H38.6341V28.0976Z" />
  <path d="M49.1707 28.0976H47.4146V29.8537H49.1707V28.0976Z" />
  <path d="M50.9268 28.0976H49.1707V29.8537H50.9268V28.0976Z" />
  <path d="M54.439 28.0976H52.6829V29.8537H54.439V28.0976Z" />
  <path d="M56.1951 28.0976H54.439V29.8537H56.1951V28.0976Z" />
  <path d="M57.9512 28.0976H56.1951V29.8537H57.9512V28.0976Z" />
  <path d="M61.4634 28.0976H59.7073V29.8537H61.4634V28.0976Z" />
  <path d="M66.7317 28.0976H64.9756V29.8537H66.7317V28.0976Z" />
  <path d="M15.8049 29.8537H14.0488V31.6098H15.8049V29.8537Z" />
  <path d="M19.3171 29.8537H17.561V31.6098H19.3171V29.8537Z" />
  <path d="M22.8293 29.8537H21.0732V31.6098H22.8293V29.8537Z" />
  <path d="M29.8537 29.8537H28.0976V31.6098H29.8537V29.8537Z" />
  <path d="M31.6098 29.8537H29.8537V31.6098H31.6098V29.8537Z" />
  <path d="M33.3659 29.8537H31.6098V31.6098H33.3659V29.8537Z" />
  <path d="M35.122 29.8537H33.3659V31.6098H35.122V29.8537Z" />
  <path d="M40.3902 29.8537H38.6341V31.6098H40.3902V29.8537Z" />
  <path d="M43.9024 29.8537H42.1463V31.6098H43.9024V29.8537Z" />
  <path d="M50.9268 29.8537H49.1707V31.6098H50.9268V29.8537Z" />
  <path d="M57.9512 29.8537H56.1951V31.6098H57.9512V29.8537Z" />
  <path d="M70.2439 29.8537H68.4878V31.6098H70.2439V29.8537Z" />
  <path d="M1.7561 31.6098H0V33.3659H1.7561V31.6098Z" />
  <path d="M3.5122 31.6098H1.7561V33.3659H3.5122V31.6098Z" />
  <path d="M7.02439 31.6098H5.26829V33.3659H7.02439V31.6098Z" />
  <path d="M12.2927 31.6098H10.5366V33.3659H12.2927V31.6098Z" />
  <path d="M17.561 31.6098H15.8049V33.3659H17.561V31.6098Z" />
  <path d="M21.0732 31.6098H19.3171V33.3659H21.0732V31.6098Z" />
  <path d="M22.8293 31.6098H21.0732V33.3659H22.8293V31.6098Z" />
  <path d="M28.0976 31.6098H26.3415V33.3659H28.0976V31.6098Z" />
  <path d="M29.8537 31.6098H28.0976V33.3659H29.8537V31.6098Z" />
  <path d="M35.122 31.6098H33.3659V33.3659H35.122V31.6098Z" />
  <path d="M38.6341 31.6098H36.8781V33.3659H38.6341V31.6098Z" />
  <path d="M40.3902 31.6098H38.6341V33.3659H40.3902V31.6098Z" />
  <path d="M42.1463 31.6098H40.3902V33.3659H42.1463V31.6098Z" />
  <path d="M45.6585 31.6098H43.9024V33.3659H45.6585V31.6098Z" />
  <path d="M47.4146 31.6098H45.6585V33.3659H47.4146V31.6098Z" />
  <path d="M50.9268 31.6098H49.1707V33.3659H50.9268V31.6098Z" />
  <path d="M57.9512 31.6098H56.1951V33.3659H57.9512V31.6098Z" />
  <path d="M61.4634 31.6098H59.7073V33.3659H61.4634V31.6098Z" />
  <path d="M64.9756 31.6098H63.2195V33.3659H64.9756V31.6098Z" />
  <path d="M72 31.6098H70.2439V33.3659H72V31.6098Z" />
  <path d="M1.7561 33.3659H0V35.122H1.7561V33.3659Z" />
  <path d="M8.78049 33.3659H7.02439L7.02439 35.122H8.78049V33.3659Z" />
  <path d="M17.561 33.3659H15.8049V35.122H17.561V33.3659Z" />
  <path d="M26.3415 33.3659H24.5854V35.122H26.3415V33.3659Z" />
  <path d="M28.0976 33.3659H26.3415V35.122H28.0976V33.3659Z" />
  <path d="M29.8537 33.3659H28.0976V35.122H29.8537V33.3659Z" />
  <path d="M31.6098 33.3659H29.8537V35.122H31.6098V33.3659Z" />
  <path d="M38.6341 33.3659H36.8781V35.122H38.6341V33.3659Z" />
  <path d="M42.1463 33.3659H40.3902V35.122H42.1463V33.3659Z" />
  <path d="M45.6585 33.3659H43.9024V35.122H45.6585V33.3659Z" />
  <path d="M49.1707 33.3659H47.4146V35.122H49.1707V33.3659Z" />
  <path d="M50.9268 33.3659H49.1707V35.122H50.9268V33.3659Z" />
  <path d="M59.7073 33.3659H57.9512V35.122H59.7073V33.3659Z" />
  <path d="M66.7317 33.3659H64.9756V35.122H66.7317V33.3659Z" />
  <path d="M1.7561 35.122H0V36.8781H1.7561V35.122Z" />
  <path d="M3.5122 35.122H1.7561V36.8781H3.5122V35.122Z" />
  <path d="M7.02439 35.122H5.26829V36.8781H7.02439L7.02439 35.122Z" />
  <path d="M10.5366 35.122H8.78049V36.8781H10.5366V35.122Z" />
  <path d="M12.2927 35.122H10.5366V36.8781H12.2927V35.122Z" />
  <path d="M14.0488 35.122H12.2927V36.8781H14.0488V35.122Z" />
  <path d="M17.561 35.122H15.8049V36.8781H17.561V35.122Z" />
  <path d="M21.0732 35.122H19.3171V36.8781H21.0732V35.122Z" />
  <path d="M24.5854 35.122H22.8293V36.8781H24.5854V35.122Z" />
  <path d="M28.0976 35.122H26.3415V36.8781H28.0976V35.122Z" />
  <path d="M31.6098 35.122H29.8537V36.8781H31.6098V35.122Z" />
  <path d="M33.3659 35.122H31.6098V36.8781H33.3659V35.122Z" />
  <path d="M35.122 35.122H33.3659V36.8781H35.122V35.122Z" />
  <path d="M36.8781 35.122H35.122V36.8781H36.8781V35.122Z" />
  <path d="M40.3902 35.122H38.6341V36.8781H40.3902V35.122Z" />
  <path d="M45.6585 35.122H43.9024V36.8781H45.6585V35.122Z" />
  <path d="M47.4146 35.122H45.6585V36.8781H47.4146V35.122Z" />
  <path d="M49.1707 35.122H47.4146V36.8781H49.1707V35.122Z" />
  <path d="M50.9268 35.122H49.1707V36.8781H50.9268V35.122Z" />
  <path d="M54.439 35.122H52.6829V36.8781H54.439V35.122Z" />
  <path d="M59.7073 35.122H57.9512V36.8781H59.7073V35.122Z" />
  <path d="M61.4634 35.122H59.7073V36.8781H61.4634V35.122Z" />
  <path d="M63.2195 35.122H61.4634V36.8781H63.2195V35.122Z" />
  <path d="M64.9756 35.122H63.2195V36.8781H64.9756V35.122Z" />
  <path d="M68.4878 35.122H66.7317V36.8781H68.4878V35.122Z" />
  <path d="M70.2439 35.122H68.4878V36.8781H70.2439V35.122Z" />
  <path d="M7.02439 36.8781H5.26829V38.6341H7.02439V36.8781Z" />
  <path d="M8.78049 36.8781H7.02439V38.6341H8.78049V36.8781Z" />
  <path d="M10.5366 36.8781H8.78049V38.6341H10.5366V36.8781Z" />
  <path d="M14.0488 36.8781H12.2927V38.6341H14.0488V36.8781Z" />
  <path d="M19.3171 36.8781H17.561V38.6341H19.3171V36.8781Z" />
  <path d="M21.0732 36.8781H19.3171V38.6341H21.0732V36.8781Z" />
  <path d="M24.5854 36.8781H22.8293V38.6341H24.5854V36.8781Z" />
  <path d="M26.3415 36.8781H24.5854V38.6341H26.3415V36.8781Z" />
  <path d="M28.0976 36.8781H26.3415V38.6341H28.0976V36.8781Z" />
  <path d="M31.6098 36.8781H29.8537V38.6341H31.6098V36.8781Z" />
  <path d="M33.3659 36.8781H31.6098V38.6341H33.3659V36.8781Z" />
  <path d="M35.122 36.8781H33.3659V38.6341H35.122V36.8781Z" />
  <path d="M36.8781 36.8781H35.122V38.6341H36.8781V36.8781Z" />
  <path d="M38.6341 36.8781H36.8781V38.6341H38.6341V36.8781Z" />
  <path d="M42.1463 36.8781H40.3902V38.6341H42.1463V36.8781Z" />
  <path d="M45.6585 36.8781H43.9024V38.6341H45.6585V36.8781Z" />
  <path d="M47.4146 36.8781H45.6585V38.6341H47.4146V36.8781Z" />
  <path d="M49.1707 36.8781H47.4146V38.6341H49.1707V36.8781Z" />
  <path d="M54.439 36.8781H52.6829V38.6341H54.439V36.8781Z" />
  <path d="M56.1951 36.8781H54.439V38.6341H56.1951V36.8781Z" />
  <path d="M64.9756 36.8781H63.2195V38.6341H64.9756V36.8781Z" />
  <path d="M66.7317 36.8781H64.9756V38.6341H66.7317V36.8781Z" />
  <path d="M70.2439 36.8781H68.4878V38.6341H70.2439V36.8781Z" />
  <path d="M3.5122 38.6341H1.7561V40.3902H3.5122V38.6341Z" />
  <path d="M7.02439 38.6341H5.26829V40.3902H7.02439V38.6341Z" />
  <path d="M10.5366 38.6341H8.78049V40.3902H10.5366V38.6341Z" />
  <path d="M12.2927 38.6341H10.5366V40.3902H12.2927V38.6341Z" />
  <path d="M14.0488 38.6341H12.2927V40.3902H14.0488V38.6341Z" />
  <path d="M15.8049 38.6341H14.0488V40.3902H15.8049V38.6341Z" />
  <path d="M17.561 38.6341H15.8049V40.3902H17.561V38.6341Z" />
  <path d="M21.0732 38.6341H19.3171V40.3902H21.0732V38.6341Z" />
  <path d="M24.5854 38.6341H22.8293V40.3902H24.5854V38.6341Z" />
  <path d="M26.3415 38.6341H24.5854V40.3902H26.3415V38.6341Z" />
  <path d="M28.0976 38.6341H26.3415V40.3902H28.0976V38.6341Z" />
  <path d="M31.6098 38.6341H29.8537V40.3902H31.6098V38.6341Z" />
  <path d="M35.122 38.6341H33.3659V40.3902H35.122V38.6341Z" />
  <path d="M38.6341 38.6341H36.8781V40.3902H38.6341V38.6341Z" />
  <path d="M43.9024 38.6341H42.1463V40.3902H43.9024V38.6341Z" />
  <path d="M47.4146 38.6341H45.6585V40.3902H47.4146V38.6341Z" />
  <path d="M49.1707 38.6341H47.4146V40.3902H49.1707V38.6341Z" />
  <path d="M50.9268 38.6341H49.1707V40.3902H50.9268V38.6341Z" />
  <path d="M57.9512 38.6341H56.1951V40.3902H57.9512V38.6341Z" />
  <path d="M61.4634 38.6341H59.7073V40.3902H61.4634V38.6341Z" />
  <path d="M64.9756 38.6341H63.2195V40.3902H64.9756V38.6341Z" />
  <path d="M68.4878 38.6341H66.7317V40.3902H68.4878V38.6341Z" />
  <path d="M1.7561 40.3902H0V42.1463H1.7561V40.3902Z" />
  <path d="M5.26829 40.3902H3.5122V42.1463H5.26829V40.3902Z" />
  <path d="M8.78049 40.3902H7.02439L7.02439 42.1463H8.78049V40.3902Z" />
  <path d="M15.8049 40.3902H14.0488V42.1463H15.8049V40.3902Z" />
  <path d="M17.561 40.3902H15.8049V42.1463H17.561V40.3902Z" />
  <path d="M21.0732 40.3902H19.3171V42.1463H21.0732V40.3902Z" />
  <path d="M31.6098 40.3902H29.8537V42.1463H31.6098V40.3902Z" />
  <path d="M38.6341 40.3902H36.8781V42.1463H38.6341V40.3902Z" />
  <path d="M45.6585 40.3902H43.9024V42.1463H45.6585V40.3902Z" />
  <path d="M49.1707 40.3902H47.4146V42.1463H49.1707V40.3902Z" />
  <path d="M50.9268 40.3902H49.1707V42.1463H50.9268V40.3902Z" />
  <path d="M54.439 40.3902H52.6829V42.1463H54.439V40.3902Z" />
  <path d="M56.1951 40.3902H54.439V42.1463H56.1951V40.3902Z" />
  <path d="M57.9512 40.3902H56.1951V42.1463H57.9512V40.3902Z" />
  <path d="M59.7073 40.3902H57.9512V42.1463H59.7073V40.3902Z" />
  <path d="M63.2195 40.3902H61.4634V42.1463H63.2195V40.3902Z" />
  <path d="M66.7317 40.3902H64.9756V42.1463H66.7317V40.3902Z" />
  <path d="M70.2439 40.3902H68.4878V42.1463H70.2439V40.3902Z" />
  <path d="M3.5122 42.1463H1.7561V43.9024H3.5122V42.1463Z" />
  <path d="M8.78049 42.1463H7.02439V43.9024H8.78049V42.1463Z" />
  <path d="M10.5366 42.1463H8.78049V43.9024H10.5366V42.1463Z" />
  <path d="M12.2927 42.1463H10.5366V43.9024H12.2927V42.1463Z" />
  <path d="M14.0488 42.1463H12.2927V43.9024H14.0488V42.1463Z" />
  <path d="M21.0732 42.1463H19.3171V43.9024H21.0732V42.1463Z" />
  <path d="M24.5854 42.1463H22.8293V43.9024H24.5854V42.1463Z" />
  <path d="M28.0976 42.1463H26.3415V43.9024H28.0976V42.1463Z" />
  <path d="M29.8537 42.1463H28.0976V43.9024H29.8537V42.1463Z" />
  <path d="M38.6341 42.1463H36.8781V43.9024H38.6341V42.1463Z" />
  <path d="M42.1463 42.1463H40.3902V43.9024H42.1463V42.1463Z" />
  <path d="M47.4146 42.1463H45.6585V43.9024H47.4146V42.1463Z" />
  <path d="M50.9268 42.1463H49.1707V43.9024H50.9268V42.1463Z" />
  <path d="M54.439 42.1463H52.6829V43.9024H54.439V42.1463Z" />
  <path d="M57.9512 42.1463H56.1951V43.9024H57.9512V42.1463Z" />
  <path d="M63.2195 42.1463H61.4634V43.9024H63.2195V42.1463Z" />
  <path d="M64.9756 42.1463H63.2195V43.9024H64.9756V42.1463Z" />
  <path d="M68.4878 42.1463H66.7317V43.9024H68.4878V42.1463Z" />
  <path d="M70.2439 42.1463H68.4878V43.9024H70.2439V42.1463Z" />
  <path d="M72 42.1463H70.2439V43.9024H72V42.1463Z" />
  <path d="M3.5122 43.9024H1.7561V45.6585H3.5122V43.9024Z" />
  <path d="M10.5366 43.9024H8.78049V45.6585H10.5366V43.9024Z" />
  <path d="M15.8049 43.9024H14.0488V45.6585H15.8049V43.9024Z" />
  <path d="M22.8293 43.9024H21.0732V45.6585H22.8293V43.9024Z" />
  <path d="M29.8537 43.9024H28.0976V45.6585H29.8537V43.9024Z" />
  <path d="M31.6098 43.9024H29.8537V45.6585H31.6098V43.9024Z" />
  <path d="M33.3659 43.9024H31.6098V45.6585H33.3659V43.9024Z" />
  <path d="M35.122 43.9024H33.3659V45.6585H35.122V43.9024Z" />
  <path d="M43.9024 43.9024H42.1463V45.6585H43.9024V43.9024Z" />
  <path d="M47.4146 43.9024H45.6585V45.6585H47.4146V43.9024Z" />
  <path d="M50.9268 43.9024H49.1707V45.6585H50.9268V43.9024Z" />
  <path d="M54.439 43.9024H52.6829V45.6585H54.439V43.9024Z" />
  <path d="M57.9512 43.9024H56.1951V45.6585H57.9512V43.9024Z" />
  <path d="M68.4878 43.9024H66.7317V45.6585H68.4878V43.9024Z" />
  <path d="M72 43.9024H70.2439V45.6585H72V43.9024Z" />
  <path d="M10.5366 45.6585H8.78049V47.4146H10.5366V45.6585Z" />
  <path d="M12.2927 45.6585H10.5366V47.4146H12.2927V45.6585Z" />
  <path d="M15.8049 45.6585H14.0488V47.4146H15.8049V45.6585Z" />
  <path d="M19.3171 45.6585H17.561V47.4146H19.3171V45.6585Z" />
  <path d="M21.0732 45.6585H19.3171V47.4146H21.0732V45.6585Z" />
  <path d="M22.8293 45.6585H21.0732V47.4146H22.8293V45.6585Z" />
  <path d="M26.3415 45.6585H24.5854V47.4146H26.3415V45.6585Z" />
  <path d="M29.8537 45.6585H28.0976V47.4146H29.8537V45.6585Z" />
  <path d="M33.3659 45.6585H31.6098V47.4146H33.3659V45.6585Z" />
  <path d="M36.8781 45.6585H35.122V47.4146H36.8781V45.6585Z" />
  <path d="M40.3902 45.6585H38.6341V47.4146H40.3902V45.6585Z" />
  <path d="M42.1463 45.6585H40.3902V47.4146H42.1463V45.6585Z" />
  <path d="M43.9024 45.6585H42.1463V47.4146H43.9024V45.6585Z" />
  <path d="M45.6585 45.6585H43.9024V47.4146H45.6585V45.6585Z" />
  <path d="M50.9268 45.6585H49.1707V47.4146H50.9268V45.6585Z" />
  <path d="M57.9512 45.6585H56.1951V47.4146H57.9512V45.6585Z" />
  <path d="M63.2195 45.6585H61.4634V47.4146H63.2195V45.6585Z" />
  <path d="M66.7317 45.6585H64.9756V47.4146H66.7317V45.6585Z" />
  <path d="M3.5122 47.4146H1.7561V49.1707H3.5122V47.4146Z" />
  <path d="M5.26829 47.4146H3.5122V49.1707H5.26829V47.4146Z" />
  <path d="M7.02439 47.4146H5.26829V49.1707H7.02439V47.4146Z" />
  <path d="M10.5366 47.4146H8.78049V49.1707H10.5366V47.4146Z" />
  <path d="M14.0488 47.4146H12.2927V49.1707H14.0488V47.4146Z" />
  <path d="M19.3171 47.4146H17.561V49.1707H19.3171V47.4146Z" />
  <path d="M21.0732 47.4146H19.3171V49.1707H21.0732V47.4146Z" />
  <path d="M22.8293 47.4146H21.0732V49.1707H22.8293V47.4146Z" />
  <path d="M26.3415 47.4146H24.5854V49.1707H26.3415V47.4146Z" />
  <path d="M29.8537 47.4146H28.0976V49.1707H29.8537V47.4146Z" />
  <path d="M33.3659 47.4146H31.6098V49.1707H33.3659V47.4146Z" />
  <path d="M35.122 47.4146H33.3659V49.1707H35.122V47.4146Z" />
  <path d="M40.3902 47.4146H38.6341V49.1707H40.3902V47.4146Z" />
  <path d="M42.1463 47.4146H40.3902V49.1707H42.1463V47.4146Z" />
  <path d="M43.9024 47.4146H42.1463V49.1707H43.9024V47.4146Z" />
  <path d="M47.4146 47.4146H45.6585V49.1707H47.4146V47.4146Z" />
  <path d="M54.439 47.4146H52.6829V49.1707H54.439V47.4146Z" />
  <path d="M56.1951 47.4146H54.439V49.1707H56.1951V47.4146Z" />
  <path d="M57.9512 47.4146H56.1951V49.1707H57.9512V47.4146Z" />
  <path d="M61.4634 47.4146H59.7073V49.1707H61.4634V47.4146Z" />
  <path d="M63.2195 47.4146H61.4634V49.1707H63.2195V47.4146Z" />
  <path d="M64.9756 47.4146H63.2195V49.1707H64.9756V47.4146Z" />
  <path d="M68.4878 47.4146H66.7317V49.1707H68.4878V47.4146Z" />
  <path d="M70.2439 47.4146H68.4878V49.1707H70.2439V47.4146Z" />
  <path d="M72 47.4146H70.2439V49.1707H72V47.4146Z" />
  <path d="M1.7561 49.1707H0V50.9268H1.7561V49.1707Z" />
  <path d="M3.5122 49.1707H1.7561V50.9268H3.5122V49.1707Z" />
  <path d="M5.26829 49.1707H3.5122V50.9268H5.26829V49.1707Z" />
  <path d="M7.02439 49.1707H5.26829V50.9268H7.02439V49.1707Z" />
  <path d="M12.2927 49.1707H10.5366V50.9268H12.2927V49.1707Z" />
  <path d="M15.8049 49.1707H14.0488V50.9268H15.8049V49.1707Z" />
  <path d="M17.561 49.1707H15.8049V50.9268H17.561V49.1707Z" />
  <path d="M19.3171 49.1707H17.561V50.9268H19.3171V49.1707Z" />
  <path d="M21.0732 49.1707H19.3171V50.9268H21.0732V49.1707Z" />
  <path d="M24.5854 49.1707H22.8293V50.9268H24.5854V49.1707Z" />
  <path d="M26.3415 49.1707H24.5854V50.9268H26.3415V49.1707Z" />
  <path d="M31.6098 49.1707H29.8537V50.9268H31.6098V49.1707Z" />
  <path d="M33.3659 49.1707H31.6098V50.9268H33.3659V49.1707Z" />
  <path d="M36.8781 49.1707H35.122V50.9268H36.8781V49.1707Z" />
  <path d="M40.3902 49.1707H38.6341V50.9268H40.3902V49.1707Z" />
  <path d="M45.6585 49.1707H43.9024V50.9268H45.6585V49.1707Z" />
  <path d="M49.1707 49.1707H47.4146V50.9268H49.1707V49.1707Z" />
  <path d="M52.6829 49.1707H50.9268V50.9268H52.6829V49.1707Z" />
  <path d="M54.439 49.1707H52.6829V50.9268H54.439V49.1707Z" />
  <path d="M57.9512 49.1707H56.1951V50.9268H57.9512V49.1707Z" />
  <path d="M63.2195 49.1707H61.4634V50.9268H63.2195V49.1707Z" />
  <path d="M66.7317 49.1707H64.9756V50.9268H66.7317V49.1707Z" />
  <path d="M68.4878 49.1707H66.7317V50.9268H68.4878V49.1707Z" />
  <path d="M1.7561 50.9268H0V52.6829H1.7561V50.9268Z" />
  <path d="M7.02439 50.9268H5.26829V52.6829H7.02439V50.9268Z" />
  <path d="M15.8049 50.9268H14.0488V52.6829H15.8049V50.9268Z" />
  <path d="M19.3171 50.9268H17.561V52.6829H19.3171V50.9268Z" />
  <path d="M22.8293 50.9268H21.0732V52.6829H22.8293V50.9268Z" />
  <path d="M26.3415 50.9268H24.5854V52.6829H26.3415V50.9268Z" />
  <path d="M29.8537 50.9268H28.0976V52.6829H29.8537V50.9268Z" />
  <path d="M36.8781 50.9268H35.122V52.6829H36.8781V50.9268Z" />
  <path d="M40.3902 50.9268H38.6341V52.6829H40.3902V50.9268Z" />
  <path d="M43.9024 50.9268H42.1463V52.6829H43.9024V50.9268Z" />
  <path d="M47.4146 50.9268H45.6585V52.6829H47.4146V50.9268Z" />
  <path d="M50.9268 50.9268H49.1707V52.6829H50.9268V50.9268Z" />
  <path d="M54.439 50.9268H52.6829V52.6829H54.439V50.9268Z" />
  <path d="M57.9512 50.9268H56.1951V52.6829H57.9512V50.9268Z" />
  <path d="M61.4634 50.9268H59.7073V52.6829H61.4634V50.9268Z" />
  <path d="M64.9756 50.9268H63.2195V52.6829H64.9756V50.9268Z" />
  <path d="M66.7317 50.9268H64.9756V52.6829H66.7317V50.9268Z" />
  <path d="M72 50.9268H70.2439V52.6829H72V50.9268Z" />
  <path d="M1.7561 52.6829H0V54.439H1.7561V52.6829Z" />
  <path d="M3.5122 52.6829H1.7561V54.439H3.5122V52.6829Z" />
  <path d="M5.26829 52.6829H3.5122V54.439H5.26829V52.6829Z" />
  <path d="M7.02439 52.6829H5.26829V54.439H7.02439V52.6829Z" />
  <path d="M12.2927 52.6829H10.5366V54.439H12.2927V52.6829Z" />
  <path d="M19.3171 52.6829H17.561V54.439H19.3171V52.6829Z" />
  <path d="M22.8293 52.6829H21.0732V54.439H22.8293V52.6829Z" />
  <path d="M26.3415 52.6829H24.5854V54.439H26.3415V52.6829Z" />
  <path d="M29.8537 52.6829H28.0976V54.439H29.8537V52.6829Z" />
  <path d="M36.8781 52.6829H35.122V54.439H36.8781V52.6829Z" />
  <path d="M40.3902 52.6829H38.6341V54.439H40.3902V52.6829Z" />
  <path d="M42.1463 52.6829H40.3902V54.439H42.1463V52.6829Z" />
  <path d="M45.6585 52.6829H43.9024V54.439H45.6585V52.6829Z" />
  <path d="M49.1707 52.6829H47.4146V54.439H49.1707V52.6829Z" />
  <path d="M52.6829 52.6829H50.9268V54.439H52.6829V52.6829Z" />
  <path d="M56.1951 52.6829H54.439V54.439H56.1951V52.6829Z" />
  <path d="M57.9512 52.6829H56.1951V54.439H57.9512V52.6829Z" />
  <path d="M61.4634 52.6829H59.7073V54.439H61.4634V52.6829Z" />
  <path d="M63.2195 52.6829H61.4634V54.439H63.2195V52.6829Z" />
  <path d="M64.9756 52.6829H63.2195V54.439H64.9756V52.6829Z" />
  <path d="M68.4878 52.6829H66.7317V54.439H68.4878V52.6829Z" />
  <path d="M72 52.6829H70.2439V54.439H72V52.6829Z" />
  <path d="M1.7561 54.439H0V56.1951H1.7561V54.439Z" />
  <path d="M5.26829 54.439H3.5122V56.1951H5.26829V54.439Z" />
  <path d="M8.78049 54.439H7.02439L7.02439 56.1951H8.78049V54.439Z" />
  <path d="M14.0488 54.439H12.2927V56.1951H14.0488V54.439Z" />
  <path d="M17.561 54.439H15.8049V56.1951H17.561V54.439Z" />
  <path d="M21.0732 54.439H19.3171V56.1951H21.0732V54.439Z" />
  <path d="M22.8293 54.439H21.0732V56.1951H22.8293V54.439Z" />
  <path d="M24.5854 54.439H22.8293V56.1951H24.5854V54.439Z" />
  <path d="M28.0976 54.439H26.3415V56.1951H28.0976V54.439Z" />
  <path d="M31.6098 54.439H29.8537V56.1951H31.6098V54.439Z" />
  <path d="M36.8781 54.439H35.122V56.1951H36.8781V54.439Z" />
  <path d="M38.6341 54.439H36.8781V56.1951H38.6341V54.439Z" />
  <path d="M42.1463 54.439H40.3902V56.1951H42.1463V54.439Z" />
  <path d="M45.6585 54.439H43.9024V56.1951H45.6585V54.439Z" />
  <path d="M49.1707 54.439H47.4146V56.1951H49.1707V54.439Z" />
  <path d="M50.9268 54.439H49.1707V56.1951H50.9268V54.439Z" />
  <path d="M52.6829 54.439H50.9268V56.1951H52.6829V54.439Z" />
  <path d="M56.1951 54.439H54.439V56.1951H56.1951V54.439Z" />
  <path d="M59.7073 54.439H57.9512V56.1951H59.7073V54.439Z" />
  <path d="M61.4634 54.439H59.7073V56.1951H61.4634V54.439Z" />
  <path d="M66.7317 54.439H64.9756V56.1951H66.7317V54.439Z" />
  <path d="M72 54.439H70.2439V56.1951H72V54.439Z" />
  <path d="M1.7561 56.1951H0V57.9512H1.7561V56.1951Z" />
  <path d="M8.78049 56.1951H7.02439V57.9512H8.78049V56.1951Z" />
  <path d="M12.2927 56.1951H10.5366V57.9512H12.2927V56.1951Z" />
  <path d="M15.8049 56.1951H14.0488V57.9512H15.8049V56.1951Z" />
  <path d="M17.561 56.1951H15.8049V57.9512H17.561V56.1951Z" />
  <path d="M19.3171 56.1951H17.561V57.9512H19.3171V56.1951Z" />
  <path d="M21.0732 56.1951H19.3171V57.9512H21.0732V56.1951Z" />
  <path d="M22.8293 56.1951H21.0732V57.9512H22.8293V56.1951Z" />
  <path d="M29.8537 56.1951H28.0976V57.9512H29.8537V56.1951Z" />
  <path d="M33.3659 56.1951H31.6098V57.9512H33.3659V56.1951Z" />
  <path d="M36.8781 56.1951H35.122V57.9512H36.8781V56.1951Z" />
  <path d="M47.4146 56.1951H45.6585V57.9512H47.4146V56.1951Z" />
  <path d="M49.1707 56.1951H47.4146V57.9512H49.1707V56.1951Z" />
  <path d="M52.6829 56.1951H50.9268V57.9512H52.6829V56.1951Z" />
  <path d="M56.1951 56.1951H54.439V57.9512H56.1951V56.1951Z" />
  <path d="M57.9512 56.1951H56.1951V57.9512H57.9512V56.1951Z" />
  <path d="M59.7073 56.1951H57.9512V57.9512H59.7073V56.1951Z" />
  <path d="M61.4634 56.1951H59.7073V57.9512H61.4634V56.1951Z" />
  <path d="M63.2195 56.1951H61.4634V57.9512H63.2195V56.1951Z" />
  <path d="M64.9756 56.1951H63.2195V57.9512H64.9756V56.1951Z" />
  <path d="M70.2439 56.1951H68.4878V57.9512H70.2439V56.1951Z" />
  <path d="M17.561 57.9512H15.8049V59.7073H17.561V57.9512Z" />
  <path d="M19.3171 57.9512H17.561V59.7073H19.3171V57.9512Z" />
  <path d="M22.8293 57.9512H21.0732V59.7073H22.8293V57.9512Z" />
  <path d="M26.3415 57.9512H24.5854V59.7073H26.3415V57.9512Z" />
  <path d="M29.8537 57.9512H28.0976V59.7073H29.8537V57.9512Z" />
  <path d="M33.3659 57.9512H31.6098V59.7073H33.3659V57.9512Z" />
  <path d="M35.122 57.9512H33.3659V59.7073H35.122V57.9512Z" />
  <path d="M36.8781 57.9512H35.122V59.7073H36.8781V57.9512Z" />
  <path d="M43.9024 57.9512H42.1463V59.7073H43.9024V57.9512Z" />
  <path d="M47.4146 57.9512H45.6585V59.7073H47.4146V57.9512Z" />
  <path d="M52.6829 57.9512H50.9268V59.7073H52.6829V57.9512Z" />
  <path d="M54.439 57.9512H52.6829V59.7073H54.439V57.9512Z" />
  <path d="M56.1951 57.9512H54.439V59.7073H56.1951V57.9512Z" />
  <path d="M57.9512 57.9512H56.1951V59.7073H57.9512V57.9512Z" />
  <path d="M64.9756 57.9512H63.2195V59.7073H64.9756V57.9512Z" />
  <path d="M66.7317 57.9512H64.9756V59.7073H66.7317V57.9512Z" />
  <path d="M68.4878 57.9512H66.7317V59.7073H68.4878V57.9512Z" />
  <path d="M72 57.9512H70.2439V59.7073H72V57.9512Z" />
  <path d="M1.7561 59.7073H0V61.4634H1.7561V59.7073Z" />
  <path d="M3.5122 59.7073H1.7561V61.4634H3.5122V59.7073Z" />
  <path d="M5.26829 59.7073H3.5122V61.4634H5.26829V59.7073Z" />
  <path d="M7.02439 59.7073H5.26829V61.4634H7.02439V59.7073Z" />
  <path d="M8.78049 59.7073H7.02439V61.4634H8.78049V59.7073Z" />
  <path d="M10.5366 59.7073H8.78049V61.4634H10.5366V59.7073Z" />
  <path d="M12.2927 59.7073H10.5366V61.4634H12.2927V59.7073Z" />
  <path d="M15.8049 59.7073H14.0488V61.4634H15.8049V59.7073Z" />
  <path d="M17.561 59.7073H15.8049V61.4634H17.561V59.7073Z" />
  <path d="M21.0732 59.7073H19.3171V61.4634H21.0732V59.7073Z" />
  <path d="M24.5854 59.7073H22.8293V61.4634H24.5854V59.7073Z" />
  <path d="M28.0976 59.7073H26.3415V61.4634H28.0976V59.7073Z" />
  <path d="M31.6098 59.7073H29.8537V61.4634H31.6098V59.7073Z" />
  <path d="M38.6341 59.7073H36.8781V61.4634H38.6341V59.7073Z" />
  <path d="M45.6585 59.7073H43.9024V61.4634H45.6585V59.7073Z" />
  <path d="M47.4146 59.7073H45.6585V61.4634H47.4146V59.7073Z" />
  <path d="M52.6829 59.7073H50.9268V61.4634H52.6829V59.7073Z" />
  <path d="M54.439 59.7073H52.6829V61.4634H54.439V59.7073Z" />
  <path d="M56.1951 59.7073H54.439V61.4634H56.1951V59.7073Z" />
  <path d="M57.9512 59.7073H56.1951V61.4634H57.9512V59.7073Z" />
  <path d="M61.4634 59.7073H59.7073V61.4634H61.4634V59.7073Z" />
  <path d="M64.9756 59.7073H63.2195V61.4634H64.9756V59.7073Z" />
  <path d="M68.4878 59.7073H66.7317V61.4634H68.4878V59.7073Z" />
  <path d="M72 59.7073H70.2439V61.4634H72V59.7073Z" />
  <path d="M1.7561 61.4634H0V63.2195H1.7561V61.4634Z" />
  <path d="M12.2927 61.4634H10.5366V63.2195H12.2927V61.4634Z" />
  <path d="M15.8049 61.4634H14.0488V63.2195H15.8049V61.4634Z" />
  <path d="M17.561 61.4634H15.8049V63.2195H17.561V61.4634Z" />
  <path d="M22.8293 61.4634H21.0732V63.2195H22.8293V61.4634Z" />
  <path d="M26.3415 61.4634H24.5854V63.2195H26.3415V61.4634Z" />
  <path d="M29.8537 61.4634H28.0976V63.2195H29.8537V61.4634Z" />
  <path d="M31.6098 61.4634H29.8537V63.2195H31.6098V61.4634Z" />
  <path d="M38.6341 61.4634H36.8781V63.2195H38.6341V61.4634Z" />
  <path d="M42.1463 61.4634H40.3902V63.2195H42.1463V61.4634Z" />
  <path d="M43.9024 61.4634H42.1463V63.2195H43.9024V61.4634Z" />
  <path d="M52.6829 61.4634H50.9268V63.2195H52.6829V61.4634Z" />
  <path d="M57.9512 61.4634H56.1951V63.2195H57.9512V61.4634Z" />
  <path d="M64.9756 61.4634H63.2195V63.2195H64.9756V61.4634Z" />
  <path d="M66.7317 61.4634H64.9756V63.2195H66.7317V61.4634Z" />
  <path d="M68.4878 61.4634H66.7317V63.2195H68.4878V61.4634Z" />
  <path d="M72 61.4634H70.2439V63.2195H72V61.4634Z" />
  <path d="M1.7561 63.2195H0V64.9756H1.7561V63.2195Z" />
  <path d="M5.26829 63.2195H3.5122V64.9756H5.26829V63.2195Z" />
  <path d="M7.02439 63.2195H5.26829V64.9756H7.02439V63.2195Z" />
  <path d="M8.78049 63.2195H7.02439V64.9756H8.78049V63.2195Z" />
  <path d="M12.2927 63.2195H10.5366V64.9756H12.2927V63.2195Z" />
  <path d="M15.8049 63.2195H14.0488V64.9756H15.8049V63.2195Z" />
  <path d="M17.561 63.2195H15.8049V64.9756H17.561V63.2195Z" />
  <path d="M19.3171 63.2195H17.561V64.9756H19.3171V63.2195Z" />
  <path d="M21.0732 63.2195H19.3171V64.9756H21.0732V63.2195Z" />
  <path d="M24.5854 63.2195H22.8293V64.9756H24.5854V63.2195Z" />
  <path d="M26.3415 63.2195H24.5854V64.9756H26.3415V63.2195Z" />
  <path d="M28.0976 63.2195H26.3415V64.9756H28.0976V63.2195Z" />
  <path d="M31.6098 63.2195H29.8537V64.9756H31.6098V63.2195Z" />
  <path d="M33.3659 63.2195H31.6098V64.9756H33.3659V63.2195Z" />
  <path d="M35.122 63.2195H33.3659V64.9756H35.122V63.2195Z" />
  <path d="M38.6341 63.2195H36.8781V64.9756H38.6341V63.2195Z" />
  <path d="M40.3902 63.2195H38.6341V64.9756H40.3902V63.2195Z" />
  <path d="M42.1463 63.2195H40.3902V64.9756H42.1463V63.2195Z" />
  <path d="M45.6585 63.2195H43.9024V64.9756H45.6585V63.2195Z" />
  <path d="M47.4146 63.2195H45.6585V64.9756H47.4146V63.2195Z" />
  <path d="M49.1707 63.2195H47.4146V64.9756H49.1707V63.2195Z" />
  <path d="M52.6829 63.2195H50.9268V64.9756H52.6829V63.2195Z" />
  <path d="M57.9512 63.2195H56.1951V64.9756H57.9512V63.2195Z" />
  <path d="M59.7073 63.2195H57.9512V64.9756H59.7073V63.2195Z" />
  <path d="M61.4634 63.2195H59.7073V64.9756H61.4634V63.2195Z" />
  <path d="M63.2195 63.2195H61.4634V64.9756H63.2195V63.2195Z" />
  <path d="M64.9756 63.2195H63.2195V64.9756H64.9756V63.2195Z" />
  <path d="M68.4878 63.2195H66.7317V64.9756H68.4878V63.2195Z" />
  <path d="M70.2439 63.2195H68.4878V64.9756H70.2439V63.2195Z" />
  <path d="M72 63.2195H70.2439V64.9756H72V63.2195Z" />
  <path d="M1.7561 64.9756H0V66.7317H1.7561V64.9756Z" />
  <path d="M5.26829 64.9756H3.5122V66.7317H5.26829V64.9756Z" />
  <path d="M7.02439 64.9756H5.26829V66.7317H7.02439V64.9756Z" />
  <path d="M8.78049 64.9756H7.02439V66.7317H8.78049V64.9756Z" />
  <path d="M12.2927 64.9756H10.5366V66.7317H12.2927V64.9756Z" />
  <path d="M15.8049 64.9756H14.0488V66.7317H15.8049V64.9756Z" />
  <path d="M21.0732 64.9756H19.3171V66.7317H21.0732V64.9756Z" />
  <path d="M22.8293 64.9756H21.0732V66.7317H22.8293V64.9756Z" />
  <path d="M24.5854 64.9756H22.8293V66.7317H24.5854V64.9756Z" />
  <path d="M26.3415 64.9756H24.5854V66.7317H26.3415V64.9756Z" />
  <path d="M29.8537 64.9756H28.0976V66.7317H29.8537V64.9756Z" />
  <path d="M33.3659 64.9756H31.6098V66.7317H33.3659V64.9756Z" />
  <path d="M36.8781 64.9756H35.122V66.7317H36.8781V64.9756Z" />
  <path d="M43.9024 64.9756H42.1463V66.7317H43.9024V64.9756Z" />
  <path d="M45.6585 64.9756H43.9024V66.7317H45.6585V64.9756Z" />
  <path d="M49.1707 64.9756H47.4146V66.7317H49.1707V64.9756Z" />
  <path d="M50.9268 64.9756H49.1707V66.7317H50.9268V64.9756Z" />
  <path d="M57.9512 64.9756H56.1951V66.7317H57.9512V64.9756Z" />
  <path d="M61.4634 64.9756H59.7073V66.7317H61.4634V64.9756Z" />
  <path d="M64.9756 64.9756H63.2195V66.7317H64.9756V64.9756Z" />
  <path d="M72 64.9756H70.2439V66.7317H72V64.9756Z" />
  <path d="M1.7561 66.7317H0V68.4878H1.7561V66.7317Z" />
  <path d="M5.26829 66.7317H3.5122V68.4878H5.26829V66.7317Z" />
  <path d="M7.02439 66.7317H5.26829V68.4878H7.02439V66.7317Z" />
  <path d="M8.78049 66.7317H7.02439V68.4878H8.78049V66.7317Z" />
  <path d="M12.2927 66.7317H10.5366V68.4878H12.2927V66.7317Z" />
  <path d="M15.8049 66.7317H14.0488V68.4878H15.8049V66.7317Z" />
  <path d="M17.561 66.7317H15.8049V68.4878H17.561V66.7317Z" />
  <path d="M22.8293 66.7317H21.0732V68.4878H22.8293V66.7317Z" />
  <path d="M24.5854 66.7317H22.8293V68.4878H24.5854V66.7317Z" />
  <path d="M26.3415 66.7317H24.5854V68.4878H26.3415V66.7317Z" />
  <path d="M29.8537 66.7317H28.0976V68.4878H29.8537V66.7317Z" />
  <path d="M36.8781 66.7317H35.122V68.4878H36.8781V66.7317Z" />
  <path d="M38.6341 66.7317H36.8781V68.4878H38.6341V66.7317Z" />
  <path d="M43.9024 66.7317H42.1463V68.4878H43.9024V66.7317Z" />
  <path d="M50.9268 66.7317H49.1707V68.4878H50.9268V66.7317Z" />
  <path d="M57.9512 66.7317H56.1951V68.4878H57.9512V66.7317Z" />
  <path d="M64.9756 66.7317H63.2195V68.4878H64.9756V66.7317Z" />
  <path d="M72 66.7317H70.2439V68.4878H72V66.7317Z" />
  <path d="M1.7561 68.4878H0V70.2439H1.7561V68.4878Z" />
  <path d="M12.2927 68.4878H10.5366V70.2439H12.2927V68.4878Z" />
  <path d="M19.3171 68.4878H17.561V70.2439H19.3171V68.4878Z" />
  <path d="M22.8293 68.4878H21.0732V70.2439H22.8293V68.4878Z" />
  <path d="M24.5854 68.4878H22.8293V70.2439H24.5854V68.4878Z" />
  <path d="M29.8537 68.4878H28.0976V70.2439H29.8537V68.4878Z" />
  <path d="M31.6098 68.4878H29.8537V70.2439H31.6098V68.4878Z" />
  <path d="M33.3659 68.4878H31.6098V70.2439H33.3659V68.4878Z" />
  <path d="M35.122 68.4878H33.3659V70.2439H35.122V68.4878Z" />
  <path d="M38.6341 68.4878H36.8781V70.2439H38.6341V68.4878Z" />
  <path d="M42.1463 68.4878H40.3902V70.2439H42.1463V68.4878Z" />
  <path d="M47.4146 68.4878H45.6585V70.2439H47.4146V68.4878Z" />
  <path d="M50.9268 68.4878H49.1707V70.2439H50.9268V68.4878Z" />
  <path d="M54.439 68.4878H52.6829V70.2439H54.439V68.4878Z" />
  <path d="M57.9512 68.4878H56.1951V70.2439H57.9512V68.4878Z" />
  <path d="M70.2439 68.4878H68.4878V70.2439H70.2439V68.4878Z" />
  <path d="M1.7561 70.2439H0V72H1.7561V70.2439Z" />
  <path d="M3.5122 70.2439H1.7561V72H3.5122V70.2439Z" />
  <path d="M5.26829 70.2439H3.5122V72H5.26829V70.2439Z" />
  <path d="M7.02439 70.2439H5.26829V72H7.02439V70.2439Z" />
  <path d="M8.78049 70.2439H7.02439V72H8.78049V70.2439Z" />
  <path d="M10.5366 70.2439H8.78049V72H10.5366V70.2439Z" />
  <path d="M12.2927 70.2439H10.5366V72H12.2927V70.2439Z" />
  <path d="M17.561 70.2439H15.8049V72H17.561V70.2439Z" />
  <path d="M24.5854 70.2439H22.8293V72H24.5854V70.2439Z" />
  <path d="M28.0976 70.2439H26.3415V72H28.0976V70.2439Z" />
  <path d="M31.6098 70.2439H29.8537V72H31.6098V70.2439Z" />
  <path d="M38.6341 70.2439H36.8781V72H38.6341V70.2439Z" />
  <path d="M42.1463 70.2439H40.3902V72H42.1463V70.2439Z" />
  <path d="M43.9024 70.2439H42.1463V72H43.9024V70.2439Z" />
  <path d="M45.6585 70.2439H43.9024V72H45.6585V70.2439Z" />
  <path d="M52.6829 70.2439H50.9268V72H52.6829V70.2439Z" />
  <path d="M57.9512 70.2439H56.1951V72H57.9512V70.2439Z" />
  <path d="M59.7073 70.2439H57.9512V72H59.7073V70.2439Z" />
  <path d="M63.2195 70.2439H61.4634V72H63.2195V70.2439Z" />
  <path d="M64.9756 70.2439H63.2195V72H64.9756V70.2439Z" />
</svg>;
export default GoshDebotQrCode;
