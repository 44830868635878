const GoshDebotQrCode = () => <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.7561 0H1C0.447715 0 0 0.447716 0 1V1.7561H1.7561V0Z" fill="#FDFDFD" />
  <path d="M3.5122 0H1.7561V1.7561H3.5122V0Z" fill="#FDFDFD" />
  <path d="M5.26829 0H3.5122V1.7561H5.26829V0Z" fill="#FDFDFD" />
  <path d="M7.02439 0H5.26829V1.7561H7.02439V0Z" fill="#FDFDFD" />
  <path d="M8.78049 0H7.02439V1.7561H8.78049V0Z" fill="#FDFDFD" />
  <path d="M10.5366 0H8.78049V1.7561H10.5366V0Z" fill="#FDFDFD" />
  <path d="M12.2927 1C12.2927 0.447715 11.845 0 11.2927 0H10.5366V1.7561H12.2927V1Z" fill="#FDFDFD" />
  <path d="M15.8049 0.878048C15.8049 0.393116 15.4118 0 14.9268 0C14.4419 0 14.0488 0.393116 14.0488 0.878049C14.0488 1.36298 14.4419 1.7561 14.9268 1.7561H15.8049V0.878048Z" fill="#FDFDFD" />
  <path d="M19.3171 0.878049C19.3171 0.393116 18.924 0 18.439 0C17.9541 0 17.561 0.393117 17.561 0.878049V1.7561H18.439C18.924 1.7561 19.3171 1.36298 19.3171 0.878049Z" fill="#FDFDFD" />
  <path d="M22.8293 0H21.9512C21.4663 0 21.0732 0.393116 21.0732 0.878049C21.0732 1.36298 21.4663 1.7561 21.9512 1.7561H22.8293V0Z" fill="#FDFDFD" />
  <path d="M24.5854 0H22.8293V1.7561H24.5854V0Z" fill="#FDFDFD" />
  <path d="M26.3415 0H24.5854V1.7561H26.3415V0Z" fill="#FDFDFD" />
  <path d="M28.0976 0H26.3415V1.7561H28.0976V0Z" fill="#FDFDFD" />
  <path d="M29.8537 0.878049C29.8537 0.393116 29.4605 0 28.9756 0H28.0976V1.7561H28.9756C29.4605 1.7561 29.8537 1.36298 29.8537 0.878049Z" fill="#FDFDFD" />
  <path d="M35.122 0.878049C35.122 0.393116 34.7288 0 34.2439 0C33.759 0 33.3659 0.393117 33.3659 0.878049V1.7561H34.2439C34.7288 1.7561 35.122 1.36298 35.122 0.878049Z" fill="#FDFDFD" />
  <path d="M38.6341 0H37.7561C37.2712 0 36.8781 0.393116 36.8781 0.878049C36.8781 1.36298 37.2712 1.7561 37.7561 1.7561H38.6341V0Z" fill="#FDFDFD" />
  <path d="M40.3902 1C40.3902 0.447715 39.9425 0 39.3902 0H38.6341V1.7561H40.3902V1Z" fill="#FDFDFD" />
  <path d="M43.9024 0H43.1463C42.5941 0 42.1463 0.447716 42.1463 1V1.7561H43.9024V0Z" fill="#FDFDFD" />
  <path d="M45.6585 0H43.9024V1.7561H45.6585V0Z" fill="#FDFDFD" />
  <path d="M47.4146 1C47.4146 0.447715 46.9669 0 46.4146 0H45.6585V1.7561H47.4146V1Z" fill="#FDFDFD" />
  <path d="M50.9268 0.878048C50.9268 0.393116 50.5337 0 50.0488 0C49.5639 0 49.1707 0.393117 49.1707 0.878049V1.7561H50.9268V0.878048Z" fill="#FDFDFD" />
  <path d="M54.439 0.878049C54.439 0.393116 54.0459 0 53.561 0C53.076 0 52.6829 0.393116 52.6829 0.878049C52.6829 1.36298 53.076 1.7561 53.561 1.7561C54.0459 1.7561 54.439 1.36298 54.439 0.878049Z" fill="#FDFDFD" />
  <path d="M57.9512 0.878049C57.9512 0.393116 57.5581 0 57.0732 0C56.5882 0 56.1951 0.393116 56.1951 0.878049C56.1951 1.36298 56.5882 1.7561 57.0732 1.7561C57.5581 1.7561 57.9512 1.36298 57.9512 0.878049Z" fill="#FDFDFD" />
  <path d="M61.4634 0H60.7073C60.155 0 59.7073 0.447716 59.7073 1V1.7561H61.4634V0Z" fill="#FDFDFD" />
  <path d="M63.2195 0H61.4634V1.7561H63.2195V0Z" fill="#FDFDFD" />
  <path d="M64.9756 0H63.2195V1.7561H64.9756V0Z" fill="#FDFDFD" />
  <path d="M66.7317 0H64.9756V1.7561H66.7317V0Z" fill="#FDFDFD" />
  <path d="M68.4878 0H66.7317V1.7561H68.4878V0Z" fill="#FDFDFD" />
  <path d="M70.2439 0H68.4878V1.7561H70.2439V0Z" fill="#FDFDFD" />
  <path d="M72 1C72 0.447715 71.5523 0 71 0H70.2439V1.7561H72V1Z" fill="#FDFDFD" />
  <path d="M1.7561 1.7561H0V3.5122H1.7561V1.7561Z" fill="#FDFDFD" />
  <path d="M12.2927 1.7561H10.5366V3.5122H12.2927V1.7561Z" fill="#FDFDFD" />
  <path d="M17.561 1.7561H15.8049V3.5122H17.561V1.7561Z" fill="#FDFDFD" />
  <path d="M26.3415 1.7561H24.5854V3.5122H25.3415C25.8937 3.5122 26.3415 3.06448 26.3415 2.51219V1.7561Z" fill="#FDFDFD" />
  <path d="M33.3659 1.7561H32.6098C32.0575 1.7561 31.6098 2.20381 31.6098 2.7561V3.5122H33.3659V1.7561Z" fill="#FDFDFD" />
  <path d="M40.3902 1.7561H38.6341V3.5122H40.3902V1.7561Z" fill="#FDFDFD" />
  <path d="M43.9024 1.7561H42.1463V3.5122H43.9024V1.7561Z" fill="#FDFDFD" />
  <path d="M47.4146 1.7561H45.6585V2.5122C45.6585 3.06448 46.1063 3.5122 46.6585 3.5122H47.4146V1.7561Z" fill="#FDFDFD" />
  <path d="M50.9268 1.7561H49.1707V3.5122H49.9268C50.4791 3.5122 50.9268 3.06448 50.9268 2.51219V1.7561Z" fill="#FDFDFD" />
  <path d="M61.4634 1.7561H59.7073V3.5122H61.4634V1.7561Z" fill="#FDFDFD" />
  <path d="M72 1.7561H70.2439V3.5122H72V1.7561Z" fill="#FDFDFD" />
  <path d="M1.7561 3.5122H0V5.26829H1.7561V3.5122Z" fill="#FDFDFD" />
  <path d="M5.26829 3.5122H4.5122C3.95991 3.5122 3.5122 3.95991 3.5122 4.5122V5.26829H5.26829V3.5122Z" fill="#FDFDFD" />
  <path d="M7.02439 3.5122H5.26829V5.26829H7.02439V3.5122Z" fill="#FDFDFD" />
  <path d="M8.78049 4.5122C8.78049 3.95991 8.33277 3.5122 7.78049 3.5122H7.02439V5.26829H8.78049V4.5122Z" fill="#FDFDFD" />
  <path d="M12.2927 3.5122H10.5366V5.26829H12.2927V3.5122Z" fill="#FDFDFD" />
  <path d="M17.561 3.5122H15.8049V5.26829H17.561V3.5122Z" fill="#FDFDFD" />
  <path d="M19.3171 3.5122H17.561V5.26829H19.3171V3.5122Z" fill="#FDFDFD" />
  <path d="M21.0732 3.5122H19.3171V5.26829H21.0732V3.5122Z" fill="#FDFDFD" />
  <path d="M22.8293 3.5122H21.0732V5.26829H22.8293V3.5122Z" fill="#FDFDFD" />
  <path d="M24.5854 3.5122H22.8293V5.26829H24.5854V3.5122Z" fill="#FDFDFD" />
  <path d="M29.8537 4.39024C29.8537 3.90531 29.4605 3.5122 28.9756 3.5122C28.4907 3.5122 28.0976 3.90531 28.0976 4.39024V5.26829H29.8537V4.39024Z" fill="#FDFDFD" />
  <path d="M33.3659 3.5122H31.6098V5.26829H32.3659C32.9181 5.26829 33.3659 4.82058 33.3659 4.26829V3.5122Z" fill="#FDFDFD" />
  <path d="M36.8781 3.5122H36.122C35.5697 3.5122 35.122 3.95991 35.122 4.5122V5.26829H36.8781V3.5122Z" fill="#FDFDFD" />
  <path d="M38.6341 3.5122H36.8781V5.26829H38.6341V3.5122Z" fill="#FDFDFD" />
  <path d="M42.1463 3.5122H40.3902V5.26829H42.1463V3.5122Z" fill="#FDFDFD" />
  <path d="M43.9024 3.5122H42.1463V5.26829H43.9024V3.5122Z" fill="#FDFDFD" />
  <path d="M49.1707 3.5122H47.4146V5.26829H49.1707V3.5122Z" fill="#FDFDFD" />
  <path d="M54.439 3.5122H53.6829C53.1306 3.5122 52.6829 3.95991 52.6829 4.5122V5.26829H54.439V3.5122Z" fill="#FDFDFD" />
  <path d="M56.1951 4.5122C56.1951 3.95991 55.7474 3.5122 55.1951 3.5122H54.439V5.26829H56.1951V4.5122Z" fill="#FDFDFD" />
  <path d="M61.4634 3.5122H59.7073V5.26829H61.4634V3.5122Z" fill="#FDFDFD" />
  <path d="M64.9756 3.5122H64.2195C63.6672 3.5122 63.2195 3.95991 63.2195 4.5122V5.26829H64.9756V3.5122Z" fill="#FDFDFD" />
  <path d="M66.7317 3.5122H64.9756V5.26829H66.7317V3.5122Z" fill="#FDFDFD" />
  <path d="M68.4878 4.5122C68.4878 3.95991 68.0401 3.5122 67.4878 3.5122H66.7317V5.26829H68.4878V4.5122Z" fill="#FDFDFD" />
  <path d="M72 3.5122H70.2439V5.26829H72V3.5122Z" fill="#FDFDFD" />
  <path d="M1.7561 5.26829H0V7.02439H1.7561V5.26829Z" fill="#FDFDFD" />
  <path d="M5.26829 5.26829H3.5122V7.02439H5.26829V5.26829Z" fill="#FDFDFD" />
  <path d="M7.02439 5.26829H5.26829V7.02439H7.02439V5.26829Z" fill="#FDFDFD" />
  <path d="M8.78049 5.26829H7.02439V7.02439H8.78049V5.26829Z" fill="#FDFDFD" />
  <path d="M12.2927 5.26829H10.5366V7.02439H12.2927V5.26829Z" fill="#FDFDFD" />
  <path d="M15.8049 5.26829H14.9268C14.4419 5.26829 14.0488 5.66141 14.0488 6.14634C14.0488 6.63127 14.4419 7.02439 14.9268 7.02439H15.8049V5.26829Z" fill="#FDFDFD" />
  <path d="M21.0732 5.26829H19.3171V7.02439H21.0732V5.26829Z" fill="#FDFDFD" />
  <path d="M22.8293 5.26829H21.0732V7.02439H22.8293V5.26829Z" fill="#FDFDFD" />
  <path d="M24.5854 5.26829H22.8293V7.02439H24.5854V5.26829Z" fill="#FDFDFD" />
  <path d="M26.3415 6.14634C26.3415 5.66141 25.9483 5.26829 25.4634 5.26829H24.5854V7.02439H25.4634C25.9483 7.02439 26.3415 6.63127 26.3415 6.14634Z" fill="#FDFDFD" />
  <path d="M29.8537 5.26829H28.0976V6.02439C28.0976 6.57667 28.5453 7.02439 29.0976 7.02439H29.8537V5.26829Z" fill="#FDFDFD" />
  <path d="M31.6098 5.26829H29.8537V7.02439H31.6098V5.26829Z" fill="#FDFDFD" />
  <path d="M36.8781 5.26829H35.122V6.02439C35.122 6.57667 35.5697 7.02439 36.122 7.02439H36.8781V5.26829Z" fill="#FDFDFD" />
  <path d="M38.6341 5.26829H36.8781V7.02439H38.6341V5.26829Z" fill="#FDFDFD" />
  <path d="M40.3902 5.26829H38.6341V7.02439H40.3902V5.26829Z" fill="#FDFDFD" />
  <path d="M42.1463 5.26829H40.3902V7.02439H42.1463V5.26829Z" fill="#FDFDFD" />
  <path d="M45.6585 6.26829C45.6585 5.71601 45.2108 5.26829 44.6585 5.26829H43.9024V7.02439H45.6585V6.26829Z" fill="#FDFDFD" />
  <path d="M49.1707 5.26829H47.4146V7.02439H49.1707V5.26829Z" fill="#FDFDFD" />
  <path d="M52.6829 5.26829H51.9268C51.3745 5.26829 50.9268 5.71601 50.9268 6.26829V7.02439H52.6829V5.26829Z" fill="#FDFDFD" />
  <path d="M54.439 5.26829H52.6829V7.02439H54.439V5.26829Z" fill="#FDFDFD" />
  <path d="M56.1951 5.26829H54.439V7.02439H55.1951C55.7474 7.02439 56.1951 6.57668 56.1951 6.02439V5.26829Z" fill="#FDFDFD" />
  <path d="M61.4634 5.26829H59.7073V7.02439H61.4634V5.26829Z" fill="#FDFDFD" />
  <path d="M64.9756 5.26829H63.2195V7.02439H64.9756V5.26829Z" fill="#FDFDFD" />
  <path d="M66.7317 5.26829H64.9756V7.02439H66.7317V5.26829Z" fill="#FDFDFD" />
  <path d="M68.4878 5.26829H66.7317V7.02439H68.4878V5.26829Z" fill="#FDFDFD" />
  <path d="M72 5.26829H70.2439V7.02439H72V5.26829Z" fill="#FDFDFD" />
  <path d="M1.7561 7.02439H0V8.78049H1.7561V7.02439Z" fill="#FDFDFD" />
  <path d="M5.26829 7.02439H3.5122V7.78049C3.5122 8.33277 3.95991 8.78049 4.5122 8.78049H5.26829V7.02439Z" fill="#FDFDFD" />
  <path d="M7.02439 7.02439H5.26829V8.78049H7.02439V7.02439Z" fill="#FDFDFD" />
  <path d="M8.78049 7.02439H7.02439V8.78049H7.78049C8.33277 8.78049 8.78049 8.33277 8.78049 7.78049V7.02439Z" fill="#FDFDFD" />
  <path d="M12.2927 7.02439H10.5366V8.78049H12.2927V7.02439Z" fill="#FDFDFD" />
  <path d="M17.561 7.02439L15.8049 7.02439V8.78049H17.561V7.02439Z" fill="#FDFDFD" />
  <path d="M19.3171 7.02439L17.561 7.02439V8.78049H19.3171V7.02439Z" fill="#FDFDFD" />
  <path d="M31.6098 7.02439H29.8537V8.78049H31.6098V7.02439Z" fill="#FDFDFD" />
  <path d="M38.6341 7.02439H36.8781V8.78049H38.6341V7.02439Z" fill="#FDFDFD" />
  <path d="M40.3902 7.02439H38.6341V8.78049H40.3902V7.02439Z" fill="#FDFDFD" />
  <path d="M43.9024 7.02439H42.1463V8.78049H43.9024V7.02439Z" fill="#FDFDFD" />
  <path d="M47.4146 7.02439H45.6585V8.78049H47.4146V7.02439Z" fill="#FDFDFD" />
  <path d="M49.1707 7.02439H47.4146V8.78049H49.1707V7.02439Z" fill="#FDFDFD" />
  <path d="M50.9268 7.02439H49.1707V8.78049H50.9268V7.02439Z" fill="#FDFDFD" />
  <path d="M52.6829 7.02439H50.9268V8.78049H52.6829V7.02439Z" fill="#FDFDFD" />
  <path d="M61.4634 7.02439H59.7073V8.78049H61.4634V7.02439Z" fill="#FDFDFD" />
  <path d="M64.9756 7.02439H63.2195V7.78049C63.2195 8.33277 63.6672 8.78049 64.2195 8.78049H64.9756V7.02439Z" fill="#FDFDFD" />
  <path d="M66.7317 7.02439H64.9756V8.78049H66.7317V7.02439Z" fill="#FDFDFD" />
  <path d="M68.4878 7.02439H66.7317V8.78049H67.4878C68.0401 8.78049 68.4878 8.33277 68.4878 7.78049V7.02439Z" fill="#FDFDFD" />
  <path d="M72 7.02439H70.2439V8.78049H72V7.02439Z" fill="#FDFDFD" />
  <path d="M1.7561 8.78049H0V10.5366H1.7561V8.78049Z" fill="#FDFDFD" />
  <path d="M12.2927 8.78049H10.5366V10.5366H12.2927V8.78049Z" fill="#FDFDFD" />
  <path d="M15.8049 8.78049H15.0488C14.4965 8.78049 14.0488 9.2282 14.0488 9.78049V10.5366H15.8049V8.78049Z" fill="#FDFDFD" />
  <path d="M17.561 8.78049H15.8049V10.5366H17.561V8.78049Z" fill="#FDFDFD" />
  <path d="M19.3171 8.78049H17.561V10.5366H19.3171V8.78049Z" fill="#FDFDFD" />
  <path d="M21.0732 8.78049H19.3171V10.5366H21.0732V8.78049Z" fill="#FDFDFD" />
  <path d="M22.8293 8.78049H21.0732V10.5366H22.8293V8.78049Z" fill="#FDFDFD" />
  <path d="M24.5854 9.78049C24.5854 9.2282 24.1377 8.78049 23.5854 8.78049H22.8293V10.5366H24.5854V9.78049Z" fill="#FDFDFD" />
  <path d="M28.0976 8.78049H27.3415C26.7892 8.78049 26.3415 9.2282 26.3415 9.78049V10.5366H28.0976V8.78049Z" fill="#FDFDFD" />
  <path d="M29.8537 8.78049H28.0976V10.5366H29.8537V8.78049Z" fill="#FDFDFD" />
  <path d="M31.6098 8.78049H29.8537V10.5366H31.6098V8.78049Z" fill="#FDFDFD" />
  <path d="M33.3659 8.78049H31.6098V10.5366H33.3659V8.78049Z" fill="#FDFDFD" />
  <path d="M35.122 8.78049H33.3659V10.5366H35.122V8.78049Z" fill="#FDFDFD" />
  <path d="M36.8781 8.78049H35.122V10.5366H36.8781V8.78049Z" fill="#FDFDFD" />
  <path d="M38.6341 8.78049H36.8781V10.5366H38.6341V8.78049Z" fill="#FDFDFD" />
  <path d="M40.3902 8.78049H38.6341V10.5366H40.3902V8.78049Z" fill="#FDFDFD" />
  <path d="M42.1463 8.78049H40.3902V10.5366H42.1463V8.78049Z" fill="#FDFDFD" />
  <path d="M45.6585 8.78049H43.9024V10.5366H45.6585V8.78049Z" fill="#FDFDFD" />
  <path d="M47.4146 8.78049H45.6585V10.5366H47.4146V8.78049Z" fill="#FDFDFD" />
  <path d="M54.439 8.78049H52.6829V10.5366H54.439V8.78049Z" fill="#FDFDFD" />
  <path d="M56.1951 9.78049C56.1951 9.2282 55.7474 8.78049 55.1951 8.78049H54.439V10.5366H56.1951V9.78049Z" fill="#FDFDFD" />
  <path d="M61.4634 8.78049H59.7073V10.5366H61.4634V8.78049Z" fill="#FDFDFD" />
  <path d="M72 8.78049H70.2439V10.5366H72V8.78049Z" fill="#FDFDFD" />
  <path d="M1.7561 10.5366H0V11.2927C0 11.845 0.447716 12.2927 1 12.2927H1.7561V10.5366Z" fill="#FDFDFD" />
  <path d="M3.5122 10.5366H1.7561V12.2927H3.5122V10.5366Z" fill="#FDFDFD" />
  <path d="M5.26829 10.5366H3.5122V12.2927H5.26829V10.5366Z" fill="#FDFDFD" />
  <path d="M7.02439 10.5366H5.26829V12.2927H7.02439V10.5366Z" fill="#FDFDFD" />
  <path d="M8.78049 10.5366H7.02439V12.2927H8.78049V10.5366Z" fill="#FDFDFD" />
  <path d="M10.5366 10.5366H8.78049V12.2927H10.5366V10.5366Z" fill="#FDFDFD" />
  <path d="M12.2927 10.5366H10.5366V12.2927H11.2927C11.845 12.2927 12.2927 11.845 12.2927 11.2927V10.5366Z" fill="#FDFDFD" />
  <path d="M15.8049 10.5366H14.0488V11.4146C14.0488 11.8996 14.4419 12.2927 14.9268 12.2927C15.4118 12.2927 15.8049 11.8996 15.8049 11.4146V10.5366Z" fill="#FDFDFD" />
  <path d="M19.3171 10.5366H17.561V11.2927C17.561 11.845 18.0087 12.2927 18.561 12.2927H19.3171V10.5366Z" fill="#FDFDFD" />
  <path d="M22.8293 10.5366H21.0732V12.2927H21.8293C22.3816 12.2927 22.8293 11.845 22.8293 11.2927V10.5366Z" fill="#FDFDFD" />
  <path d="M26.3415 10.5366H24.5854V12.2927H26.3415V10.5366Z" fill="#FDFDFD" />
  <path d="M29.8537 10.5366H28.0976V12.2927H29.8537V10.5366Z" fill="#FDFDFD" />
  <path d="M33.3659 10.5366H31.6098V12.2927H32.3659C32.9181 12.2927 33.3659 11.845 33.3659 11.2927V10.5366Z" fill="#FDFDFD" />
  <path d="M36.8781 10.5366H35.122V11.2927C35.122 11.845 35.5697 12.2927 36.122 12.2927H36.8781V10.5366Z" fill="#FDFDFD" />
  <path d="M40.3902 10.5366H38.6341V12.2927H40.3902V10.5366Z" fill="#FDFDFD" />
  <path d="M43.9024 10.5366H42.1463V12.2927H43.9024V10.5366Z" fill="#FDFDFD" />
  <path d="M47.4146 10.5366H45.6585V12.2927H47.4146V10.5366Z" fill="#FDFDFD" />
  <path d="M50.9268 11.4146C50.9268 10.9297 50.5337 10.5366 50.0488 10.5366C49.5639 10.5366 49.1707 10.9297 49.1707 11.4146V12.2927H50.9268V11.4146Z" fill="#FDFDFD" />
  <path d="M54.439 10.5366H52.6829V12.2927H54.439V10.5366Z" fill="#FDFDFD" />
  <path d="M57.9512 11.5366C57.9512 10.9843 57.5035 10.5366 56.9512 10.5366H56.1951V12.2927H57.9512V11.5366Z" fill="#FDFDFD" />
  <path d="M61.4634 10.5366H59.7073V11.2927C59.7073 11.845 60.155 12.2927 60.7073 12.2927H61.4634V10.5366Z" fill="#FDFDFD" />
  <path d="M63.2195 10.5366H61.4634V12.2927H63.2195V10.5366Z" fill="#FDFDFD" />
  <path d="M64.9756 10.5366H63.2195V12.2927H64.9756V10.5366Z" fill="#FDFDFD" />
  <path d="M66.7317 10.5366H64.9756V12.2927H66.7317V10.5366Z" fill="#FDFDFD" />
  <path d="M68.4878 10.5366H66.7317V12.2927H68.4878V10.5366Z" fill="#FDFDFD" />
  <path d="M70.2439 10.5366H68.4878V12.2927H70.2439V10.5366Z" fill="#FDFDFD" />
  <path d="M72 10.5366H70.2439V12.2927H71C71.5523 12.2927 72 11.845 72 11.2927V10.5366Z" fill="#FDFDFD" />
  <path d="M21.0732 12.2927H19.3171V13.1707C19.3171 13.6557 19.7102 14.0488 20.1951 14.0488C20.6801 14.0488 21.0732 13.6557 21.0732 13.1707V12.2927Z" fill="#FDFDFD" />
  <path d="M26.3415 12.2927H24.5854V14.0488H26.3415V12.2927Z" fill="#FDFDFD" />
  <path d="M28.0976 12.2927H26.3415V14.0488H28.0976V12.2927Z" fill="#FDFDFD" />
  <path d="M29.8537 12.2927H28.0976V14.0488H29.8537V12.2927Z" fill="#FDFDFD" />
  <path d="M31.6098 12.2927H29.8537V14.0488H31.6098V12.2927Z" fill="#FDFDFD" />
  <path d="M38.6341 12.2927H36.8781V13.0488C36.8781 13.6011 37.3258 14.0488 37.8781 14.0488H38.6341V12.2927Z" fill="#FDFDFD" />
  <path d="M40.3902 12.2927H38.6341V14.0488H40.3902V12.2927Z" fill="#FDFDFD" />
  <path d="M43.9024 12.2927H42.1463V14.0488H43.9024V12.2927Z" fill="#FDFDFD" />
  <path d="M45.6585 12.2927H43.9024V14.0488H45.6585V12.2927Z" fill="#FDFDFD" />
  <path d="M47.4146 12.2927H45.6585V14.0488H46.4146C46.9669 14.0488 47.4146 13.6011 47.4146 13.0488V12.2927Z" fill="#FDFDFD" />
  <path d="M50.9268 12.2927H49.1707V14.0488H50.9268V12.2927Z" fill="#FDFDFD" />
  <path d="M52.6829 12.2927H50.9268V14.0488H52.6829V12.2927Z" fill="#FDFDFD" />
  <path d="M56.1951 12.2927H54.439V14.0488H56.1951V12.2927Z" fill="#FDFDFD" />
  <path d="M57.9512 12.2927H56.1951V14.0488H57.9512V12.2927Z" fill="#FDFDFD" />
  <path d="M10.5366 14.0488H9.78049C9.2282 14.0488 8.78049 14.4965 8.78049 15.0488V15.8049H10.5366V14.0488Z" fill="#FDFDFD" />
  <path d="M12.2927 14.9268C12.2927 14.4419 11.8996 14.0488 11.4146 14.0488H10.5366V15.8049H11.4146C11.8996 15.8049 12.2927 15.4118 12.2927 14.9268Z" fill="#FDFDFD" />
  <path d="M15.8049 14.9268C15.8049 14.4419 15.4118 14.0488 14.9268 14.0488C14.4419 14.0488 14.0488 14.4419 14.0488 14.9268C14.0488 15.4118 14.4419 15.8049 14.9268 15.8049C15.4118 15.8049 15.8049 15.4118 15.8049 14.9268Z" fill="#FDFDFD" />
  <path d="M26.3415 14.0488H24.5854V15.8049H26.3415V14.0488Z" fill="#FDFDFD" />
  <path d="M33.3659 15.0488C33.3659 14.4965 32.9181 14.0488 32.3659 14.0488H31.6098V15.8049H33.3659V15.0488Z" fill="#FDFDFD" />
  <path d="M40.3902 14.0488H38.6341V15.8049H40.3902V14.0488Z" fill="#FDFDFD" />
  <path d="M42.1463 14.0488H40.3902V15.8049H42.1463V14.0488Z" fill="#FDFDFD" />
  <path d="M43.9024 14.0488H42.1463V15.8049H43.9024V14.0488Z" fill="#FDFDFD" />
  <path d="M45.6585 14.0488H43.9024V15.8049H45.6585V14.0488Z" fill="#FDFDFD" />
  <path d="M50.9268 14.0488H49.1707V15.8049H50.9268V14.0488Z" fill="#FDFDFD" />
  <path d="M52.6829 14.0488H50.9268V15.8049H52.6829V14.0488Z" fill="#FDFDFD" />
  <path d="M54.439 14.0488H52.6829V15.8049H54.439V14.0488Z" fill="#FDFDFD" />
  <path d="M56.1951 14.0488H54.439V15.8049H56.1951V14.0488Z" fill="#FDFDFD" />
  <path d="M57.9512 14.0488H56.1951V15.8049H57.9512V14.0488Z" fill="#FDFDFD" />
  <path d="M59.7073 15.0488C59.7073 14.4965 59.2596 14.0488 58.7073 14.0488H57.9512V15.8049H59.7073V15.0488Z" fill="#FDFDFD" />
  <path d="M63.2195 14.9268C63.2195 14.4419 62.8264 14.0488 62.3415 14.0488C61.8565 14.0488 61.4634 14.4419 61.4634 14.9268V15.8049H63.2195V14.9268Z" fill="#FDFDFD" />
  <path d="M66.7317 14.9268C66.7317 14.4419 66.3386 14.0488 65.8537 14.0488C65.3687 14.0488 64.9756 14.4419 64.9756 14.9268V15.8049H66.7317V14.9268Z" fill="#FDFDFD" />
  <path d="M72 14.9268C72 14.4419 71.6069 14.0488 71.122 14.0488C70.637 14.0488 70.2439 14.4419 70.2439 14.9268V15.8049H72V14.9268Z" fill="#FDFDFD" />
  <path d="M7.02439 16.6829C7.02439 16.198 6.63127 15.8049 6.14634 15.8049C5.66141 15.8049 5.26829 16.198 5.26829 16.6829V17.561H7.02439V16.6829Z" fill="#FDFDFD" />
  <path d="M10.5366 15.8049H8.78049V17.561H10.5366V15.8049Z" fill="#FDFDFD" />
  <path d="M19.3171 16.6829C19.3171 16.198 18.924 15.8049 18.439 15.8049C17.9541 15.8049 17.561 16.198 17.561 16.6829V17.561H18.439C18.924 17.561 19.3171 17.1679 19.3171 16.6829Z" fill="#FDFDFD" />
  <path d="M24.5854 15.8049H23.7073C23.2224 15.8049 22.8293 16.198 22.8293 16.6829C22.8293 17.1679 23.2224 17.561 23.7073 17.561H24.5854V15.8049Z" fill="#FDFDFD" />
  <path d="M26.3415 15.8049H24.5854V17.561H26.3415V15.8049Z" fill="#FDFDFD" />
  <path d="M28.0976 16.6829C28.0976 16.198 27.7044 15.8049 27.2195 15.8049H26.3415V17.561H27.2195C27.7044 17.561 28.0976 17.1679 28.0976 16.6829Z" fill="#FDFDFD" />
  <path d="M33.3659 15.8049H31.6098V16.561C31.6098 17.1133 32.0575 17.561 32.6098 17.561H33.3659V15.8049Z" fill="#FDFDFD" />
  <path d="M35.122 16.8049C35.122 16.2526 34.6742 15.8049 34.122 15.8049H33.3659V17.561H35.122V16.8049Z" fill="#FDFDFD" />
  <path d="M38.6341 15.8049H37.8781C37.3258 15.8049 36.8781 16.2526 36.8781 16.8049V17.561H37.6341C38.1864 17.561 38.6341 17.1133 38.6341 16.561V15.8049Z" fill="#FDFDFD" />
  <path d="M43.9024 15.8049H42.1463V17.561H43.9024V15.8049Z" fill="#FDFDFD" />
  <path d="M45.6585 15.8049H43.9024V17.561H45.6585V15.8049Z" fill="#FDFDFD" />
  <path d="M49.1707 15.8049H48.4146C47.8624 15.8049 47.4146 16.2526 47.4146 16.8049V17.561H49.1707V15.8049Z" fill="#FDFDFD" />
  <path d="M50.9268 15.8049H49.1707V17.561H50.9268V15.8049Z" fill="#FDFDFD" />
  <path d="M52.6829 15.8049H50.9268V17.561H52.6829V15.8049Z" fill="#FDFDFD" />
  <path d="M54.439 15.8049H52.6829V17.561H54.439V15.8049Z" fill="#FDFDFD" />
  <path d="M56.1951 15.8049H54.439V17.561H56.1951V15.8049Z" fill="#FDFDFD" />
  <path d="M57.9512 15.8049H56.1951V17.561H57.9512V15.8049Z" fill="#FDFDFD" />
  <path d="M61.4634 15.8049H59.7073V16.561C59.7073 17.1133 60.155 17.561 60.7073 17.561H61.4634V15.8049Z" fill="#FDFDFD" />
  <path d="M63.2195 15.8049H61.4634V17.561H63.2195V15.8049Z" fill="#FDFDFD" />
  <path d="M64.9756 15.8049H63.2195V17.561H64.9756V15.8049Z" fill="#FDFDFD" />
  <path d="M68.4878 15.8049H66.7317V17.561H68.4878V15.8049Z" fill="#FDFDFD" />
  <path d="M70.2439 15.8049H68.4878V17.561H70.2439V15.8049Z" fill="#FDFDFD" />
  <path d="M72 15.8049H70.2439V17.561H72V15.8049Z" fill="#FDFDFD" />
  <path d="M1.7561 17.561H0.878048C0.393116 17.561 0 17.9541 0 18.439C0 18.924 0.393117 19.3171 0.878049 19.3171H1.7561V17.561Z" fill="#FDFDFD" />
  <path d="M3.5122 17.561H1.7561V19.3171H3.5122V17.561Z" fill="#FDFDFD" />
  <path d="M5.26829 17.561H3.5122V19.3171H5.26829V17.561Z" fill="#FDFDFD" />
  <path d="M8.78049 17.561H7.02439L7.02439 19.3171H8.78049V17.561Z" fill="#FDFDFD" />
  <path d="M10.5366 17.561H8.78049V19.3171H10.5366V17.561Z" fill="#FDFDFD" />
  <path d="M12.2927 17.561H10.5366V19.3171H12.2927V17.561Z" fill="#FDFDFD" />
  <path d="M14.0488 17.561H12.2927V19.3171H14.0488V17.561Z" fill="#FDFDFD" />
  <path d="M15.8049 17.561H14.0488V19.3171H15.8049V17.561Z" fill="#FDFDFD" />
  <path d="M17.561 17.561H15.8049V19.3171H16.561C17.1133 19.3171 17.561 18.8694 17.561 18.3171V17.561Z" fill="#FDFDFD" />
  <path d="M36.8781 17.561H35.122V19.3171H36.8781V17.561Z" fill="#FDFDFD" />
  <path d="M42.1463 17.561H41.2683C40.7834 17.561 40.3902 17.9541 40.3902 18.439C40.3902 18.924 40.7834 19.3171 41.2683 19.3171H42.1463V17.561Z" fill="#FDFDFD" />
  <path d="M47.4146 17.561H45.6585V18.439C45.6585 18.924 46.0517 19.3171 46.5366 19.3171C47.0215 19.3171 47.4146 18.924 47.4146 18.439V17.561Z" fill="#FDFDFD" />
  <path d="M52.6829 17.561H50.9268V19.3171H52.6829V17.561Z" fill="#FDFDFD" />
  <path d="M54.439 17.561H52.6829V19.3171H53.439C53.9913 19.3171 54.439 18.8694 54.439 18.3171V17.561Z" fill="#FDFDFD" />
  <path d="M57.9512 17.561H56.1951V18.439C56.1951 18.924 56.5882 19.3171 57.0732 19.3171C57.5581 19.3171 57.9512 18.924 57.9512 18.439V17.561Z" fill="#FDFDFD" />
  <path d="M63.2195 17.561H61.4634V19.3171H63.2195V17.561Z" fill="#FDFDFD" />
  <path d="M64.9756 17.561H63.2195V19.3171H64.9756V17.561Z" fill="#FDFDFD" />
  <path d="M66.7317 17.561H64.9756V19.3171H66.7317V17.561Z" fill="#FDFDFD" />
  <path d="M68.4878 17.561H66.7317V19.3171H68.4878V17.561Z" fill="#FDFDFD" />
  <path d="M70.2439 17.561H68.4878V19.3171H70.2439V17.561Z" fill="#FDFDFD" />
  <path d="M72 17.561H70.2439V19.3171H71C71.5523 19.3171 72 18.8694 72 18.3171V17.561Z" fill="#FDFDFD" />
  <path d="M3.5122 19.3171H1.7561V21.0732H3.5122V19.3171Z" fill="#FDFDFD" />
  <path d="M5.26829 19.3171H3.5122V21.0732H5.26829V19.3171Z" fill="#FDFDFD" />
  <path d="M7.02439 19.3171H5.26829V21.0732H7.02439L7.02439 19.3171Z" fill="#FDFDFD" />
  <path d="M10.5366 19.3171H8.78049V20.0732C8.78049 20.6255 9.2282 21.0732 9.78049 21.0732H10.5366V19.3171Z" fill="#FDFDFD" />
  <path d="M14.0488 19.3171H12.2927V21.0732H14.0488V19.3171Z" fill="#FDFDFD" />
  <path d="M26.3415 19.3171H25.5854C25.0331 19.3171 24.5854 19.7648 24.5854 20.3171V21.0732H26.3415V19.3171Z" fill="#FDFDFD" />
  <path d="M28.0976 20.3171C28.0976 19.7648 27.6498 19.3171 27.0976 19.3171H26.3415V21.0732H28.0976V20.3171Z" fill="#FDFDFD" />
  <path d="M36.8781 19.3171H35.122V20.0732C35.122 20.6255 35.5697 21.0732 36.122 21.0732H36.8781V19.3171Z" fill="#FDFDFD" />
  <path d="M43.9024 20.1951C43.9024 19.7102 43.5093 19.3171 43.0244 19.3171H42.1463V20.1951C42.1463 20.6801 42.5395 21.0732 43.0244 21.0732C43.5093 21.0732 43.9024 20.6801 43.9024 20.1951Z" fill="#FDFDFD" />
  <path d="M50.9268 19.3171H50.1707C49.6185 19.3171 49.1707 19.7648 49.1707 20.3171V21.0732H50.9268V19.3171Z" fill="#FDFDFD" />
  <path d="M61.4634 19.3171H60.7073C60.155 19.3171 59.7073 19.7648 59.7073 20.3171V21.0732H61.4634V19.3171Z" fill="#FDFDFD" />
  <path d="M64.9756 19.3171H63.2195V21.0732H64.9756V19.3171Z" fill="#FDFDFD" />
  <path d="M70.2439 19.3171H68.4878V21.0732H70.2439V19.3171Z" fill="#FDFDFD" />
  <path d="M1.7561 21.0732H1C0.447715 21.0732 0 21.5209 0 22.0732V22.8293H1.7561V21.0732Z" fill="#FDFDFD" />
  <path d="M7.02439 21.0732H5.26829V22.8293H7.02439V21.0732Z" fill="#FDFDFD" />
  <path d="M12.2927 21.0732H10.5366V22.8293H11.2927C11.845 22.8293 12.2927 22.3816 12.2927 21.8293V21.0732Z" fill="#FDFDFD" />
  <path d="M15.8049 22.0732C15.8049 21.5209 15.3572 21.0732 14.8049 21.0732H14.0488V22.8293H15.8049V22.0732Z" fill="#FDFDFD" />
  <path d="M19.3171 21.9512C19.3171 21.4663 18.924 21.0732 18.439 21.0732C17.9541 21.0732 17.561 21.4663 17.561 21.9512C17.561 22.4362 17.9541 22.8293 18.439 22.8293C18.924 22.8293 19.3171 22.4362 19.3171 21.9512Z" fill="#FDFDFD" />
  <path d="M24.5854 21.0732H23.8293C23.277 21.0732 22.8293 21.5209 22.8293 22.0732V22.8293H24.5854V21.0732Z" fill="#FDFDFD" />
  <path d="M26.3415 21.0732H24.5854V22.8293H26.3415V21.0732Z" fill="#FDFDFD" />
  <path d="M29.8537 22.0732C29.8537 21.5209 29.4059 21.0732 28.8537 21.0732H28.0976V22.8293H29.8537V22.0732Z" fill="#FDFDFD" />
  <path d="M33.3659 21.9512C33.3659 21.4663 32.9727 21.0732 32.4878 21.0732C32.0029 21.0732 31.6098 21.4663 31.6098 21.9512V22.8293H33.3659V21.9512Z" fill="#FDFDFD" />
  <path d="M38.6341 22.0732C38.6341 21.5209 38.1864 21.0732 37.6341 21.0732H36.8781V22.8293H38.6341V22.0732Z" fill="#FDFDFD" />
  <path d="M49.1707 21.0732H48.4146C47.8624 21.0732 47.4146 21.5209 47.4146 22.0732V22.8293H49.1707V21.0732Z" fill="#FDFDFD" />
  <path d="M50.9268 21.0732H49.1707V22.8293H50.9268V21.0732Z" fill="#FDFDFD" />
  <path d="M57.9512 21.0732H57.1951C56.6428 21.0732 56.1951 21.5209 56.1951 22.0732V22.8293H57.9512V21.0732Z" fill="#FDFDFD" />
  <path d="M59.7073 21.0732H57.9512V22.8293H59.7073V21.0732Z" fill="#FDFDFD" />
  <path d="M61.4634 21.0732H59.7073V22.8293H61.4634V21.0732Z" fill="#FDFDFD" />
  <path d="M63.2195 21.0732H61.4634V22.8293H63.2195V21.0732Z" fill="#FDFDFD" />
  <path d="M64.9756 21.0732H63.2195V22.8293H64.9756V21.0732Z" fill="#FDFDFD" />
  <path d="M66.7317 22.0732C66.7317 21.5209 66.284 21.0732 65.7317 21.0732H64.9756V22.8293H66.7317V22.0732Z" fill="#FDFDFD" />
  <path d="M70.2439 21.0732H68.4878V22.8293H70.2439V21.0732Z" fill="#FDFDFD" />
  <path d="M1.7561 22.8293H0V24.5854H1.7561V22.8293Z" fill="#FDFDFD" />
  <path d="M5.26829 22.8293H4.5122C3.95991 22.8293 3.5122 23.277 3.5122 23.8293V24.5854H5.26829V22.8293Z" fill="#FDFDFD" />
  <path d="M7.02439 22.8293H5.26829V24.5854H7.02439V22.8293Z" fill="#FDFDFD" />
  <path d="M8.78049 22.8293H7.02439V24.5854H8.78049V22.8293Z" fill="#FDFDFD" />
  <path d="M10.5366 22.8293H8.78049V24.5854H10.5366V22.8293Z" fill="#FDFDFD" />
  <path d="M15.8049 22.8293H14.0488V24.5854H15.8049V22.8293Z" fill="#FDFDFD" />
  <path d="M24.5854 22.8293H22.8293V24.5854H24.5854V22.8293Z" fill="#FDFDFD" />
  <path d="M26.3415 22.8293H24.5854V24.5854H26.3415V22.8293Z" fill="#FDFDFD" />
  <path d="M29.8537 22.8293H28.0976V24.5854H29.8537V22.8293Z" fill="#FDFDFD" />
  <path d="M33.3659 22.8293H31.6098V23.5854C31.6098 24.1377 32.0575 24.5854 32.6098 24.5854H33.3659V22.8293Z" fill="#FDFDFD" />
  <path d="M35.122 23.8293C35.122 23.277 34.6742 22.8293 34.122 22.8293H33.3659V24.5854H35.122V23.8293Z" fill="#FDFDFD" />
  <path d="M38.6341 22.8293H36.8781V24.5854H38.6341V22.8293Z" fill="#FDFDFD" />
  <path d="M43.9024 22.8293H43.1463C42.5941 22.8293 42.1463 23.277 42.1463 23.8293V24.5854H43.9024V22.8293Z" fill="#FDFDFD" />
  <path d="M45.6585 22.8293H43.9024V24.5854H45.6585V22.8293Z" fill="#FDFDFD" />
  <path d="M47.4146 22.8293H45.6585V24.5854H47.4146V22.8293Z" fill="#FDFDFD" />
  <path d="M52.6829 22.8293H50.9268V24.5854H52.6829V22.8293Z" fill="#FDFDFD" />
  <path d="M54.439 23.7073C54.439 23.2224 54.0459 22.8293 53.561 22.8293H52.6829V24.5854H53.561C54.0459 24.5854 54.439 24.1923 54.439 23.7073Z" fill="#FDFDFD" />
  <path d="M57.9512 22.8293H56.1951V24.5854H57.9512V22.8293Z" fill="#FDFDFD" />
  <path d="M61.4634 22.8293H59.7073V24.5854H61.4634V22.8293Z" fill="#FDFDFD" />
  <path d="M63.2195 22.8293H61.4634V24.5854H63.2195V22.8293Z" fill="#FDFDFD" />
  <path d="M66.7317 22.8293H64.9756V24.5854H66.7317V22.8293Z" fill="#FDFDFD" />
  <path d="M68.4878 22.8293H66.7317V24.5854H68.4878V22.8293Z" fill="#FDFDFD" />
  <path d="M72 23.8293C72 23.277 71.5523 22.8293 71 22.8293H70.2439V24.5854H72V23.8293Z" fill="#FDFDFD" />
  <path d="M1.7561 24.5854H0V26.3415H1.7561V24.5854Z" fill="#FDFDFD" />
  <path d="M3.5122 24.5854H1.7561V26.3415H3.5122V24.5854Z" fill="#FDFDFD" />
  <path d="M5.26829 24.5854H3.5122V26.3415H5.26829V24.5854Z" fill="#FDFDFD" />
  <path d="M7.02439 24.5854H5.26829V26.3415H7.02439V24.5854Z" fill="#FDFDFD" />
  <path d="M8.78049 24.5854H7.02439V26.3415H8.78049V24.5854Z" fill="#FDFDFD" />
  <path d="M12.2927 25.4634C12.2927 24.9785 11.8996 24.5854 11.4146 24.5854H10.5366V26.3415H11.4146C11.8996 26.3415 12.2927 25.9483 12.2927 25.4634Z" fill="#FDFDFD" />
  <path d="M15.8049 24.5854H14.0488V26.3415H15.8049V24.5854Z" fill="#FDFDFD" />
  <path d="M17.561 25.5854C17.561 25.0331 17.1133 24.5854 16.561 24.5854H15.8049V26.3415H17.561V25.5854Z" fill="#FDFDFD" />
  <path d="M21.0732 24.5854H20.3171C19.7648 24.5854 19.3171 25.0331 19.3171 25.5854V26.3415H21.0732V24.5854Z" fill="#FDFDFD" />
  <path d="M22.8293 24.5854H21.0732V26.3415H22.8293V24.5854Z" fill="#FDFDFD" />
  <path d="M24.5854 24.5854H22.8293V26.3415H24.5854V24.5854Z" fill="#FDFDFD" />
  <path d="M26.3415 24.5854H24.5854V26.3415H26.3415V24.5854Z" fill="#FDFDFD" />
  <path d="M29.8537 24.5854H28.0976V26.3415H29.8537V24.5854Z" fill="#FDFDFD" />
  <path d="M36.8781 24.5854H35.122V26.3415H36.8781V24.5854Z" fill="#FDFDFD" />
  <path d="M38.6341 24.5854H36.8781V26.3415H38.6341V24.5854Z" fill="#FDFDFD" />
  <path d="M40.3902 25.5854C40.3902 25.0331 39.9425 24.5854 39.3902 24.5854H38.6341V26.3415H40.3902V25.5854Z" fill="#FDFDFD" />
  <path d="M43.9024 24.5854H42.1463V26.3415H43.9024V24.5854Z" fill="#FDFDFD" />
  <path d="M45.6585 24.5854H43.9024V26.3415H45.6585V24.5854Z" fill="#FDFDFD" />
  <path d="M49.1707 24.5854H47.4146V26.3415H49.1707V24.5854Z" fill="#FDFDFD" />
  <path d="M50.9268 24.5854H49.1707V26.3415H50.9268V24.5854Z" fill="#FDFDFD" />
  <path d="M52.6829 24.5854H50.9268V26.3415H52.6829V24.5854Z" fill="#FDFDFD" />
  <path d="M57.9512 24.5854H56.1951V26.3415H57.9512V24.5854Z" fill="#FDFDFD" />
  <path d="M59.7073 24.5854H57.9512V26.3415H58.7073C59.2596 26.3415 59.7073 25.8937 59.7073 25.3415V24.5854Z" fill="#FDFDFD" />
  <path d="M64.9756 24.5854H63.2195V26.3415H64.9756V24.5854Z" fill="#FDFDFD" />
  <path d="M68.4878 24.5854H66.7317V26.3415H68.4878V24.5854Z" fill="#FDFDFD" />
  <path d="M70.2439 24.5854H68.4878V26.3415H70.2439V24.5854Z" fill="#FDFDFD" />
  <path d="M72 24.5854H70.2439V26.3415H71C71.5523 26.3415 72 25.8937 72 25.3415V24.5854Z" fill="#FDFDFD" />
  <path d="M1.7561 26.3415H0V27.0976C0 27.6498 0.447716 28.0976 1 28.0976H1.7561V26.3415Z" fill="#FDFDFD" />
  <path d="M3.5122 26.3415H1.7561V28.0976H3.5122V26.3415Z" fill="#FDFDFD" />
  <path d="M5.26829 26.3415H3.5122V28.0976H5.26829V26.3415Z" fill="#FDFDFD" />
  <path d="M7.02439 26.3415H5.26829V28.0976H7.02439V26.3415Z" fill="#FDFDFD" />
  <path d="M8.78049 26.3415H7.02439V28.0976H8.78049V26.3415Z" fill="#FDFDFD" />
  <path d="M10.5366 26.3415H8.78049V28.0976H10.5366V26.3415Z" fill="#FDFDFD" />
  <path d="M15.8049 26.3415H14.0488V28.0976H15.8049V26.3415Z" fill="#FDFDFD" />
  <path d="M17.561 26.3415H15.8049V28.0976H17.561V26.3415Z" fill="#FDFDFD" />
  <path d="M19.3171 26.3415H17.561V28.0976H19.3171V26.3415Z" fill="#FDFDFD" />
  <path d="M22.8293 26.3415H21.0732V28.0976H22.8293V26.3415Z" fill="#FDFDFD" />
  <path d="M24.5854 26.3415H22.8293V28.0976H24.5854V26.3415Z" fill="#FDFDFD" />
  <path d="M26.3415 26.3415H24.5854V28.0976H26.3415V26.3415Z" fill="#FDFDFD" />
  <path d="M29.8537 26.3415H28.0976V28.0976H29.8537V26.3415Z" fill="#FDFDFD" />
  <path d="M35.122 26.3415H34.3659C33.8136 26.3415 33.3659 26.7892 33.3659 27.3415V28.0976H35.122V26.3415Z" fill="#FDFDFD" />
  <path d="M38.6341 26.3415H36.8781V27.0976C36.8781 27.6498 37.3258 28.0976 37.8781 28.0976H38.6341V26.3415Z" fill="#FDFDFD" />
  <path d="M40.3902 26.3415H38.6341V28.0976H39.3902C39.9425 28.0976 40.3902 27.6498 40.3902 27.0976V26.3415Z" fill="#FDFDFD" />
  <path d="M43.9024 26.3415H42.1463V28.0976H43.9024V26.3415Z" fill="#FDFDFD" />
  <path d="M45.6585 26.3415H43.9024V28.0976H45.6585V26.3415Z" fill="#FDFDFD" />
  <path d="M47.4146 26.3415H45.6585V28.0976H47.4146V26.3415Z" fill="#FDFDFD" />
  <path d="M49.1707 26.3415H47.4146V28.0976H49.1707V26.3415Z" fill="#FDFDFD" />
  <path d="M50.9268 26.3415H49.1707V28.0976H49.9268C50.4791 28.0976 50.9268 27.6498 50.9268 27.0976V26.3415Z" fill="#FDFDFD" />
  <path d="M54.439 26.3415H52.6829V28.0976H54.439V26.3415Z" fill="#FDFDFD" />
  <path d="M56.1951 26.3415H54.439V28.0976H56.1951V26.3415Z" fill="#FDFDFD" />
  <path d="M63.2195 26.3415H62.4634C61.9111 26.3415 61.4634 26.7892 61.4634 27.3415V28.0976H63.2195V26.3415Z" fill="#FDFDFD" />
  <path d="M66.7317 26.3415H64.9756V28.0976H66.7317V26.3415Z" fill="#FDFDFD" />
  <path d="M68.4878 26.3415H66.7317V28.0976H68.4878V26.3415Z" fill="#FDFDFD" />
  <path d="M70.2439 26.3415H68.4878V28.0976H69.2439C69.7962 28.0976 70.2439 27.6498 70.2439 27.0976V26.3415Z" fill="#FDFDFD" />
  <path d="M5.26829 28.0976H3.5122V29.8537H5.26829V28.0976Z" fill="#FDFDFD" />
  <path d="M7.02439 28.0976H5.26829V29.8537H7.02439V28.0976Z" fill="#FDFDFD" />
  <path d="M8.78049 28.0976H7.02439V29.8537H8.78049V28.0976Z" fill="#FDFDFD" />
  <path d="M10.5366 28.0976H8.78049V29.8537H10.5366V28.0976Z" fill="#FDFDFD" />
  <path d="M12.2927 28.0976H10.5366V29.8537H12.2927V28.0976Z" fill="#FDFDFD" />
  <path d="M14.0488 28.0976H12.2927V29.8537H14.0488V28.0976Z" fill="#FDFDFD" />
  <path d="M15.8049 28.0976H14.0488V29.8537H15.8049V28.0976Z" fill="#FDFDFD" />
  <path d="M21.0732 28.0976H19.3171V28.8537C19.3171 29.4059 19.7648 29.8537 20.3171 29.8537H21.0732V28.0976Z" fill="#FDFDFD" />
  <path d="M22.8293 28.0976H21.0732V29.8537H22.8293V28.0976Z" fill="#FDFDFD" />
  <path d="M26.3415 28.0976H24.5854V29.8537H26.3415V28.0976Z" fill="#FDFDFD" />
  <path d="M29.8537 28.0976H28.0976V29.8537H29.8537V28.0976Z" fill="#FDFDFD" />
  <path d="M31.6098 28.0976H29.8537V29.8537H31.6098V28.0976Z" fill="#FDFDFD" />
  <path d="M33.3659 28.0976H31.6098V29.8537H33.3659V28.0976Z" fill="#FDFDFD" />
  <path d="M35.122 28.0976H33.3659V29.8537H35.122V28.0976Z" fill="#FDFDFD" />
  <path d="M43.9024 28.0976H42.1463V29.8537H43.9024V28.0976Z" fill="#FDFDFD" />
  <path d="M45.6585 28.0976H43.9024V29.8537H45.6585V28.0976Z" fill="#FDFDFD" />
  <path d="M47.4146 28.0976H45.6585V29.8537H47.4146V28.0976Z" fill="#FDFDFD" />
  <path d="M49.1707 28.0976H47.4146V29.8537H49.1707V28.0976Z" fill="#FDFDFD" />
  <path d="M54.439 28.0976H52.6829V29.8537H54.439V28.0976Z" fill="#FDFDFD" />
  <path d="M56.1951 28.0976H54.439V29.8537H56.1951V28.0976Z" fill="#FDFDFD" />
  <path d="M61.4634 28.0976H60.7073C60.155 28.0976 59.7073 28.5453 59.7073 29.0976V29.8537H60.4634C61.0157 29.8537 61.4634 29.4059 61.4634 28.8537V28.0976Z" fill="#FDFDFD" />
  <path d="M64.9756 28.0976H63.2195V28.8537C63.2195 29.4059 63.6672 29.8537 64.2195 29.8537H64.9756V28.0976Z" fill="#FDFDFD" />
  <path d="M66.7317 28.0976H64.9756V29.8537H66.7317V28.0976Z" fill="#FDFDFD" />
  <path d="M3.5122 29.8537H2.63415C2.14921 29.8537 1.7561 30.2468 1.7561 30.7317C1.7561 31.2166 2.14921 31.6098 2.63415 31.6098H3.5122V29.8537Z" fill="#FDFDFD" />
  <path d="M5.26829 29.8537H3.5122V31.6098H5.26829V29.8537Z" fill="#FDFDFD" />
  <path d="M7.02439 29.8537H5.26829V31.6098H6.02439C6.57667 31.6098 7.02439 31.162 7.02439 30.6098V29.8537Z" fill="#FDFDFD" />
  <path d="M14.0488 29.8537H12.2927V31.6098H14.0488V29.8537Z" fill="#FDFDFD" />
  <path d="M17.561 30.7317C17.561 30.2468 17.1679 29.8537 16.6829 29.8537H15.8049V30.7317C15.8049 31.2166 16.198 31.6098 16.6829 31.6098C17.1679 31.6098 17.561 31.2166 17.561 30.7317Z" fill="#FDFDFD" />
  <path d="M22.8293 29.8537H21.0732V31.6098H22.8293V29.8537Z" fill="#FDFDFD" />
  <path d="M24.5854 29.8537H22.8293V31.6098H24.5854V29.8537Z" fill="#FDFDFD" />
  <path d="M26.3415 29.8537H24.5854V31.6098H26.3415V29.8537Z" fill="#FDFDFD" />
  <path d="M28.0976 29.8537H26.3415V31.6098H28.0976V29.8537Z" fill="#FDFDFD" />
  <path d="M29.8537 29.8537H28.0976V31.6098H29.8537V29.8537Z" fill="#FDFDFD" />
  <path d="M31.6098 29.8537H29.8537V31.6098H31.6098V29.8537Z" fill="#FDFDFD" />
  <path d="M33.3659 29.8537H31.6098V31.6098H32.3659C32.9181 31.6098 33.3659 31.162 33.3659 30.6098V29.8537Z" fill="#FDFDFD" />
  <path d="M36.8781 29.8537H35.122V30.6098C35.122 31.162 35.5697 31.6098 36.122 31.6098H36.8781V29.8537Z" fill="#FDFDFD" />
  <path d="M38.6341 29.8537H36.8781V31.6098H38.6341V29.8537Z" fill="#FDFDFD" />
  <path d="M40.3902 30.8537C40.3902 30.3014 39.9425 29.8537 39.3902 29.8537H38.6341V31.6098H40.3902V30.8537Z" fill="#FDFDFD" />
  <path d="M43.9024 29.8537H42.1463V30.6098C42.1463 31.162 42.5941 31.6098 43.1463 31.6098H43.9024V29.8537Z" fill="#FDFDFD" />
  <path d="M45.6585 29.8537H43.9024V31.6098H45.6585V29.8537Z" fill="#FDFDFD" />
  <path d="M47.4146 29.8537H45.6585V31.6098H47.4146V29.8537Z" fill="#FDFDFD" />
  <path d="M49.1707 29.8537H47.4146V31.6098H49.1707V29.8537Z" fill="#FDFDFD" />
  <path d="M50.9268 30.7317C50.9268 30.2468 50.5337 29.8537 50.0488 29.8537H49.1707V31.6098H50.0488C50.5337 31.6098 50.9268 31.2166 50.9268 30.7317Z" fill="#FDFDFD" />
  <path d="M54.439 29.8537H52.6829V31.6098H54.439V29.8537Z" fill="#FDFDFD" />
  <path d="M56.1951 29.8537H54.439V31.6098H56.1951V29.8537Z" fill="#FDFDFD" />
  <path d="M59.7073 29.8537H58.9512C58.3989 29.8537 57.9512 30.3014 57.9512 30.8537V31.6098H59.7073V29.8537Z" fill="#FDFDFD" />
  <path d="M66.7317 29.8537H64.9756V31.6098H66.7317V29.8537Z" fill="#FDFDFD" />
  <path d="M68.4878 30.8537C68.4878 30.3014 68.0401 29.8537 67.4878 29.8537H66.7317V31.6098H68.4878V30.8537Z" fill="#FDFDFD" />
  <path d="M10.5366 31.6098H9.78049C9.2282 31.6098 8.78049 32.0575 8.78049 32.6098V33.3659H10.5366V31.6098Z" fill="#FDFDFD" />
  <path d="M12.2927 31.6098H10.5366V33.3659H12.2927V31.6098Z" fill="#FDFDFD" />
  <path d="M14.0488 31.6098H12.2927V33.3659H13.0488C13.6011 33.3659 14.0488 32.9181 14.0488 32.3659V31.6098Z" fill="#FDFDFD" />
  <path d="M22.8293 31.6098H21.0732V33.3659H22.8293V31.6098Z" fill="#FDFDFD" />
  <path d="M24.5854 31.6098H22.8293V33.3659H24.5854V31.6098Z" fill="#FDFDFD" />
  <path d="M29.8537 31.6098H28.0976V32.3659C28.0976 32.9181 28.5453 33.3659 29.0976 33.3659H29.8537V31.6098Z" fill="#FDFDFD" />
  <path d="M31.6098 31.6098H29.8537V33.3659H30.6098C31.162 33.3659 31.6098 32.9181 31.6098 32.3659V31.6098Z" fill="#FDFDFD" />
  <path d="M40.3902 31.6098H38.6341V33.3659H40.3902V31.6098Z" fill="#FDFDFD" />
  <path d="M54.439 31.6098H52.6829V33.3659H53.439C53.9913 33.3659 54.439 32.9181 54.439 32.3659V31.6098Z" fill="#FDFDFD" />
  <path d="M57.9512 31.6098H56.1951V32.3659C56.1951 32.9181 56.6428 33.3659 57.1951 33.3659H57.9512V31.6098Z" fill="#FDFDFD" />
  <path d="M59.7073 31.6098H57.9512V33.3659H59.7073V31.6098Z" fill="#FDFDFD" />
  <path d="M61.4634 31.6098H59.7073V33.3659H61.4634V31.6098Z" fill="#FDFDFD" />
  <path d="M63.2195 31.6098H61.4634V33.3659H63.2195V31.6098Z" fill="#FDFDFD" />
  <path d="M64.9756 31.6098H63.2195V33.3659H64.9756V31.6098Z" fill="#FDFDFD" />
  <path d="M66.7317 31.6098H64.9756V33.3659H66.7317V31.6098Z" fill="#FDFDFD" />
  <path d="M70.2439 31.6098H68.4878V32.3659C68.4878 32.9181 68.9355 33.3659 69.4878 33.3659H70.2439V31.6098Z" fill="#FDFDFD" />
  <path d="M72 32.6098C72 32.0575 71.5523 31.6098 71 31.6098H70.2439V33.3659H72V32.6098Z" fill="#FDFDFD" />
  <path d="M1.7561 34.2439C1.7561 33.759 1.36298 33.3659 0.878049 33.3659C0.393116 33.3659 0 33.759 0 34.2439V35.122H1.7561V34.2439Z" fill="#FDFDFD" />
  <path d="M5.26829 33.3659H4.5122C3.95991 33.3659 3.5122 33.8136 3.5122 34.3659V35.122H5.26829V33.3659Z" fill="#FDFDFD" />
  <path d="M7.02439 34.2439C7.02439 33.759 6.63128 33.3659 6.14634 33.3659H5.26829V35.122H6.14634C6.63127 35.122 7.02439 34.7288 7.02439 34.2439Z" fill="#FDFDFD" />
  <path d="M10.5366 33.3659H8.78049V35.122H10.5366V33.3659Z" fill="#FDFDFD" />
  <path d="M19.3171 33.3659H18.561C18.0087 33.3659 17.561 33.8136 17.561 34.3659V35.122H19.3171V33.3659Z" fill="#FDFDFD" />
  <path d="M21.0732 33.3659H19.3171V35.122H21.0732V33.3659Z" fill="#FDFDFD" />
  <path d="M26.3415 34.3659C26.3415 33.8136 25.8937 33.3659 25.3415 33.3659H24.5854V35.122H26.3415V34.3659Z" fill="#FDFDFD" />
  <path d="M35.122 34.2439C35.122 33.759 34.7288 33.3659 34.2439 33.3659C33.759 33.3659 33.3659 33.759 33.3659 34.2439C33.3659 34.7288 33.759 35.122 34.2439 35.122C34.7288 35.122 35.122 34.7288 35.122 34.2439Z" fill="#FDFDFD" />
  <path d="M40.3902 33.3659H38.6341V35.122H40.3902V33.3659Z" fill="#FDFDFD" />
  <path d="M42.1463 34.3659C42.1463 33.8136 41.6986 33.3659 41.1463 33.3659H40.3902V35.122H42.1463V34.3659Z" fill="#FDFDFD" />
  <path d="M45.6585 34.2439C45.6585 33.759 45.2654 33.3659 44.7805 33.3659C44.2956 33.3659 43.9024 33.759 43.9024 34.2439V35.122H45.6585V34.2439Z" fill="#FDFDFD" />
  <path d="M52.6829 33.3659H51.9268C51.3745 33.3659 50.9268 33.8136 50.9268 34.3659V35.122H52.6829V33.3659Z" fill="#FDFDFD" />
  <path d="M61.4634 33.3659H59.7073V35.122H61.4634V33.3659Z" fill="#FDFDFD" />
  <path d="M63.2195 33.3659H61.4634V35.122H63.2195V33.3659Z" fill="#FDFDFD" />
  <path d="M64.9756 33.3659H63.2195V35.122H64.9756V33.3659Z" fill="#FDFDFD" />
  <path d="M66.7317 33.3659H64.9756V35.122H65.7317C66.284 35.122 66.7317 34.6742 66.7317 34.122V33.3659Z" fill="#FDFDFD" />
  <path d="M72 33.3659H70.2439V35.122H72V33.3659Z" fill="#FDFDFD" />
  <path d="M1.7561 35.122H0V36.8781H1.7561V35.122Z" fill="#FDFDFD" />
  <path d="M5.26829 35.122H3.5122V36.8781H5.26829V35.122Z" fill="#FDFDFD" />
  <path d="M10.5366 35.122H8.78049V36.8781H10.5366V35.122Z" fill="#FDFDFD" />
  <path d="M12.2927 35.122H10.5366V36.8781H12.2927V35.122Z" fill="#FDFDFD" />
  <path d="M14.0488 36.122C14.0488 35.5697 13.6011 35.122 13.0488 35.122H12.2927V36.8781H14.0488V36.122Z" fill="#FDFDFD" />
  <path d="M17.561 35.122H16.8049C16.2526 35.122 15.8049 35.5697 15.8049 36.122V36.8781H17.561V35.122Z" fill="#FDFDFD" />
  <path d="M19.3171 35.122H17.561V36.8781H19.3171V35.122Z" fill="#FDFDFD" />
  <path d="M22.8293 35.122H21.0732V36.8781H22.8293V35.122Z" fill="#FDFDFD" />
  <path d="M24.5854 35.122H22.8293V36.8781H24.5854V35.122Z" fill="#FDFDFD" />
  <path d="M28.0976 35.122H26.3415V36.8781H28.0976V35.122Z" fill="#FDFDFD" />
  <path d="M29.8537 36.122C29.8537 35.5697 29.4059 35.122 28.8537 35.122H28.0976V36.8781H29.8537V36.122Z" fill="#FDFDFD" />
  <path d="M38.6341 35.122H37.8781C37.3258 35.122 36.8781 35.5697 36.8781 36.122V36.8781H38.6341V35.122Z" fill="#FDFDFD" />
  <path d="M40.3902 35.122H38.6341V36.8781H40.3902V35.122Z" fill="#FDFDFD" />
  <path d="M43.9024 35.122H42.1463V36.8781H43.9024V35.122Z" fill="#FDFDFD" />
  <path d="M45.6585 35.122H43.9024V36.8781H45.6585V35.122Z" fill="#FDFDFD" />
  <path d="M47.4146 35.122H45.6585V36.8781H47.4146V35.122Z" fill="#FDFDFD" />
  <path d="M49.1707 36.122C49.1707 35.5697 48.723 35.122 48.1707 35.122H47.4146V36.8781H49.1707V36.122Z" fill="#FDFDFD" />
  <path d="M52.6829 35.122H50.9268V36.8781H52.6829V35.122Z" fill="#FDFDFD" />
  <path d="M59.7073 35.122H58.9512C58.3989 35.122 57.9512 35.5697 57.9512 36.122V36.8781H59.7073V35.122Z" fill="#FDFDFD" />
  <path d="M61.4634 35.122H59.7073V36.8781H61.4634V35.122Z" fill="#FDFDFD" />
  <path d="M63.2195 35.122H61.4634V36.8781H62.2195C62.7718 36.8781 63.2195 36.4303 63.2195 35.8781V35.122Z" fill="#FDFDFD" />
  <path d="M72 35.122H70.2439V36.8781H72V35.122Z" fill="#FDFDFD" />
  <path d="M1.7561 36.8781H0V37.7561C0 38.241 0.393116 38.6341 0.878049 38.6341C1.36298 38.6341 1.7561 38.241 1.7561 37.7561V36.8781Z" fill="#FDFDFD" />
  <path d="M5.26829 36.8781H3.5122V38.6341H5.26829V36.8781Z" fill="#FDFDFD" />
  <path d="M7.02439 37.8781C7.02439 37.3258 6.57668 36.8781 6.02439 36.8781H5.26829V38.6341H7.02439V37.8781Z" fill="#FDFDFD" />
  <path d="M10.5366 36.8781H8.78049V38.6341H10.5366V36.8781Z" fill="#FDFDFD" />
  <path d="M14.0488 36.8781H12.2927V38.6341H14.0488V36.8781Z" fill="#FDFDFD" />
  <path d="M15.8049 36.8781H14.0488V38.6341H15.8049V36.8781Z" fill="#FDFDFD" />
  <path d="M17.561 36.8781H15.8049V38.6341H17.561V36.8781Z" fill="#FDFDFD" />
  <path d="M21.0732 36.8781H19.3171V38.6341H21.0732V36.8781Z" fill="#FDFDFD" />
  <path d="M22.8293 36.8781H21.0732V38.6341H22.8293V36.8781Z" fill="#FDFDFD" />
  <path d="M24.5854 36.8781H22.8293V38.6341H24.5854V36.8781Z" fill="#FDFDFD" />
  <path d="M26.3415 36.8781H24.5854V38.6341H26.3415V36.8781Z" fill="#FDFDFD" />
  <path d="M28.0976 36.8781H26.3415V38.6341H28.0976V36.8781Z" fill="#FDFDFD" />
  <path d="M29.8537 36.8781H28.0976V38.6341H29.8537V36.8781Z" fill="#FDFDFD" />
  <path d="M31.6098 37.8781C31.6098 37.3258 31.162 36.8781 30.6098 36.8781H29.8537V38.6341H31.6098V37.8781Z" fill="#FDFDFD" />
  <path d="M35.122 37.7561C35.122 37.2712 34.7288 36.8781 34.2439 36.8781C33.759 36.8781 33.3659 37.2712 33.3659 37.7561V38.6341H35.122V37.7561Z" fill="#FDFDFD" />
  <path d="M38.6341 36.8781H36.8781V38.6341H38.6341V36.8781Z" fill="#FDFDFD" />
  <path d="M40.3902 36.8781H38.6341V38.6341H40.3902V36.8781Z" fill="#FDFDFD" />
  <path d="M42.1463 36.8781H40.3902V38.6341H42.1463V36.8781Z" fill="#FDFDFD" />
  <path d="M43.9024 36.8781H42.1463V38.6341H43.9024V36.8781Z" fill="#FDFDFD" />
  <path d="M45.6585 36.8781H43.9024V38.6341H45.6585V36.8781Z" fill="#FDFDFD" />
  <path d="M49.1707 36.8781H47.4146V38.6341H49.1707V36.8781Z" fill="#FDFDFD" />
  <path d="M50.9268 36.8781H49.1707V38.6341H50.9268V36.8781Z" fill="#FDFDFD" />
  <path d="M52.6829 36.8781H50.9268V38.6341H51.6829C52.2352 38.6341 52.6829 38.1864 52.6829 37.6341V36.8781Z" fill="#FDFDFD" />
  <path d="M56.1951 36.8781H55.3171C54.8321 36.8781 54.439 37.2712 54.439 37.7561C54.439 38.241 54.8321 38.6341 55.3171 38.6341H56.1951V36.8781Z" fill="#FDFDFD" />
  <path d="M57.9512 36.8781H56.1951V38.6341H57.9512V36.8781Z" fill="#FDFDFD" />
  <path d="M61.4634 36.8781H59.7073V38.6341H61.4634V36.8781Z" fill="#FDFDFD" />
  <path d="M66.7317 36.8781H65.9756C65.4233 36.8781 64.9756 37.3258 64.9756 37.8781V38.6341H66.7317V36.8781Z" fill="#FDFDFD" />
  <path d="M68.4878 36.8781H66.7317V38.6341H68.4878V36.8781Z" fill="#FDFDFD" />
  <path d="M70.2439 36.8781H68.4878V38.6341H70.2439V36.8781Z" fill="#FDFDFD" />
  <path d="M72 36.8781H70.2439V38.6341H71C71.5523 38.6341 72 38.1864 72 37.6341V36.8781Z" fill="#FDFDFD" />
  <path d="M5.26829 38.6341H3.5122V40.3902H5.26829V38.6341Z" fill="#FDFDFD" />
  <path d="M7.02439 38.6341H5.26829V40.3902H7.02439V38.6341Z" fill="#FDFDFD" />
  <path d="M10.5366 38.6341H8.78049V40.3902H10.5366V38.6341Z" fill="#FDFDFD" />
  <path d="M12.2927 38.6341H10.5366V40.3902H11.2927C11.845 40.3902 12.2927 39.9425 12.2927 39.3902V38.6341Z" fill="#FDFDFD" />
  <path d="M17.561 38.6341H15.8049V40.3902H17.561V38.6341Z" fill="#FDFDFD" />
  <path d="M19.3171 38.6341H17.561V40.3902H19.3171V38.6341Z" fill="#FDFDFD" />
  <path d="M24.5854 38.6341H22.8293V40.3902H24.5854V38.6341Z" fill="#FDFDFD" />
  <path d="M26.3415 38.6341H24.5854V40.3902H26.3415V38.6341Z" fill="#FDFDFD" />
  <path d="M31.6098 38.6341H29.8537V40.3902H31.6098V38.6341Z" fill="#FDFDFD" />
  <path d="M33.3659 38.6341H31.6098V40.3902H32.3659C32.9181 40.3902 33.3659 39.9425 33.3659 39.3902V38.6341Z" fill="#FDFDFD" />
  <path d="M36.8781 38.6341H35.122V40.3902H36.8781V38.6341Z" fill="#FDFDFD" />
  <path d="M38.6341 38.6341H36.8781V40.3902H37.6341C38.1864 40.3902 38.6341 39.9425 38.6341 39.3902V38.6341Z" fill="#FDFDFD" />
  <path d="M45.6585 38.6341H43.9024V40.3902H45.6585V38.6341Z" fill="#FDFDFD" />
  <path d="M47.4146 38.6341H45.6585V40.3902H47.4146V38.6341Z" fill="#FDFDFD" />
  <path d="M49.1707 38.6341H47.4146V40.3902H49.1707V38.6341Z" fill="#FDFDFD" />
  <path d="M50.9268 38.6341H49.1707V40.3902H50.9268V38.6341Z" fill="#FDFDFD" />
  <path d="M57.9512 38.6341H56.1951V39.3902C56.1951 39.9425 56.6428 40.3902 57.1951 40.3902H57.9512V38.6341Z" fill="#FDFDFD" />
  <path d="M59.7073 38.6341H57.9512V40.3902H59.7073V38.6341Z" fill="#FDFDFD" />
  <path d="M61.4634 38.6341H59.7073V40.3902H60.4634C61.0157 40.3902 61.4634 39.9425 61.4634 39.3902V38.6341Z" fill="#FDFDFD" />
  <path d="M66.7317 38.6341H64.9756V39.3902C64.9756 39.9425 65.4233 40.3902 65.9756 40.3902H66.7317V38.6341Z" fill="#FDFDFD" />
  <path d="M1.7561 40.3902H1C0.447715 40.3902 0 40.838 0 41.3902V42.1463H1.7561V40.3902Z" fill="#FDFDFD" />
  <path d="M3.5122 40.3902H1.7561V42.1463H3.5122V40.3902Z" fill="#FDFDFD" />
  <path d="M5.26829 40.3902H3.5122V42.1463H5.26829V40.3902Z" fill="#FDFDFD" />
  <path d="M7.02439 40.3902H5.26829V42.1463H7.02439V40.3902Z" fill="#FDFDFD" />
  <path d="M8.78049 40.3902H7.02439V42.1463H8.78049V40.3902Z" fill="#FDFDFD" />
  <path d="M15.8049 40.3902H14.9268C14.4419 40.3902 14.0488 40.7834 14.0488 41.2683C14.0488 41.7532 14.4419 42.1463 14.9268 42.1463H15.8049V40.3902Z" fill="#FDFDFD" />
  <path d="M21.0732 41.2683C21.0732 40.7834 20.6801 40.3902 20.1951 40.3902H19.3171V41.2683C19.3171 41.7532 19.7102 42.1463 20.1951 42.1463C20.6801 42.1463 21.0732 41.7532 21.0732 41.2683Z" fill="#FDFDFD" />
  <path d="M24.5854 40.3902H22.8293V41.1463C22.8293 41.6986 23.277 42.1463 23.8293 42.1463H24.5854V40.3902Z" fill="#FDFDFD" />
  <path d="M26.3415 40.3902H24.5854V42.1463H26.3415V40.3902Z" fill="#FDFDFD" />
  <path d="M28.0976 41.2683C28.0976 40.7834 27.7044 40.3902 27.2195 40.3902H26.3415V42.1463H27.2195C27.7044 42.1463 28.0976 41.7532 28.0976 41.2683Z" fill="#FDFDFD" />
  <path d="M31.6098 40.3902H29.8537V41.2683C29.8537 41.7532 30.2468 42.1463 30.7317 42.1463C31.2166 42.1463 31.6098 41.7532 31.6098 41.2683V40.3902Z" fill="#FDFDFD" />
  <path d="M36.8781 40.3902H35.122V42.1463H36.8781V40.3902Z" fill="#FDFDFD" />
  <path d="M43.9024 40.3902H43.0244C42.5395 40.3902 42.1463 40.7834 42.1463 41.2683C42.1463 41.7532 42.5395 42.1463 43.0244 42.1463H43.9024V40.3902Z" fill="#FDFDFD" />
  <path d="M47.4146 40.3902H45.6585V42.1463H47.4146V40.3902Z" fill="#FDFDFD" />
  <path d="M50.9268 40.3902H49.1707V42.1463H50.9268V40.3902Z" fill="#FDFDFD" />
  <path d="M54.439 41.2683C54.439 40.7834 54.0459 40.3902 53.561 40.3902C53.076 40.3902 52.6829 40.7834 52.6829 41.2683V42.1463H54.439V41.2683Z" fill="#FDFDFD" />
  <path d="M68.4878 40.3902H66.7317V41.1463C66.7317 41.6986 67.1794 42.1463 67.7317 42.1463H68.4878V40.3902Z" fill="#FDFDFD" />
  <path d="M70.2439 41.3902C70.2439 40.838 69.7962 40.3902 69.2439 40.3902H68.4878V42.1463H70.2439V41.3902Z" fill="#FDFDFD" />
  <path d="M1.7561 42.1463H0V42.9024C0 43.4547 0.447716 43.9024 1 43.9024H1.7561V42.1463Z" fill="#FDFDFD" />
  <path d="M3.5122 42.1463H1.7561V43.9024H3.5122V42.1463Z" fill="#FDFDFD" />
  <path d="M5.26829 42.1463H3.5122V43.9024H5.26829V42.1463Z" fill="#FDFDFD" />
  <path d="M7.02439 42.1463H5.26829V43.9024H7.02439V42.1463Z" fill="#FDFDFD" />
  <path d="M8.78049 42.1463H7.02439V43.9024H8.78049V42.1463Z" fill="#FDFDFD" />
  <path d="M10.5366 42.1463H8.78049V43.9024H10.5366V42.1463Z" fill="#FDFDFD" />
  <path d="M12.2927 43.0244C12.2927 42.5395 11.8996 42.1463 11.4146 42.1463H10.5366V43.9024H11.4146C11.8996 43.9024 12.2927 43.5093 12.2927 43.0244Z" fill="#FDFDFD" />
  <path d="M17.561 43.1463C17.561 42.5941 17.1133 42.1463 16.561 42.1463H15.8049V43.9024H17.561V43.1463Z" fill="#FDFDFD" />
  <path d="M35.122 42.1463H34.3659C33.8136 42.1463 33.3659 42.5941 33.3659 43.1463V43.9024H35.122V42.1463Z" fill="#FDFDFD" />
  <path d="M36.8781 42.1463H35.122V43.9024H36.8781V42.1463Z" fill="#FDFDFD" />
  <path d="M38.6341 43.1463C38.6341 42.5941 38.1864 42.1463 37.6341 42.1463H36.8781V43.9024H38.6341V43.1463Z" fill="#FDFDFD" />
  <path d="M45.6585 42.1463H43.9024V43.9024H45.6585V42.1463Z" fill="#FDFDFD" />
  <path d="M47.4146 42.1463H45.6585V43.9024H47.4146V42.1463Z" fill="#FDFDFD" />
  <path d="M49.1707 42.1463H47.4146V43.9024H49.1707V42.1463Z" fill="#FDFDFD" />
  <path d="M50.9268 42.1463H49.1707V43.9024H50.9268V42.1463Z" fill="#FDFDFD" />
  <path d="M52.6829 42.1463H50.9268V43.9024H52.6829V42.1463Z" fill="#FDFDFD" />
  <path d="M54.439 42.1463H52.6829V43.9024H54.439V42.1463Z" fill="#FDFDFD" />
  <path d="M56.1951 43.1463C56.1951 42.5941 55.7474 42.1463 55.1951 42.1463H54.439V43.9024H56.1951V43.1463Z" fill="#FDFDFD" />
  <path d="M63.2195 43.0244C63.2195 42.5395 62.8264 42.1463 62.3415 42.1463C61.8565 42.1463 61.4634 42.5395 61.4634 43.0244V43.9024H63.2195V43.0244Z" fill="#FDFDFD" />
  <path d="M72 43.0244C72 42.5395 71.6069 42.1463 71.122 42.1463H70.2439V43.9024H71.122C71.6069 43.9024 72 43.5093 72 43.0244Z" fill="#FDFDFD" />
  <path d="M3.5122 43.9024H1.7561V44.7805C1.7561 45.2654 2.14921 45.6585 2.63415 45.6585C3.11908 45.6585 3.5122 45.2654 3.5122 44.7805V43.9024Z" fill="#FDFDFD" />
  <path d="M7.02439 43.9024H5.26829V44.6585C5.26829 45.2108 5.71601 45.6585 6.26829 45.6585H7.02439V43.9024Z" fill="#FDFDFD" />
  <path d="M8.78049 43.9024H7.02439V45.6585H8.78049V43.9024Z" fill="#FDFDFD" />
  <path d="M15.8049 43.9024H15.0488C14.4965 43.9024 14.0488 44.3502 14.0488 44.9024V45.6585H15.8049V43.9024Z" fill="#FDFDFD" />
  <path d="M17.561 43.9024H15.8049V45.6585H17.561V43.9024Z" fill="#FDFDFD" />
  <path d="M19.3171 43.9024H17.561V45.6585H19.3171V43.9024Z" fill="#FDFDFD" />
  <path d="M21.0732 43.9024H19.3171V45.6585H21.0732V43.9024Z" fill="#FDFDFD" />
  <path d="M22.8293 43.9024H21.0732V45.6585H22.8293V43.9024Z" fill="#FDFDFD" />
  <path d="M24.5854 44.9024C24.5854 44.3502 24.1377 43.9024 23.5854 43.9024H22.8293V45.6585H24.5854V44.9024Z" fill="#FDFDFD" />
  <path d="M28.0976 44.7805C28.0976 44.2956 27.7044 43.9024 27.2195 43.9024C26.7346 43.9024 26.3415 44.2956 26.3415 44.7805C26.3415 45.2654 26.7346 45.6585 27.2195 45.6585C27.7044 45.6585 28.0976 45.2654 28.0976 44.7805Z" fill="#FDFDFD" />
  <path d="M33.3659 43.9024H32.6098C32.0575 43.9024 31.6098 44.3502 31.6098 44.9024V45.6585H33.3659V43.9024Z" fill="#FDFDFD" />
  <path d="M35.122 43.9024H33.3659V45.6585H35.122V43.9024Z" fill="#FDFDFD" />
  <path d="M38.6341 43.9024H36.8781V45.6585H38.6341V43.9024Z" fill="#FDFDFD" />
  <path d="M40.3902 43.9024H38.6341V45.6585H40.3902V43.9024Z" fill="#FDFDFD" />
  <path d="M42.1463 43.9024H40.3902V45.6585H42.1463V43.9024Z" fill="#FDFDFD" />
  <path d="M43.9024 43.9024H42.1463V45.6585H43.9024V43.9024Z" fill="#FDFDFD" />
  <path d="M45.6585 43.9024H43.9024V45.6585H45.6585V43.9024Z" fill="#FDFDFD" />
  <path d="M49.1707 43.9024H47.4146V45.6585H49.1707V43.9024Z" fill="#FDFDFD" />
  <path d="M52.6829 43.9024H50.9268V45.6585H52.6829V43.9024Z" fill="#FDFDFD" />
  <path d="M57.9512 43.9024H56.1951V45.6585H57.9512V43.9024Z" fill="#FDFDFD" />
  <path d="M59.7073 43.9024H57.9512V45.6585H59.7073V43.9024Z" fill="#FDFDFD" />
  <path d="M61.4634 43.9024H59.7073V45.6585H61.4634V43.9024Z" fill="#FDFDFD" />
  <path d="M63.2195 43.9024H61.4634V45.6585H63.2195V43.9024Z" fill="#FDFDFD" />
  <path d="M68.4878 43.9024H67.7317C67.1794 43.9024 66.7317 44.3502 66.7317 44.9024V45.6585H68.4878V43.9024Z" fill="#FDFDFD" />
  <path d="M70.2439 43.9024H68.4878V45.6585H70.2439V43.9024Z" fill="#FDFDFD" />
  <path d="M8.78049 45.6585H7.02439L7.02439 47.4146H8.78049V45.6585Z" fill="#FDFDFD" />
  <path d="M12.2927 46.5366C12.2927 46.0517 11.8996 45.6585 11.4146 45.6585C10.9297 45.6585 10.5366 46.0517 10.5366 46.5366V47.4146H12.2927V46.5366Z" fill="#FDFDFD" />
  <path d="M15.8049 45.6585H14.0488V47.4146H14.8049C15.3572 47.4146 15.8049 46.9669 15.8049 46.4146V45.6585Z" fill="#FDFDFD" />
  <path d="M21.0732 45.6585H19.3171V47.4146H21.0732V45.6585Z" fill="#FDFDFD" />
  <path d="M22.8293 45.6585H21.0732V47.4146H22.8293V45.6585Z" fill="#FDFDFD" />
  <path d="M24.5854 45.6585H22.8293V47.4146H23.5854C24.1377 47.4146 24.5854 46.9669 24.5854 46.4146V45.6585Z" fill="#FDFDFD" />
  <path d="M31.6098 45.6585H30.7317C30.2468 45.6585 29.8537 46.0517 29.8537 46.5366C29.8537 47.0215 30.2468 47.4146 30.7317 47.4146C31.2166 47.4146 31.6098 47.0215 31.6098 46.5366V45.6585Z" fill="#FDFDFD" />
  <path d="M36.8781 45.6585H35.122V47.4146H35.8781C36.4303 47.4146 36.8781 46.9669 36.8781 46.4146V45.6585Z" fill="#FDFDFD" />
  <path d="M45.6585 45.6585H43.9024V46.5366C43.9024 47.0215 44.2956 47.4146 44.7805 47.4146C45.2654 47.4146 45.6585 47.0215 45.6585 46.5366V45.6585Z" fill="#FDFDFD" />
  <path d="M49.1707 45.6585H47.4146V46.4146C47.4146 46.9669 47.8624 47.4146 48.4146 47.4146H49.1707V45.6585Z" fill="#FDFDFD" />
  <path d="M52.6829 45.6585H50.9268V47.4146H51.6829C52.2352 47.4146 52.6829 46.9669 52.6829 46.4146V45.6585Z" fill="#FDFDFD" />
  <path d="M56.1951 45.6585H55.439C54.8867 45.6585 54.439 46.1063 54.439 46.6585V47.4146H56.1951V45.6585Z" fill="#FDFDFD" />
  <path d="M59.7073 45.6585H57.9512V46.4146C57.9512 46.9669 58.3989 47.4146 58.9512 47.4146H59.7073V45.6585Z" fill="#FDFDFD" />
  <path d="M61.4634 45.6585H59.7073V47.4146H61.4634V45.6585Z" fill="#FDFDFD" />
  <path d="M64.9756 45.6585H63.2195V47.4146H64.9756V45.6585Z" fill="#FDFDFD" />
  <path d="M66.7317 45.6585H64.9756V47.4146H65.7317C66.284 47.4146 66.7317 46.9669 66.7317 46.4146V45.6585Z" fill="#FDFDFD" />
  <path d="M72 46.5366C72 46.0517 71.6069 45.6585 71.122 45.6585H70.2439V47.4146H71.122C71.6069 47.4146 72 47.0215 72 46.5366Z" fill="#FDFDFD" />
  <path d="M1.7561 47.4146H1C0.447715 47.4146 0 47.8624 0 48.4146V49.1707H1.7561V47.4146Z" fill="#FDFDFD" />
  <path d="M3.5122 47.4146H1.7561V49.1707H3.5122V47.4146Z" fill="#FDFDFD" />
  <path d="M5.26829 47.4146H3.5122V49.1707H5.26829V47.4146Z" fill="#FDFDFD" />
  <path d="M7.02439 47.4146H5.26829V49.1707H7.02439L7.02439 47.4146Z" fill="#FDFDFD" />
  <path d="M8.78049 47.4146H7.02439L7.02439 49.1707H8.78049V47.4146Z" fill="#FDFDFD" />
  <path d="M10.5366 47.4146H8.78049V49.1707H10.5366V47.4146Z" fill="#FDFDFD" />
  <path d="M14.0488 47.4146H12.2927V49.1707H13.0488C13.6011 49.1707 14.0488 48.723 14.0488 48.1707V47.4146Z" fill="#FDFDFD" />
  <path d="M21.0732 47.4146H19.3171V49.1707H21.0732V47.4146Z" fill="#FDFDFD" />
  <path d="M28.0976 48.2927C28.0976 47.8078 27.7044 47.4146 27.2195 47.4146C26.7346 47.4146 26.3415 47.8078 26.3415 48.2927C26.3415 48.7776 26.7346 49.1707 27.2195 49.1707H28.0976V48.2927Z" fill="#FDFDFD" />
  <path d="M35.122 47.4146H34.3659C33.8136 47.4146 33.3659 47.8624 33.3659 48.4146V49.1707H35.122V47.4146Z" fill="#FDFDFD" />
  <path d="M50.9268 47.4146H49.1707V49.1707H50.9268V47.4146Z" fill="#FDFDFD" />
  <path d="M56.1951 47.4146H54.439V49.1707H56.1951V47.4146Z" fill="#FDFDFD" />
  <path d="M63.2195 47.4146H61.4634V49.1707H63.2195V47.4146Z" fill="#FDFDFD" />
  <path d="M70.2439 47.4146H69.4878C68.9355 47.4146 68.4878 47.8624 68.4878 48.4146V49.1707H70.2439V47.4146Z" fill="#FDFDFD" />
  <path d="M1.7561 49.1707H0V50.9268H1.7561V49.1707Z" fill="#FDFDFD" />
  <path d="M3.5122 49.1707H1.7561V50.9268H3.5122V49.1707Z" fill="#FDFDFD" />
  <path d="M5.26829 49.1707H3.5122V50.9268H5.26829V49.1707Z" fill="#FDFDFD" />
  <path d="M8.78049 49.1707H7.02439V49.9268C7.02439 50.4791 7.47211 50.9268 8.02439 50.9268H8.78049V49.1707Z" fill="#FDFDFD" />
  <path d="M10.5366 49.1707H8.78049V50.9268H10.5366V49.1707Z" fill="#FDFDFD" />
  <path d="M12.2927 49.1707H10.5366V50.9268H12.2927V49.1707Z" fill="#FDFDFD" />
  <path d="M17.561 49.1707H16.8049C16.2526 49.1707 15.8049 49.6185 15.8049 50.1707V50.9268H17.561V49.1707Z" fill="#FDFDFD" />
  <path d="M19.3171 49.1707H17.561V50.9268H19.3171V49.1707Z" fill="#FDFDFD" />
  <path d="M21.0732 49.1707H19.3171V50.9268H21.0732V49.1707Z" fill="#FDFDFD" />
  <path d="M24.5854 50.0488C24.5854 49.5639 24.1923 49.1707 23.7073 49.1707C23.2224 49.1707 22.8293 49.5639 22.8293 50.0488V50.9268H24.5854V50.0488Z" fill="#FDFDFD" />
  <path d="M29.8537 50.1707C29.8537 49.6185 29.4059 49.1707 28.8537 49.1707H28.0976V50.9268H29.8537V50.1707Z" fill="#FDFDFD" />
  <path d="M35.122 49.1707H33.3659V50.9268H35.122V49.1707Z" fill="#FDFDFD" />
  <path d="M36.8781 49.1707H35.122V50.9268H36.8781V49.1707Z" fill="#FDFDFD" />
  <path d="M38.6341 50.1707C38.6341 49.6185 38.1864 49.1707 37.6341 49.1707H36.8781V50.9268H38.6341V50.1707Z" fill="#FDFDFD" />
  <path d="M43.9024 50.0488C43.9024 49.5639 43.5093 49.1707 43.0244 49.1707C42.5395 49.1707 42.1463 49.5639 42.1463 50.0488V50.9268H43.9024V50.0488Z" fill="#FDFDFD" />
  <path d="M47.4146 49.1707H46.6585C46.1063 49.1707 45.6585 49.6185 45.6585 50.1707V50.9268H47.4146V49.1707Z" fill="#FDFDFD" />
  <path d="M49.1707 49.1707H47.4146V50.9268H49.1707V49.1707Z" fill="#FDFDFD" />
  <path d="M50.9268 49.1707H49.1707V50.9268H50.9268V49.1707Z" fill="#FDFDFD" />
  <path d="M56.1951 49.1707H54.439V50.9268H56.1951V49.1707Z" fill="#FDFDFD" />
  <path d="M57.9512 49.1707H56.1951V50.9268H57.9512V49.1707Z" fill="#FDFDFD" />
  <path d="M59.7073 50.0488C59.7073 49.5639 59.3142 49.1707 58.8293 49.1707H57.9512V50.9268H58.8293C59.3142 50.9268 59.7073 50.5337 59.7073 50.0488Z" fill="#FDFDFD" />
  <path d="M63.2195 49.1707H61.4634V49.9268C61.4634 50.4791 61.9111 50.9268 62.4634 50.9268H63.2195V49.1707Z" fill="#FDFDFD" />
  <path d="M64.9756 50.1707C64.9756 49.6185 64.5279 49.1707 63.9756 49.1707H63.2195V50.9268H64.9756V50.1707Z" fill="#FDFDFD" />
  <path d="M70.2439 49.1707H68.4878V50.9268H70.2439V49.1707Z" fill="#FDFDFD" />
  <path d="M1.7561 50.9268H0V51.6829C0 52.2352 0.447716 52.6829 1 52.6829H1.7561V50.9268Z" fill="#FDFDFD" />
  <path d="M3.5122 50.9268H1.7561V52.6829H3.5122V50.9268Z" fill="#FDFDFD" />
  <path d="M5.26829 50.9268H3.5122V52.6829H5.26829V50.9268Z" fill="#FDFDFD" />
  <path d="M14.0488 51.9268C14.0488 51.3745 13.6011 50.9268 13.0488 50.9268H12.2927V52.6829H14.0488V51.9268Z" fill="#FDFDFD" />
  <path d="M17.561 50.9268H15.8049V52.6829H17.561V50.9268Z" fill="#FDFDFD" />
  <path d="M22.8293 50.9268H21.0732V52.6829H22.8293V50.9268Z" fill="#FDFDFD" />
  <path d="M24.5854 50.9268H22.8293V52.6829H23.5854C24.1377 52.6829 24.5854 52.2352 24.5854 51.6829V50.9268Z" fill="#FDFDFD" />
  <path d="M28.0976 50.9268H27.3415C26.7892 50.9268 26.3415 51.3745 26.3415 51.9268V52.6829H28.0976V50.9268Z" fill="#FDFDFD" />
  <path d="M29.8537 50.9268H28.0976V52.6829H28.8537C29.4059 52.6829 29.8537 52.2352 29.8537 51.6829V50.9268Z" fill="#FDFDFD" />
  <path d="M35.122 50.9268H33.3659V52.6829H35.122V50.9268Z" fill="#FDFDFD" />
  <path d="M38.6341 50.9268H36.8781V52.6829H38.6341V50.9268Z" fill="#FDFDFD" />
  <path d="M40.3902 51.9268C40.3902 51.3745 39.9425 50.9268 39.3902 50.9268H38.6341V52.6829H40.3902V51.9268Z" fill="#FDFDFD" />
  <path d="M43.9024 50.9268H42.1463V51.6829C42.1463 52.2352 42.5941 52.6829 43.1463 52.6829H43.9024V50.9268Z" fill="#FDFDFD" />
  <path d="M45.6585 50.9268H43.9024V52.6829H45.6585V50.9268Z" fill="#FDFDFD" />
  <path d="M47.4146 50.9268H45.6585V52.6829H47.4146V50.9268Z" fill="#FDFDFD" />
  <path d="M49.1707 50.9268H47.4146V52.6829H49.1707V50.9268Z" fill="#FDFDFD" />
  <path d="M50.9268 50.9268H49.1707V52.6829H50.9268V50.9268Z" fill="#FDFDFD" />
  <path d="M54.439 50.9268H53.6829C53.1306 50.9268 52.6829 51.3745 52.6829 51.9268V52.6829H54.439V50.9268Z" fill="#FDFDFD" />
  <path d="M56.1951 50.9268H54.439V52.6829H56.1951V50.9268Z" fill="#FDFDFD" />
  <path d="M64.9756 50.9268H63.2195V52.6829H64.9756V50.9268Z" fill="#FDFDFD" />
  <path d="M68.4878 50.9268H67.7317C67.1794 50.9268 66.7317 51.3745 66.7317 51.9268V52.6829H68.4878V50.9268Z" fill="#FDFDFD" />
  <path d="M70.2439 50.9268H68.4878V52.6829H70.2439V50.9268Z" fill="#FDFDFD" />
  <path d="M72 51.9268C72 51.3745 71.5523 50.9268 71 50.9268H70.2439V52.6829H72V51.9268Z" fill="#FDFDFD" />
  <path d="M5.26829 52.6829H3.5122V53.439C3.5122 53.9913 3.95991 54.439 4.5122 54.439H5.26829V52.6829Z" fill="#FDFDFD" />
  <path d="M10.5366 52.6829H9.78049C9.2282 52.6829 8.78049 53.1306 8.78049 53.6829V54.439H10.5366V52.6829Z" fill="#FDFDFD" />
  <path d="M12.2927 52.6829H10.5366V54.439H12.2927V52.6829Z" fill="#FDFDFD" />
  <path d="M14.0488 52.6829H12.2927V54.439H14.0488V52.6829Z" fill="#FDFDFD" />
  <path d="M17.561 52.6829H15.8049V53.439C15.8049 53.9913 16.2526 54.439 16.8049 54.439H17.561V52.6829Z" fill="#FDFDFD" />
  <path d="M19.3171 52.6829H17.561V54.439H19.3171V52.6829Z" fill="#FDFDFD" />
  <path d="M21.0732 52.6829H19.3171V54.439H21.0732V52.6829Z" fill="#FDFDFD" />
  <path d="M28.0976 52.6829H26.3415V54.439H28.0976V52.6829Z" fill="#FDFDFD" />
  <path d="M33.3659 52.6829H32.4878C32.0029 52.6829 31.6098 53.076 31.6098 53.561C31.6098 54.0459 32.0029 54.439 32.4878 54.439H33.3659V52.6829Z" fill="#FDFDFD" />
  <path d="M35.122 52.6829H33.3659V54.439H35.122V52.6829Z" fill="#FDFDFD" />
  <path d="M36.8781 52.6829H35.122V54.439H36.8781V52.6829Z" fill="#FDFDFD" />
  <path d="M38.6341 52.6829H36.8781V54.439H38.6341V52.6829Z" fill="#FDFDFD" />
  <path d="M40.3902 52.6829H38.6341V54.439H40.3902V52.6829Z" fill="#FDFDFD" />
  <path d="M47.4146 52.6829H45.6585V54.439H47.4146V52.6829Z" fill="#FDFDFD" />
  <path d="M49.1707 52.6829H47.4146V54.439H49.1707V52.6829Z" fill="#FDFDFD" />
  <path d="M50.9268 52.6829H49.1707V54.439H50.9268V52.6829Z" fill="#FDFDFD" />
  <path d="M52.6829 52.6829H50.9268V54.439H52.6829V52.6829Z" fill="#FDFDFD" />
  <path d="M54.439 52.6829H52.6829V54.439H54.439V52.6829Z" fill="#FDFDFD" />
  <path d="M56.1951 52.6829H54.439V54.439H55.1951C55.7474 54.439 56.1951 53.9913 56.1951 53.439V52.6829Z" fill="#FDFDFD" />
  <path d="M61.4634 53.561C61.4634 53.076 61.0703 52.6829 60.5854 52.6829C60.1004 52.6829 59.7073 53.076 59.7073 53.561C59.7073 54.0459 60.1004 54.439 60.5854 54.439H61.4634V53.561Z" fill="#FDFDFD" />
  <path d="M64.9756 52.6829H63.2195V54.439H64.9756V52.6829Z" fill="#FDFDFD" />
  <path d="M66.7317 52.6829H64.9756V54.439H66.7317V52.6829Z" fill="#FDFDFD" />
  <path d="M68.4878 52.6829H66.7317V54.439H68.4878V52.6829Z" fill="#FDFDFD" />
  <path d="M70.2439 52.6829H68.4878V54.439H70.2439V52.6829Z" fill="#FDFDFD" />
  <path d="M72 52.6829H70.2439V54.439H71C71.5523 54.439 72 53.9913 72 53.439V52.6829Z" fill="#FDFDFD" />
  <path d="M1.7561 55.3171C1.7561 54.8321 1.36298 54.439 0.878049 54.439C0.393116 54.439 0 54.8321 0 55.3171V56.1951H1.7561V55.3171Z" fill="#FDFDFD" />
  <path d="M7.02439 55.439C7.02439 54.8867 6.57668 54.439 6.02439 54.439H5.26829V56.1951H7.02439V55.439Z" fill="#FDFDFD" />
  <path d="M10.5366 54.439H8.78049V56.1951H10.5366V54.439Z" fill="#FDFDFD" />
  <path d="M14.0488 54.439H12.2927V56.1951H14.0488V54.439Z" fill="#FDFDFD" />
  <path d="M19.3171 54.439H17.561V56.1951H18.3171C18.8694 56.1951 19.3171 55.7474 19.3171 55.1951V54.439Z" fill="#FDFDFD" />
  <path d="M22.8293 54.439H21.0732V55.1951C21.0732 55.7474 21.5209 56.1951 22.0732 56.1951H22.8293V54.439Z" fill="#FDFDFD" />
  <path d="M24.5854 55.3171C24.5854 54.8321 24.1923 54.439 23.7073 54.439H22.8293V56.1951H23.7073C24.1923 56.1951 24.5854 55.802 24.5854 55.3171Z" fill="#FDFDFD" />
  <path d="M28.0976 54.439H26.3415V56.1951H28.0976V54.439Z" fill="#FDFDFD" />
  <path d="M29.8537 55.439C29.8537 54.8867 29.4059 54.439 28.8537 54.439H28.0976V56.1951H29.8537V55.439Z" fill="#FDFDFD" />
  <path d="M36.8781 54.439H35.122V55.1951C35.122 55.7474 35.5697 56.1951 36.122 56.1951H36.8781V54.439Z" fill="#FDFDFD" />
  <path d="M40.3902 54.439H38.6341V56.1951H40.3902V54.439Z" fill="#FDFDFD" />
  <path d="M45.6585 54.439H44.9024C44.3502 54.439 43.9024 54.8867 43.9024 55.439V56.1951H45.6585V54.439Z" fill="#FDFDFD" />
  <path d="M63.2195 54.439H61.4634V56.1951H63.2195V54.439Z" fill="#FDFDFD" />
  <path d="M66.7317 54.439H64.9756V56.1951H66.7317V54.439Z" fill="#FDFDFD" />
  <path d="M70.2439 54.439H68.4878V56.1951H70.2439V54.439Z" fill="#FDFDFD" />
  <path d="M1.7561 56.1951H0V56.9512C0 57.5035 0.447716 57.9512 1 57.9512H1.7561V56.1951Z" fill="#FDFDFD" />
  <path d="M3.5122 56.1951H1.7561V57.9512H3.5122V56.1951Z" fill="#FDFDFD" />
  <path d="M5.26829 56.1951H3.5122V57.9512H5.26829V56.1951Z" fill="#FDFDFD" />
  <path d="M7.02439 56.1951H5.26829V57.9512H7.02439V56.1951Z" fill="#FDFDFD" />
  <path d="M8.78049 56.1951H7.02439V57.9512H7.78049C8.33277 57.9512 8.78049 57.5035 8.78049 56.9512V56.1951Z" fill="#FDFDFD" />
  <path d="M12.2927 56.1951H10.5366V57.0732C10.5366 57.5581 10.9297 57.9512 11.4146 57.9512C11.8996 57.9512 12.2927 57.5581 12.2927 57.0732V56.1951Z" fill="#FDFDFD" />
  <path d="M15.8049 56.1951H14.0488V57.9512H15.8049V56.1951Z" fill="#FDFDFD" />
  <path d="M17.561 56.1951H15.8049V57.9512H16.561C17.1133 57.9512 17.561 57.5035 17.561 56.9512V56.1951Z" fill="#FDFDFD" />
  <path d="M28.0976 56.1951H26.3415V56.9512C26.3415 57.5035 26.7892 57.9512 27.3415 57.9512H28.0976V56.1951Z" fill="#FDFDFD" />
  <path d="M29.8537 56.1951H28.0976V57.9512H28.8537C29.4059 57.9512 29.8537 57.5035 29.8537 56.9512V56.1951Z" fill="#FDFDFD" />
  <path d="M33.3659 57.0732C33.3659 56.5882 32.9727 56.1951 32.4878 56.1951C32.0029 56.1951 31.6098 56.5882 31.6098 57.0732C31.6098 57.5581 32.0029 57.9512 32.4878 57.9512H33.3659V57.0732Z" fill="#FDFDFD" />
  <path d="M38.6341 56.1951H36.8781V56.9512C36.8781 57.5035 37.3258 57.9512 37.8781 57.9512H38.6341V56.1951Z" fill="#FDFDFD" />
  <path d="M40.3902 56.1951H38.6341V57.9512H39.3902C39.9425 57.9512 40.3902 57.5035 40.3902 56.9512V56.1951Z" fill="#FDFDFD" />
  <path d="M43.9024 56.1951H43.1463C42.5941 56.1951 42.1463 56.6428 42.1463 57.1951V57.9512H43.9024V56.1951Z" fill="#FDFDFD" />
  <path d="M47.4146 56.1951H45.6585V57.9512H47.4146V56.1951Z" fill="#FDFDFD" />
  <path d="M49.1707 56.1951H47.4146V57.9512H49.1707V56.1951Z" fill="#FDFDFD" />
  <path d="M50.9268 57.1951C50.9268 56.6428 50.4791 56.1951 49.9268 56.1951H49.1707V57.9512H50.9268V57.1951Z" fill="#FDFDFD" />
  <path d="M57.9512 56.1951H57.1951C56.6428 56.1951 56.1951 56.6428 56.1951 57.1951V57.9512H57.9512V56.1951Z" fill="#FDFDFD" />
  <path d="M59.7073 56.1951H57.9512V57.9512H59.7073V56.1951Z" fill="#FDFDFD" />
  <path d="M61.4634 56.1951H59.7073V57.9512H61.4634V56.1951Z" fill="#FDFDFD" />
  <path d="M63.2195 56.1951H61.4634V57.9512H63.2195V56.1951Z" fill="#FDFDFD" />
  <path d="M64.9756 56.1951H63.2195V57.9512H64.9756V56.1951Z" fill="#FDFDFD" />
  <path d="M66.7317 56.1951H64.9756V57.9512H66.7317V56.1951Z" fill="#FDFDFD" />
  <path d="M70.2439 56.1951H68.4878V56.9512C68.4878 57.5035 68.9355 57.9512 69.4878 57.9512H70.2439V56.1951Z" fill="#FDFDFD" />
  <path d="M72 57.1951C72 56.6428 71.5523 56.1951 71 56.1951H70.2439V57.9512H72V57.1951Z" fill="#FDFDFD" />
  <path d="M15.8049 57.9512H14.0488V58.7073C14.0488 59.2596 14.4965 59.7073 15.0488 59.7073H15.8049V57.9512Z" fill="#FDFDFD" />
  <path d="M21.0732 57.9512H20.1951C19.7102 57.9512 19.3171 58.3443 19.3171 58.8293C19.3171 59.3142 19.7102 59.7073 20.1951 59.7073H21.0732V57.9512Z" fill="#FDFDFD" />
  <path d="M22.8293 57.9512H21.0732V59.7073H22.8293V57.9512Z" fill="#FDFDFD" />
  <path d="M24.5854 58.9512C24.5854 58.3989 24.1377 57.9512 23.5854 57.9512H22.8293V59.7073H24.5854V58.9512Z" fill="#FDFDFD" />
  <path d="M35.122 58.9512C35.122 58.3989 34.6742 57.9512 34.122 57.9512H33.3659V59.7073H35.122V58.9512Z" fill="#FDFDFD" />
  <path d="M43.9024 57.9512H42.1463V59.7073H43.9024V57.9512Z" fill="#FDFDFD" />
  <path d="M45.6585 57.9512H43.9024V59.7073H45.6585V57.9512Z" fill="#FDFDFD" />
  <path d="M47.4146 57.9512H45.6585V59.7073H47.4146V57.9512Z" fill="#FDFDFD" />
  <path d="M49.1707 57.9512H47.4146V59.7073H49.1707V57.9512Z" fill="#FDFDFD" />
  <path d="M50.9268 57.9512H49.1707V59.7073H50.9268V57.9512Z" fill="#FDFDFD" />
  <path d="M52.6829 58.9512C52.6829 58.3989 52.2352 57.9512 51.6829 57.9512H50.9268V59.7073H52.6829V58.9512Z" fill="#FDFDFD" />
  <path d="M56.1951 57.9512H55.3171C54.8321 57.9512 54.439 58.3443 54.439 58.8293C54.439 59.3142 54.8321 59.7073 55.3171 59.7073H56.1951V57.9512Z" fill="#FDFDFD" />
  <path d="M57.9512 57.9512H56.1951V59.7073H57.9512V57.9512Z" fill="#FDFDFD" />
  <path d="M64.9756 57.9512H63.2195V59.7073H64.9756V57.9512Z" fill="#FDFDFD" />
  <path d="M66.7317 57.9512H64.9756V59.7073H66.7317V57.9512Z" fill="#FDFDFD" />
  <path d="M72 57.9512H70.2439V59.7073H72V57.9512Z" fill="#FDFDFD" />
  <path d="M1.7561 59.7073H1C0.447715 59.7073 0 60.155 0 60.7073V61.4634H1.7561V59.7073Z" fill="#FDFDFD" />
  <path d="M3.5122 59.7073H1.7561V61.4634H3.5122V59.7073Z" fill="#FDFDFD" />
  <path d="M5.26829 59.7073H3.5122V61.4634H5.26829V59.7073Z" fill="#FDFDFD" />
  <path d="M7.02439 59.7073H5.26829V61.4634H7.02439V59.7073Z" fill="#FDFDFD" />
  <path d="M8.78049 59.7073H7.02439V61.4634H8.78049V59.7073Z" fill="#FDFDFD" />
  <path d="M10.5366 59.7073H8.78049V61.4634H10.5366V59.7073Z" fill="#FDFDFD" />
  <path d="M12.2927 60.7073C12.2927 60.155 11.845 59.7073 11.2927 59.7073H10.5366V61.4634H12.2927V60.7073Z" fill="#FDFDFD" />
  <path d="M17.561 60.7073C17.561 60.155 17.1133 59.7073 16.561 59.7073H15.8049V61.4634H17.561V60.7073Z" fill="#FDFDFD" />
  <path d="M22.8293 59.7073H21.0732V61.4634H22.8293V59.7073Z" fill="#FDFDFD" />
  <path d="M26.3415 59.7073H24.5854V61.4634H26.3415V59.7073Z" fill="#FDFDFD" />
  <path d="M28.0976 60.7073C28.0976 60.155 27.6498 59.7073 27.0976 59.7073H26.3415V61.4634H28.0976V60.7073Z" fill="#FDFDFD" />
  <path d="M31.6098 59.7073H30.8537C30.3014 59.7073 29.8537 60.155 29.8537 60.7073V61.4634H31.6098V59.7073Z" fill="#FDFDFD" />
  <path d="M33.3659 59.7073H31.6098V61.4634H33.3659V59.7073Z" fill="#FDFDFD" />
  <path d="M35.122 59.7073H33.3659V61.4634H35.122V59.7073Z" fill="#FDFDFD" />
  <path d="M36.8781 59.7073H35.122V61.4634H36.8781V59.7073Z" fill="#FDFDFD" />
  <path d="M38.6341 60.7073C38.6341 60.155 38.1864 59.7073 37.6341 59.7073H36.8781V61.4634H38.6341V60.7073Z" fill="#FDFDFD" />
  <path d="M42.1463 59.7073H41.3902C40.838 59.7073 40.3902 60.155 40.3902 60.7073V61.4634H42.1463V59.7073Z" fill="#FDFDFD" />
  <path d="M43.9024 59.7073H42.1463V61.4634H43.9024V59.7073Z" fill="#FDFDFD" />
  <path d="M47.4146 59.7073H45.6585V61.4634H47.4146V59.7073Z" fill="#FDFDFD" />
  <path d="M52.6829 59.7073H50.9268V61.4634H52.6829V59.7073Z" fill="#FDFDFD" />
  <path d="M57.9512 59.7073H56.1951V61.4634H57.9512V59.7073Z" fill="#FDFDFD" />
  <path d="M61.4634 60.5854C61.4634 60.1004 61.0703 59.7073 60.5854 59.7073C60.1004 59.7073 59.7073 60.1004 59.7073 60.5854C59.7073 61.0703 60.1004 61.4634 60.5854 61.4634C61.0703 61.4634 61.4634 61.0703 61.4634 60.5854Z" fill="#FDFDFD" />
  <path d="M64.9756 59.7073H63.2195V61.4634H64.9756V59.7073Z" fill="#FDFDFD" />
  <path d="M66.7317 59.7073H64.9756V61.4634H65.7317C66.284 61.4634 66.7317 61.0157 66.7317 60.4634V59.7073Z" fill="#FDFDFD" />
  <path d="M72 59.7073H70.2439V61.4634H72V59.7073Z" fill="#FDFDFD" />
  <path d="M1.7561 61.4634H0V63.2195H1.7561V61.4634Z" fill="#FDFDFD" />
  <path d="M12.2927 61.4634H10.5366V63.2195H12.2927V61.4634Z" fill="#FDFDFD" />
  <path d="M15.8049 61.4634H14.9268C14.4419 61.4634 14.0488 61.8565 14.0488 62.3415C14.0488 62.8264 14.4419 63.2195 14.9268 63.2195H15.8049V61.4634Z" fill="#FDFDFD" />
  <path d="M17.561 61.4634H15.8049V63.2195H16.561C17.1133 63.2195 17.561 62.7718 17.561 62.2195V61.4634Z" fill="#FDFDFD" />
  <path d="M22.8293 61.4634H21.0732V62.2195C21.0732 62.7718 21.5209 63.2195 22.0732 63.2195H22.8293V61.4634Z" fill="#FDFDFD" />
  <path d="M24.5854 61.4634H22.8293V63.2195H24.5854V61.4634Z" fill="#FDFDFD" />
  <path d="M29.8537 61.4634H28.0976V62.2195C28.0976 62.7718 28.5453 63.2195 29.0976 63.2195H29.8537V61.4634Z" fill="#FDFDFD" />
  <path d="M31.6098 61.4634H29.8537V63.2195H31.6098V61.4634Z" fill="#FDFDFD" />
  <path d="M35.122 61.4634H33.3659V63.2195H34.122C34.6742 63.2195 35.122 62.7718 35.122 62.2195V61.4634Z" fill="#FDFDFD" />
  <path d="M38.6341 61.4634H36.8781V62.2195C36.8781 62.7718 37.3258 63.2195 37.8781 63.2195H38.6341V61.4634Z" fill="#FDFDFD" />
  <path d="M40.3902 61.4634H38.6341V63.2195H40.3902V61.4634Z" fill="#FDFDFD" />
  <path d="M43.9024 61.4634H42.1463V62.2195C42.1463 62.7718 42.5941 63.2195 43.1463 63.2195H43.9024V61.4634Z" fill="#FDFDFD" />
  <path d="M45.6585 61.4634H43.9024V63.2195H44.6585C45.2108 63.2195 45.6585 62.7718 45.6585 62.2195V61.4634Z" fill="#FDFDFD" />
  <path d="M49.1707 62.3415C49.1707 61.8565 48.7776 61.4634 48.2927 61.4634H47.4146V62.3415C47.4146 62.8264 47.8078 63.2195 48.2927 63.2195C48.7776 63.2195 49.1707 62.8264 49.1707 62.3415Z" fill="#FDFDFD" />
  <path d="M52.6829 61.4634H50.9268V62.3415C50.9268 62.8264 51.3199 63.2195 51.8049 63.2195C52.2898 63.2195 52.6829 62.8264 52.6829 62.3415V61.4634Z" fill="#FDFDFD" />
  <path d="M56.1951 61.4634H55.439C54.8867 61.4634 54.439 61.9111 54.439 62.4634V63.2195H56.1951V61.4634Z" fill="#FDFDFD" />
  <path d="M57.9512 61.4634H56.1951V63.2195H57.9512V61.4634Z" fill="#FDFDFD" />
  <path d="M64.9756 61.4634H63.2195V63.2195H64.9756V61.4634Z" fill="#FDFDFD" />
  <path d="M70.2439 61.4634H69.4878C68.9355 61.4634 68.4878 61.9111 68.4878 62.4634V63.2195H70.2439V61.4634Z" fill="#FDFDFD" />
  <path d="M72 61.4634H70.2439V63.2195H72V61.4634Z" fill="#FDFDFD" />
  <path d="M1.7561 63.2195H0V64.9756H1.7561V63.2195Z" fill="#FDFDFD" />
  <path d="M5.26829 63.2195H4.5122C3.95991 63.2195 3.5122 63.6672 3.5122 64.2195V64.9756H5.26829V63.2195Z" fill="#FDFDFD" />
  <path d="M7.02439 63.2195H5.26829V64.9756H7.02439V63.2195Z" fill="#FDFDFD" />
  <path d="M8.78049 64.2195C8.78049 63.6672 8.33277 63.2195 7.78049 63.2195H7.02439V64.9756H8.78049V64.2195Z" fill="#FDFDFD" />
  <path d="M12.2927 63.2195H10.5366V64.9756H12.2927V63.2195Z" fill="#FDFDFD" />
  <path d="M26.3415 64.2195C26.3415 63.6672 25.8937 63.2195 25.3415 63.2195H24.5854V64.9756H26.3415V64.2195Z" fill="#FDFDFD" />
  <path d="M33.3659 63.2195H31.6098V64.9756H33.3659V63.2195Z" fill="#FDFDFD" />
  <path d="M40.3902 63.2195H38.6341V64.9756H40.3902V63.2195Z" fill="#FDFDFD" />
  <path d="M56.1951 63.2195H54.439V63.9756C54.439 64.5279 54.8867 64.9756 55.439 64.9756H56.1951V63.2195Z" fill="#FDFDFD" />
  <path d="M57.9512 63.2195H56.1951V64.9756H57.9512V63.2195Z" fill="#FDFDFD" />
  <path d="M59.7073 63.2195H57.9512V64.9756H59.7073V63.2195Z" fill="#FDFDFD" />
  <path d="M61.4634 63.2195H59.7073V64.9756H61.4634V63.2195Z" fill="#FDFDFD" />
  <path d="M63.2195 63.2195H61.4634V64.9756H63.2195V63.2195Z" fill="#FDFDFD" />
  <path d="M64.9756 63.2195H63.2195V64.9756H64.9756V63.2195Z" fill="#FDFDFD" />
  <path d="M66.7317 63.2195H64.9756V64.9756H66.7317V63.2195Z" fill="#FDFDFD" />
  <path d="M68.4878 63.2195H66.7317V64.9756H68.4878V63.2195Z" fill="#FDFDFD" />
  <path d="M72 63.2195H70.2439V64.9756H72V63.2195Z" fill="#FDFDFD" />
  <path d="M1.7561 64.9756H0V66.7317H1.7561V64.9756Z" fill="#FDFDFD" />
  <path d="M5.26829 64.9756H3.5122V66.7317H5.26829V64.9756Z" fill="#FDFDFD" />
  <path d="M7.02439 64.9756H5.26829V66.7317H7.02439V64.9756Z" fill="#FDFDFD" />
  <path d="M8.78049 64.9756H7.02439V66.7317H8.78049V64.9756Z" fill="#FDFDFD" />
  <path d="M12.2927 64.9756H10.5366V66.7317H12.2927V64.9756Z" fill="#FDFDFD" />
  <path d="M17.561 64.9756H16.8049C16.2526 64.9756 15.8049 65.4233 15.8049 65.9756V66.7317H17.561V64.9756Z" fill="#FDFDFD" />
  <path d="M19.3171 65.9756C19.3171 65.4233 18.8694 64.9756 18.3171 64.9756H17.561V66.7317H19.3171V65.9756Z" fill="#FDFDFD" />
  <path d="M22.8293 64.9756H22.0732C21.5209 64.9756 21.0732 65.4233 21.0732 65.9756V66.7317H22.8293V64.9756Z" fill="#FDFDFD" />
  <path d="M24.5854 64.9756H22.8293V66.7317H24.5854V64.9756Z" fill="#FDFDFD" />
  <path d="M26.3415 64.9756H24.5854V66.7317H26.3415V64.9756Z" fill="#FDFDFD" />
  <path d="M28.0976 65.8537C28.0976 65.3687 27.7044 64.9756 27.2195 64.9756H26.3415V66.7317H27.2195C27.7044 66.7317 28.0976 66.3386 28.0976 65.8537Z" fill="#FDFDFD" />
  <path d="M33.3659 64.9756H31.6098V66.7317H33.3659V64.9756Z" fill="#FDFDFD" />
  <path d="M35.122 65.9756C35.122 65.4233 34.6742 64.9756 34.122 64.9756H33.3659V66.7317H35.122V65.9756Z" fill="#FDFDFD" />
  <path d="M40.3902 64.9756H38.6341V65.7317C38.6341 66.284 39.0819 66.7317 39.6342 66.7317H40.3902V64.9756Z" fill="#FDFDFD" />
  <path d="M42.1463 64.9756H40.3902V66.7317H42.1463V64.9756Z" fill="#FDFDFD" />
  <path d="M43.9024 64.9756H42.1463V66.7317H43.9024V64.9756Z" fill="#FDFDFD" />
  <path d="M45.6585 65.8537C45.6585 65.3687 45.2654 64.9756 44.7805 64.9756H43.9024V66.7317H44.7805C45.2654 66.7317 45.6585 66.3386 45.6585 65.8537Z" fill="#FDFDFD" />
  <path d="M52.6829 65.8537C52.6829 65.3687 52.2898 64.9756 51.8049 64.9756C51.3199 64.9756 50.9268 65.3687 50.9268 65.8537V66.7317H52.6829V65.8537Z" fill="#FDFDFD" />
  <path d="M57.9512 64.9756H56.1951V66.7317H57.9512V64.9756Z" fill="#FDFDFD" />
  <path d="M59.7073 64.9756H57.9512V66.7317H59.7073V64.9756Z" fill="#FDFDFD" />
  <path d="M61.4634 64.9756H59.7073V66.7317H61.4634V64.9756Z" fill="#FDFDFD" />
  <path d="M63.2195 64.9756H61.4634V66.7317H63.2195V64.9756Z" fill="#FDFDFD" />
  <path d="M64.9756 64.9756H63.2195V66.7317H63.9756C64.5279 66.7317 64.9756 66.284 64.9756 65.7317V64.9756Z" fill="#FDFDFD" />
  <path d="M68.4878 64.9756H66.7317V65.8537C66.7317 66.3386 67.1248 66.7317 67.6098 66.7317C68.0947 66.7317 68.4878 66.3386 68.4878 65.8537V64.9756Z" fill="#FDFDFD" />
  <path d="M72 64.9756H70.2439V65.8537C70.2439 66.3386 70.637 66.7317 71.122 66.7317C71.6069 66.7317 72 66.3386 72 65.8537V64.9756Z" fill="#FDFDFD" />
  <path d="M1.7561 66.7317H0V68.4878H1.7561V66.7317Z" fill="#FDFDFD" />
  <path d="M5.26829 66.7317H3.5122V67.4878C3.5122 68.0401 3.95991 68.4878 4.5122 68.4878H5.26829V66.7317Z" fill="#FDFDFD" />
  <path d="M7.02439 66.7317H5.26829V68.4878H7.02439V66.7317Z" fill="#FDFDFD" />
  <path d="M8.78049 66.7317H7.02439V68.4878H7.78049C8.33277 68.4878 8.78049 68.0401 8.78049 67.4878V66.7317Z" fill="#FDFDFD" />
  <path d="M12.2927 66.7317H10.5366V68.4878H12.2927V66.7317Z" fill="#FDFDFD" />
  <path d="M17.561 66.7317H15.8049V68.4878H17.561V66.7317Z" fill="#FDFDFD" />
  <path d="M19.3171 66.7317H17.561V68.4878H19.3171V66.7317Z" fill="#FDFDFD" />
  <path d="M21.0732 66.7317H19.3171V68.4878H21.0732V66.7317Z" fill="#FDFDFD" />
  <path d="M24.5854 66.7317H22.8293V67.4878C22.8293 68.0401 23.277 68.4878 23.8293 68.4878H24.5854V66.7317Z" fill="#FDFDFD" />
  <path d="M26.3415 66.7317H24.5854V68.4878H25.3415C25.8937 68.4878 26.3415 68.0401 26.3415 67.4878V66.7317Z" fill="#FDFDFD" />
  <path d="M31.6098 66.7317H30.8537C30.3014 66.7317 29.8537 67.1794 29.8537 67.7317V68.4878H31.6098V66.7317Z" fill="#FDFDFD" />
  <path d="M33.3659 66.7317H31.6098V68.4878H33.3659V66.7317Z" fill="#FDFDFD" />
  <path d="M36.8781 67.7317C36.8781 67.1794 36.4303 66.7317 35.8781 66.7317H35.122V68.4878H36.8781V67.7317Z" fill="#FDFDFD" />
  <path d="M42.1463 66.7317H40.3902V68.4878H42.1463V66.7317Z" fill="#FDFDFD" />
  <path d="M52.6829 66.7317H50.9268V67.4878C50.9268 68.0401 51.3745 68.4878 51.9268 68.4878H52.6829V66.7317Z" fill="#FDFDFD" />
  <path d="M54.439 67.6098C54.439 67.1248 54.0459 66.7317 53.561 66.7317H52.6829V68.4878H53.561C54.0459 68.4878 54.439 68.0947 54.439 67.6098Z" fill="#FDFDFD" />
  <path d="M57.9512 66.7317H56.1951V67.6098C56.1951 68.0947 56.5882 68.4878 57.0732 68.4878C57.5581 68.4878 57.9512 68.0947 57.9512 67.6098V66.7317Z" fill="#FDFDFD" />
  <path d="M63.2195 66.7317H61.4634V68.4878H63.2195V66.7317Z" fill="#FDFDFD" />
  <path d="M1.7561 68.4878H0V70.2439H1.7561V68.4878Z" fill="#FDFDFD" />
  <path d="M12.2927 68.4878H10.5366V70.2439H12.2927V68.4878Z" fill="#FDFDFD" />
  <path d="M17.561 68.4878H15.8049V70.2439H17.561V68.4878Z" fill="#FDFDFD" />
  <path d="M19.3171 68.4878H17.561V70.2439H19.3171V68.4878Z" fill="#FDFDFD" />
  <path d="M21.0732 68.4878H19.3171V70.2439H20.0732C20.6255 70.2439 21.0732 69.7962 21.0732 69.2439V68.4878Z" fill="#FDFDFD" />
  <path d="M29.8537 68.4878H29.0976C28.5453 68.4878 28.0976 68.9355 28.0976 69.4878V70.2439H29.8537V68.4878Z" fill="#FDFDFD" />
  <path d="M31.6098 68.4878H29.8537V70.2439H31.6098V68.4878Z" fill="#FDFDFD" />
  <path d="M35.122 68.4878H33.3659V70.2439H35.122V68.4878Z" fill="#FDFDFD" />
  <path d="M36.8781 68.4878H35.122V70.2439H36.8781V68.4878Z" fill="#FDFDFD" />
  <path d="M38.6341 68.4878H36.8781V70.2439H38.6341V68.4878Z" fill="#FDFDFD" />
  <path d="M40.3902 68.4878H38.6341V70.2439H40.3902V68.4878Z" fill="#FDFDFD" />
  <path d="M42.1463 68.4878H40.3902V70.2439H42.1463V68.4878Z" fill="#FDFDFD" />
  <path d="M43.9024 69.3659C43.9024 68.8809 43.5093 68.4878 43.0244 68.4878H42.1463V70.2439H43.0244C43.5093 70.2439 43.9024 69.8508 43.9024 69.3659Z" fill="#FDFDFD" />
  <path d="M49.1707 69.3659C49.1707 68.8809 48.7776 68.4878 48.2927 68.4878C47.8078 68.4878 47.4146 68.8809 47.4146 69.3659V70.2439H48.2927C48.7776 70.2439 49.1707 69.8508 49.1707 69.3659Z" fill="#FDFDFD" />
  <path d="M61.4634 68.4878H60.7073C60.155 68.4878 59.7073 68.9355 59.7073 69.4878V70.2439H61.4634V68.4878Z" fill="#FDFDFD" />
  <path d="M64.9756 69.4878C64.9756 68.9355 64.5279 68.4878 63.9756 68.4878H63.2195V70.2439H64.9756V69.4878Z" fill="#FDFDFD" />
  <path d="M68.4878 68.4878H67.7317C67.1794 68.4878 66.7317 68.9355 66.7317 69.4878V70.2439H68.4878V68.4878Z" fill="#FDFDFD" />
  <path d="M70.2439 68.4878H68.4878V70.2439H70.2439V68.4878Z" fill="#FDFDFD" />
  <path d="M72 69.3659C72 68.8809 71.6069 68.4878 71.122 68.4878H70.2439V70.2439H71.122C71.6069 70.2439 72 69.8508 72 69.3659Z" fill="#FDFDFD" />
  <path d="M1.7561 70.2439H0V71C0 71.5523 0.447716 72 1 72H1.7561V70.2439Z" fill="#FDFDFD" />
  <path d="M3.5122 70.2439H1.7561V72H3.5122V70.2439Z" fill="#FDFDFD" />
  <path d="M5.26829 70.2439H3.5122V72H5.26829V70.2439Z" fill="#FDFDFD" />
  <path d="M7.02439 70.2439H5.26829V72H7.02439V70.2439Z" fill="#FDFDFD" />
  <path d="M8.78049 70.2439H7.02439V72H8.78049V70.2439Z" fill="#FDFDFD" />
  <path d="M10.5366 70.2439H8.78049V72H10.5366V70.2439Z" fill="#FDFDFD" />
  <path d="M12.2927 70.2439H10.5366V72H11.2927C11.845 72 12.2927 71.5523 12.2927 71V70.2439Z" fill="#FDFDFD" />
  <path d="M17.561 70.2439H15.8049V71.1219C15.8049 71.6069 16.198 72 16.6829 72C17.1679 72 17.561 71.6069 17.561 71.1219V70.2439Z" fill="#FDFDFD" />
  <path d="M26.3415 70.2439H25.4634C24.9785 70.2439 24.5854 70.637 24.5854 71.122C24.5854 71.6069 24.9785 72 25.4634 72H26.3415V70.2439Z" fill="#FDFDFD" />
  <path d="M28.0976 70.2439H26.3415V72H27.0976C27.6498 72 28.0976 71.5523 28.0976 71V70.2439Z" fill="#FDFDFD" />
  <path d="M31.6098 70.2439H29.8537V71C29.8537 71.5523 30.3014 72 30.8537 72H31.6098V70.2439Z" fill="#FDFDFD" />
  <path d="M33.3659 70.2439H31.6098V72H33.3659V70.2439Z" fill="#FDFDFD" />
  <path d="M35.122 70.2439H33.3659V72H35.122V70.2439Z" fill="#FDFDFD" />
  <path d="M36.8781 70.2439H35.122V72H36.8781V70.2439Z" fill="#FDFDFD" />
  <path d="M38.6341 70.2439H36.8781V72H37.6341C38.1864 72 38.6341 71.5523 38.6341 71V70.2439Z" fill="#FDFDFD" />
  <path d="M42.1463 70.2439H40.3902V71.122C40.3902 71.6069 40.7834 72 41.2683 72C41.7532 72 42.1463 71.6069 42.1463 71.122V70.2439Z" fill="#FDFDFD" />
  <path d="M47.4146 70.2439H46.5366C46.0517 70.2439 45.6585 70.637 45.6585 71.122C45.6585 71.6069 46.0517 72 46.5366 72C47.0215 72 47.4146 71.6069 47.4146 71.122V70.2439Z" fill="#FDFDFD" />
  <path d="M52.6829 70.2439H51.8049C51.3199 70.2439 50.9268 70.637 50.9268 71.122C50.9268 71.6069 51.3199 72 51.8049 72H52.6829V70.2439Z" fill="#FDFDFD" />
  <path d="M54.439 71.122C54.439 70.637 54.0459 70.2439 53.561 70.2439H52.6829V72H53.561C54.0459 72 54.439 71.6069 54.439 71.122Z" fill="#FDFDFD" />
  <path d="M57.9512 70.2439H57.0732C56.5882 70.2439 56.1951 70.637 56.1951 71.122C56.1951 71.6069 56.5882 72 57.0732 72H57.9512V70.2439Z" fill="#FDFDFD" />
  <path d="M59.7073 70.2439H57.9512V72H58.7073C59.2596 72 59.7073 71.5523 59.7073 71V70.2439Z" fill="#FDFDFD" />
  <path d="M63.2195 70.2439H61.4634V71.122C61.4634 71.6069 61.8565 72 62.3415 72C62.8264 72 63.2195 71.6069 63.2195 71.122V70.2439Z" fill="#FDFDFD" />
  <path d="M66.7317 70.2439H64.9756V71C64.9756 71.5523 65.4233 72 65.9756 72H66.7317V70.2439Z" fill="#FDFDFD" />
  <path d="M68.4878 70.2439H66.7317V72H67.4878C68.0401 72 68.4878 71.5523 68.4878 71V70.2439Z" fill="#FDFDFD" />
</svg>
  ;
export default GoshDebotQrCode;
